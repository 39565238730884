import { Helmet } from "react-helmet-async";
import { useState, useEffect, useContext } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { CSVDownload } from "react-csv";
import TableCell from "@mui/material/TableCell";

import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { getComparator, stableSort } from "src/utils/sortTables";
import { deletePromoCode, fetchPromoData, fetchPromoDataExport } from "src/api/promo";
import { Button, Container, CircularProgress, Divider, Grid, InputAdornment, Table, TableContainer, TableHead, TableBody, TablePagination, TableRow, TextField, Typography, Snackbar, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import { ContentCopy, Delete, Edit, Search } from "@mui/icons-material";

import { debounce } from "lodash";
import { PROMO_STATUS, colors } from "src/constants/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { StyledTableCell2, addProductButton, addVariantButton, pTable } from "src/styles/styles";
import { PROMO_CHANNEL, PROMO_OFFER_TYPE, VISIBILITY_TYPE } from "src/constants/constants";
import { getNameValueFromId, useQuery } from "src/utils/utils";
import { fDate } from "src/utils/formatTime";
import { parseISO, format } from "date-fns";

export default function PromoPage() {
  //Handle search persist
  const searchQuery = useQuery().get("search") || null;

  const navigate = useNavigate();

  const [tableArray, setTableArray] = useState([]);
  const [searchText, setSearchText] = useState(searchQuery);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPromoCount, setTotalPromoCount] = useState(0);
  const [exportt, setExport] = useState(false);
  const [exportTable, setExportTable] = useState([]);
  const [copied, setCopied] = useState(false);
  const [openDeleteBanner, setOpenDeleteBanner] = useState(false);
  const [promoDeleteObj, setPromoDeleteObj] = useState({ promoCodeId: null, promoCode: null });
  const [loading, setLoading] = useState(false);

  const {
    dispatch,
    state: { promoState }
  } = useContext(GlobalContext);

  useEffect(() => {
    if (searchText && searchText.trim().length >= 3 && searchText !== "null") {
      setSearchParams({ search: searchText });
    } else {
      setSearchText(null);
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchText && searchText.trim().length > 0 && searchText.trim().length < 3) return;
        updateTableData(pageSize, page, searchText);
      } catch (error) {
        setLoading(false);
        alert(error.message);
      }
    };

    fetchData();
  }, [searchText, promoState?.promoReload, page, pageSize]);

  const updateTableData = async (pageSize, page, searchText) => {
    const body = {
      pageSize,
      pageNumber: page,
      filter: { searchText }
    };
    setLoading(true);
    const response = await fetchPromoData(body);
    if (response.httpStatusCode === 200) {
      const { pageNumber, pageSize, totalProductsCount, promoCodes } = response.result.data;
      const array = [];
      promoCodes.forEach((data) => {
        const { promoCodeId, name, description, promoCode, validFrom, validTo, visibilityType, promoChannel, offerType, status, discount, minOrderValue, termAndConditions, productVariantId, quantity } = data;
        const body = {
          promoCodeId,
          name,
          description: description ?? "-",
          promoCode,
          validFrom: validFrom ? fDate(validFrom) : "-",
          validTo: validTo ? format(parseISO(validTo), "do MMM yyyy") : "-",
          visibilityType,
          promoChannel,
          offerType,
          status,
          discount: discount ? `${discount}%` : "-",
          minOrderValue: minOrderValue ? `Ksh ${minOrderValue.toLocaleString()}` : "-",
          termAndConditions,
          productVariantId,
          quantity
        };
        array.push(body);
      });

      setTableArray(array);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalPromoCount(totalProductsCount);

      setSearchParams({ ...searchParams, search: searchText });

      setLoading(false);
    } else {
      setLoading(false);
      alert(response.message);
    }
  };

  const handleChangeSearch = (e) => {
    if (!e.target.value.trim()) {
      setSearchText(null);
    } else if (e.target.value < 3) {
      setSearchText(e.target.value);
    } else {
      setSearchParams({ search: e.target.value });
      setSearchText(e.target.value);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const promoHeadCells = [
    {
      id: "promoCode",
      numeric: false,
      disablePadding: false,
      label: "Promo Code"
    },
    {
      id: "minOrderValue",
      numeric: false,
      disablePadding: true,
      label: "Min Val Req"
    },
    {
      id: "validTill",
      numeric: false,
      disablePadding: true,
      label: "Valid Till"
    },
    {
      id: "visibility",
      numeric: false,
      disablePadding: true,
      label: "Visibility"
    },
    {
      id: "promoChannel",
      numeric: false,
      disablePadding: true,
      label: "Promo Channel"
    },
    {
      id: "offerType",
      numeric: false,
      disablePadding: true,
      label: "Offer Type"
    },
    {
      id: "discount",
      numeric: false,
      disablePadding: true,
      label: "Discount"
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status"
    }
  ];

  const handlePromoCodeDelete = async (promoCodeId) => {
    try {
      const response = await deletePromoCode(promoCodeId);
      setPromoDeleteObj({ ...promoDeleteObj, promoCode: null, promoCodeId: null });
      setOpenDeleteBanner(false);
      if (response.httpStatusCode === 200) {
        alert(response?.message);
        dispatch({ type: "promoReload", payload: !promoState?.promoReload });
      } else {
        alert(response?.message ?? "Unable to delete the selected promo code");
      }
      setPromoDeleteObj({ ...promoDeleteObj, promoCode: null, promoCodeId: null });
      setOpenDeleteBanner(false);
    } catch (error) {
      alert(error?.message);
    }
  };

  const fetchPromoExportData = async () => {
    try {
      const body = {
        pageSize: 1000,
        pageNumber: 0
      };
      const responseExport = await fetchPromoDataExport(body);
      if (responseExport.httpStatusCode === 200) {
        const { promoDetails } = responseExport.result.data;
        let promoCodeArray = promoDetails.map((pc) => {
          delete pc["totalCount"];
          return pc;
        });

        setExportTable(promoCodeArray);

        if (exportt) {
          setExport(false);
          setTimeout(() => {
            setExport(true);
          }, 500);
        } else {
          setExport(true);
        }
      } else {
        alert(responseExport.message);
      }
    } catch (error) {
      alert(error?.message);
    }
  };

  const csvReport = {
    data: exportTable,
    // headers,
    filename: `promo.csv`
  };

  const DeletePromoCodeBanner = () => {
    return (
      <Dialog open={openDeleteBanner} onClose={() => setOpenDeleteBanner(false)}>
        <DialogTitle> {`Are you sure you want to DELETE promo code ${promoDeleteObj?.promoCode} ?`}</DialogTitle>
        <DialogContent>
          <Typography>Please confirm before proceeding. Action is irreversible !!!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpenDeleteBanner(false)} variant="outlined" style={addVariantButton}>
            Cancel
          </Button>
          <Button style={addProductButton} onClick={() => handlePromoCodeDelete(promoDeleteObj?.promoCodeId)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Promos </title>
      </Helmet>
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4">Promo Codes</Typography>
          </Grid>
          {promoState.promoDetails ? null : (
            <Grid item xs={8} style={headerStyle.searchContainer}>
              <TextField
                onChange={debounce((e) => {
                  handleChangeSearch(e);
                }, 300)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    updateTableData(10, 0, searchText);
                  }
                }}
                placeholder="Search Promo"
                defaultValue={searchText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                      <Search />
                    </InputAdornment>
                  ),
                  style: headerStyle.searchTextField,
                  inputProps: {
                    style: headerStyle.placeHolderText
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Divider />
        {/*Subheader Container */}
        <Grid sx={{ my: 2 }} maxWidth="xxl" spacing={2} gap={2} style={headerStyle.subHeaderContainer}>
          <Grid item xs={4}>
            <Button
              onClick={(e) => {
                dispatch({ type: "updatePromoCode", type: false });
                dispatch({ type: "addPromoCode", type: true });
                dispatch({ type: "promoClear" });
                navigate("/dashboard/promos/details/add");
              }}
              style={addProductButton}
              endIcon={<img alt="add" src="/assets/icons/button/add_icon.svg" />}
            >
              New Promo
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={() => fetchPromoExportData()} variant="outlined" style={addVariantButton} endIcon={<img alt="add" src="/assets/icons/button/arrow_down_black.svg" />}>
              Export
            </Button>
            {exportt ? <CSVDownload {...csvReport} /> : <></>}
          </Grid>
        </Grid>

        <Grid maxWidth="xxl">
          <>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                    <EnhancedTableHead headCells={promoHeadCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} />
                    <StyledTableCell2>{""}</StyledTableCell2>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <CircularProgress sx={{ color: colors.primary }} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    stableSort(tableArray, getComparator(order, orderBy)).map((record, index) => (
                      <TableRow key={record.promoCodeId}>
                        <TableCell align="left">
                          <p style={pTable}>{record.promoCode}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.minOrderValue}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.validTo}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{getNameValueFromId(VISIBILITY_TYPE, record.visibilityType)}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{getNameValueFromId(PROMO_CHANNEL, record.promoChannel)}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{getNameValueFromId(PROMO_OFFER_TYPE, record.offerType)}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.discount}</p>
                        </TableCell>
                        <TableCell align="left">
                          <p style={pTable}>{record.status === PROMO_STATUS.inActive ? "InActive" : record.status === PROMO_STATUS.active ? "Active" : record.status === PROMO_STATUS.expired ? "Expired" : record.status === PROMO_STATUS.completed ? "Completed" : null}</p>
                        </TableCell>
                        <TableCell sx={{ display: "flex", justifyContent: "space-between" }} align="right">
                          <Edit style={{ cursor: "pointer" }} onClick={() => navigate(`/dashboard/promos/details/${record.promoCodeId}${searchText ? `?search=${searchText}` : ""}`)} sx={{ my: 1 }} fontSize="small" />
                          <CopyToClipboard
                            style={{ cursor: "pointer" }}
                            text={record.promoCode}
                            onCopy={() => {
                              setCopied(true);
                              setTimeout(() => setCopied(false), 5000);
                            }}
                          >
                            <ContentCopy sx={{ my: 1 }} fontSize="small" />
                          </CopyToClipboard>
                          <Snackbar open={copied} autoHideDuration={5000} message="Copied to clipboard" />
                          <DeletePromoCodeBanner />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ height: "20px" }}>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 25]}
                component="div"
                count={totalPromoCount}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newVal) => {
                  updateTableData(pageSize, newVal, searchText);
                }}
                onRowsPerPageChange={(e) => {
                  updateTableData(e.target.value, page, searchText);
                }}
              />
            </div>
          </>
        </Grid>
      </Container>
    </>
  );
}
