import { useContext, useEffect, useState } from "react";
import { Autocomplete, Button, FormControl, TextField } from "@mui/material";
// eslint-disable-next-line
import { GlobalContext } from "src/context/GlobalState";

const VTypeFields = () => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);

  const [selectedVariant, setSelectedVariant] = useState(productState.variantTypeDropdown.find((variant) => variant.vType === productState?.productRecord.variantId) || null);

  useEffect(() => {
    setSelectedVariant(productState.variantTypeDropdown.find((variant) => variant.vType === productState?.productRecord?.variantId) || null);
  }, [productState]);

  const createNewBox = {
    boxSizing: "border-box",
    marginTop: "10px",
    width: "100%",
    background: "#F2F2F2",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#BFBFBF",
    borderRadius: "8px 8px 8px 8px",
    marginBottom: "1em"
  };

  const addVariantButton = {
    display: "flex",
    flexDirection: "row",
    color: "#404040",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    height: "40px",
    borderRadius: "8px",
    borderColor: "black",
    flex: "none",
    order: "1",
    flexGrow: "0"
  };

  const handleChange = (e, val) => {
    if (val?.vType === "POST") {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, variantId: null } });
      dispatch({ type: "newVType", payload: true });
    } else {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, variantId: val?.vType } });
    }
  };

  const handleClose = () => {
    dispatch({ type: "newVType", payload: false });
    dispatch({ type: "newVTypeName", payload: false });
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, variantId: null, variantName: null } });
  };

  return (
    <>
      {productState.newVType ? (
        <div style={createNewBox}>
          <p>Create New Variant Type</p>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="Variant Type Name"
              onChange={(e) => {
                dispatch({ type: "productRecord", payload: { ...productState?.productRecord, variantName: e.target.value } });
              }}
              variant="standard"
              margin="normal"
              value={productState?.productRecord?.variantName ?? null}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <div
            style={{
              height: "42px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Button onClick={(e) => handleClose()} variant="outlined" style={addVariantButton}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <FormControl fullWidth margin="dense" style={{ marginBottom: "1em" }}>
          <Autocomplete disablePortal defaultValue={productState.variantTypeDropdown.find((variant) => variant.vType === productState?.productRecord.variantId)} value={selectedVariant} id="category-label" name="vType" options={productState.variantTypeDropdown} getOptionLabel={(option) => option.label} onChange={(e, val) => handleChange(e, val)} sx={{ width: "100%" }} renderInput={(params) => <TextField {...params} label="Variant Type" variant="standard" />} />
        </FormControl>
      )}
    </>
  );
};

export default VTypeFields;
