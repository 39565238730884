import { Helmet } from "react-helmet-async";
import { useState, useEffect, useContext } from "react";
import TableCell from "@mui/material/TableCell";
// @mui
import { CircularProgress, Container, FormControl, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Button, Typography, TextField, Grid, Divider } from "@mui/material";
// components

import { pTable, statusButtons, addProductButton } from "../../styles/styles";

// eslint-disable-next-line
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { StyledTableCell2 } from "../../styles/styles";
import { getComparator, stableSort } from "src/utils/sortTables";
import { fetchPamojaTableData, fetchPamojaTableDataByTushop } from "src/api/products";
import PamojaConfig from "src/components/dialogs/PamojaConfig/PamojaConfig";
import SupermojaConfig from "src/components/dialogs/SupermojaConfig/SupermojaConfig";
import { CSVDownload } from "react-csv";
import { fetchPamojaExportData } from "src/api/orders";
import { fDate } from "src/utils/formatTime";
import PamojaFilter from "src/components/dialogs/PamojaFilter/PamojaFilter";
import { Search } from "@mui/icons-material";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
import buttonStyle from "src/styles/buttonStyle";
import tableStyle from "src/styles/tableStyle";
import fontStyle from "src/styles/fontStyle";
import { pamojaOrdersCSV, pamojaSpecificCSV, pamojaUserSpecificCSV, productSpecificCSV } from "src/constants/csv";
import { allPamojaStatus, pamojaChangeStatus, pamojaCsvExports, pamojaType } from "src/constants/constants";
import { MoreVert } from "@mui/icons-material";
import ActionPopever from "src/components/popovers/ActionPopover";
import { debounce } from "lodash";
import { colors } from "src/constants/constants";
import SystemLoader from "src/components/dialogs/Loader/Loader";
// mock
// import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function PamojaPage() {
  const [tableArray, setTableArray] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("category");
  const [searchText, setSearchText] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState(1);
  const [exportType, setExportType] = useState(0);
  const [pamojaStatus, setPamojaStatus] = useState([allPamojaStatus.active]);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [headersExport, setHeadersExport] = useState([]);
  const [exportTable, setExportTable] = useState([]);
  const [exportt, setExport] = useState(false);
  const [openActionPopover, setOpenActionPopover] = useState(null);
  const [popoverRecordDeatils, setPopeverRecordDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    dispatch,
    state: { pamojaState, superpamojaState, globalState }
  } = useContext(GlobalContext);

  const pamojaHeadCells = [
    {
      id: "productName",
      numeric: false,
      disablePadding: true,
      label: "Product"
    },
    {
      id: "brandName",
      numeric: false,
      disablePadding: false,
      label: "Brand"
    },
    {
      id: "variationName",
      numeric: false,
      disablePadding: false,
      label: "Variation"
    },
    {
      id: "sokoPrice",
      numeric: true,
      disablePadding: false,
      label: "Soko Price"
    },
    {
      id: "pamojaPrice",
      numeric: false,
      disablePadding: false,
      label: "Pamoja Price"
    },
    {
      id: "qtyAllocated",
      numeric: true,
      disablePadding: false,
      label: "Allocated"
    },
    {
      id: "qtyAvailable",
      numeric: true,
      disablePadding: false,
      label: "Available"
    },
    {
      id: "qtySold",
      numeric: true,
      disablePadding: false,
      label: "Qty Sold"
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status"
    }
  ];

  // Handle display of active pamoja's in user pamoja section
  if (superpamojaState?.pamojaType === pamojaType.userPamoja) {
    pamojaHeadCells.splice(-1, 0, {
      id: "activePamojas",
      numeric: true,
      disablePadding: false,
      label: "Active Pamojas"
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        updateTableData(pageSize, page, pamojaStatus);
      } catch (error) {
        setLoading(false);
        alert(error.message);
      }
    };

    if (!pamojaState.openDialog || !superpamojaState?.openSupermojaDialog) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [pamojaState.openDialog, superpamojaState?.pamojaType, superpamojaState?.openSupermojaDialog, searchText, globalState.reload]);

  const updateTableData = async (pageSize, pageNumber, pamojaStatus) => {
    setTableArray([]);
    const body = {
      pageNumber,
      pageSize,
      filter: {
        searchText,
        pamojaStatus
      }
    };
    let response;
    setLoading(true);
    if (superpamojaState?.pamojaType === pamojaType.userPamoja) {
      response = await fetchPamojaTableData(body);
    } else {
      response = await fetchPamojaTableDataByTushop(body);
    }
    if (response?.httpStatusCode === 200) {
      const { products, pageNumber, pageSize, totalProductsCount } = response.result.data;
      const array = [];
      products.forEach((data) => {
        const { brand, productVariantPrice, productVariantPamoja, product, productVariantId, unit, variantType, productVariantImage, initialUsersCount, defaultQuantity } = data;
        const body = {
          productVariantId,
          warning: productVariantPamoja.warning,
          productName: product.name,
          variationName: `${variantType.name} - ${unit.name}`,
          variantImgUrl: productVariantImage ? productVariantImage[0]?.imageUrl ?? null : null,
          brandName: brand.name,
          sokoPrice: productVariantPrice.sokoPrice,
          pamojaPrice: superpamojaState?.pamojaType === pamojaType.userPamoja ? productVariantPrice.pamojaPrice : productVariantPrice.supermojaPrice,
          qtyAllocated: productVariantPamoja.quantityAllocated,
          qtyAvailable: productVariantPamoja.quantityAvailable,
          qtySold: productVariantPamoja.quantitySold ?? null,
          arrivesIn: productVariantPamoja.arrivesIn,
          expiresIn: productVariantPamoja.expiresIn,
          creatorRewards: productVariantPamoja.creatorRewards,
          userPerPamoja: productVariantPamoja.userPerPamoja,
          maxQuantityPerUser: productVariantPamoja.maxQuantityPerUser,
          status: productVariantPamoja.status,
          activePamojas: productVariantPamoja?.activePamojas ?? null,
          showPamojaFeed: productVariantPamoja.showPamojaFeed,
          initialUsersCount: initialUsersCount ?? 0,
          showProductDescription: productVariantPamoja?.showProductDescription ?? false
        };
        if (superpamojaState?.pamojaType === pamojaType.superMoja) {
          body.pamojaConfigId = productVariantPamoja?.pamojaConfigId ?? null;
          body.pamojaId = productVariantPamoja?.pamojaId?.pamojaId ?? null;
          body.defaultQuantity = defaultQuantity ?? 0;
        }
        array.push(body);
      });

      setTableArray(array);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalProductsCount(totalProductsCount);
      setLoading(false);
    } else {
      setTableArray([]);
      setPage(0);
      setTotalProductsCount(0);
      setLoading(false);
      alert(response?.message ?? "An error occured");
    }
  };

  const fetchExportData = async (e) => {
    const exportPageSize = parseInt(process.env.REACT_APP_EXPORT_PAGESIZE);
    let currentPageSize = parseInt(process.env.REACT_APP_EXPORT_PAGESIZE);
    dispatch({ type: "exportProgress", payload: true });
    if (e.target.value === pamojaCsvExports.ignore) {
      console.log("IGNORE");
    } else {
      let array = [];
      for (let i = 0; currentPageSize !== 0; i++) {
        console.log("currentPageSize", currentPageSize);
        console.log("LOOP", i);
        const body = {
          filter: {
            type: parseInt(e.target.value),
            status: pamojaState.pamojaStatuses,
            pageNumber: i,
            pageSize: exportPageSize,
            startDate: pamojaState.fromDate,
            endDate: pamojaState.toDate
          }
        };

        const responseExport = await fetchPamojaExportData(body);
        currentPageSize = responseExport.result.data.result.length !== exportPageSize ? 0 : responseExport.result.data.result.length;
        if (responseExport.result.data.result.length !== exportPageSize) {
          console.log("YA MWISHOOOOOOOO!");
        }
        console.log("pageSize", responseExport.result.data.pageSize);
        if (e.target.value === pamojaCsvExports.productSpecific) {
          if (responseExport.httpStatusCode === 200) {
            // eslint-disable-next-line
            responseExport.result.data.result.forEach((data) => {
              const { brand, sokoPrice, pamojaPrice, productName, productVariantId, unit, totalQuantityAllocated, arrivesIn, creatorRewards, userPerPamoja, status } = data;
              const body = {
                productVariantId,
                productName,
                brandName: brand,
                unit,
                sokoPrice,
                pamojaPrice,
                qtyAllocated: totalQuantityAllocated,
                arrivesIn,
                creatorRewards,
                userPerPamoja,
                status: status === 1 ? "ACTIVE" : "INACTIVE"
              };

              array.push(body);
            });

            if (responseExport.result.data.result.length !== exportPageSize) {
              handleExport(productSpecificCSV.headers, array);
            }
          } else {
            alert(responseExport.message);
          }
        } else if (e.target.value === pamojaCsvExports.pamojaSpecific) {
          if (responseExport.httpStatusCode === 200) {
            //eslint-disable-next-line
            responseExport.result.data.result.forEach((data) => {
              const { pamojaId, startDate, endDate, pamojaConfigId, status, expiresIn, userPerPamoja, arrivesIn, totalQuantityAllocated, pamojaLaunchedBy, creatorRewards, productName, productVariantId, unit, brand, categoryName, variantType, launchedByUserId, launchedByPhoneNumber, launchedUser, pamojaPrice, sokoPrice, numberOfUsers, maturedDate, deliveryDate } = data;
              const body = {
                pamojaId,
                startDate: fDate(startDate),
                endDate: fDate(endDate),
                pamojaConfigId,
                status: status === 0 ? "INACTIVE" : status === 1 ? "ACTIVE" : status === 2 ? "IN PROGRESS" : status === 3 ? "SUCCESS" : "NULL",
                expiresIn: expiresIn / 3600000 < 1 ? 0 : expiresIn / 3600000,
                userPerPamoja,
                arrivesIn: arrivesIn / 86400000 < 1 ? 0 : arrivesIn / 86400000,
                totalQuantityAllocated,
                pamojaLaunchedBy,
                creatorRewards,
                productName,
                productVariantId,
                unit,
                brand,
                categoryName,
                variantType,
                launchedByUserId,
                launchedByPhoneNumber,
                launchedUser,
                pamojaPrice,
                sokoPrice,
                numberOfUsers,
                maturedDate: (maturedDate instanceof Date && !isNaN(maturedDate)) || deliveryDate !== null ? fDate(maturedDate) : "-"
              };
              array.push(body);
            });
            if (responseExport.result.data.result.length !== exportPageSize) {
              handleExport(pamojaSpecificCSV.headers, array);
            }
          } else {
            alert(responseExport.message);
          }
        } else if (e.target.value === pamojaCsvExports.pamojaUserSpefic) {
          console.log(exportType);

          if (responseExport.httpStatusCode === 200) {
            const array = [];

            responseExport.result.data.result.forEach((pamoja) => {
              const { pamojaId, pamojaConfigId, status, productName, variantType, unit, brand, pamojaPrice, pamojaUser, launchedUser, launchedByPhoneNumber, maturedDate, deliveryDate } = pamoja;

              pamojaUser.forEach((user) => {
                const { pamojaUserId, quantity, joinerName, joinerPhoneNumber, createdAt, joinerSignUpDate } = user;

                const body = {
                  pamojaUserId,
                  pamojaId,
                  pamojaConfigId,
                  status: status === 0 ? "INACTIVE" : status === 1 ? "ACTIVE" : status === 2 ? "IN PROGRESS" : status === 3 ? "SUCCESS" : "NULL",
                  productName: `${productName} - ${variantType} - ${unit} (${brand})`,
                  pamojaPrice,
                  quantity,
                  total: pamojaPrice * quantity,
                  joinerName,
                  joinerPhoneNumber,
                  launchedUser,
                  launchedByPhoneNumber,
                  createdAt: fDate(createdAt),
                  joinerSignUpDate: fDate(joinerSignUpDate),
                  maturedDate: fDate(maturedDate),
                  deliveryDate: fDate(deliveryDate)
                };

                array.push(body);
              });
            });
            if (responseExport.result.data.result.length !== exportPageSize) {
              handleExport(pamojaUserSpecificCSV.headers, array);
            }
          } else {
            alert(responseExport.message);
          }
        } else if (e.target.value === pamojaCsvExports.pamojaOrders) {
          if (responseExport.httpStatusCode === 200) {
            if (responseExport.result.data.result.length !== exportPageSize) {
              array = array.concat(responseExport.result.data.result);
              handleExport(pamojaOrdersCSV.headers, array);
            } else {
              if (array.length === 0) {
                array = responseExport.result.data.result;
              } else {
                array = array.concat(responseExport.result.data.result);
              }
              console.log(array);
            }
          } else {
            alert(responseExport.message);
          }
        }
      }
    }
  };

  const handleExport = (headers, array) => {
    setHeadersExport(headers);
    setExportTable(array);
    dispatch({ type: "exportProgress", payload: false });
    if (exportt) {
      setExport(false);
      setTimeout(() => {
        setExport(true);
        setExportType(0);
      }, 1500);
    } else {
      setExport(true);
      setExportType(0);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangeStatus = (e) => {
    setStatus(parseInt(e.target.value));
    if (e.target.value === pamojaChangeStatus.all) {
      setPamojaStatus([allPamojaStatus.inActive, allPamojaStatus.active, allPamojaStatus.draft]);
      updateTableData(pageSize, page, [allPamojaStatus.inActive, allPamojaStatus.active, allPamojaStatus.draft]);
    } else if (e.target.value === pamojaChangeStatus.active) {
      setPamojaStatus([allPamojaStatus.active]);
      updateTableData(pageSize, page, [allPamojaStatus.active]);
    } else if (e.target.value === pamojaChangeStatus.inActive) {
      setPamojaStatus([allPamojaStatus.inActive]);
      updateTableData(pageSize, page, [allPamojaStatus.inActive]);
    } else if (e.target.value === pamojaChangeStatus.draft) {
      setPamojaStatus([allPamojaStatus.draft]);
      updateTableData(pageSize, page, [allPamojaStatus.draft]);
    }
  };

  const checkNull = (data) => {
    if (data === null) {
      return "-";
    } else {
      return data;
    }
  };

  // Handle Action popever open
  const handleActionPopeverOpen = (event, record) => {
    setPopeverRecordDetails(record);
    setOpenActionPopover(event.currentTarget);
  };

  const handleOpenDetails = (record) => {
    if (record.sokoPrice === null || record.sokoPrice === 0) {
      alert("Please update soko price on price matrix page first before setting pamoja configs");
    } else {
      dispatch({ type: "productVariantId", payload: record.productVariantId });
      dispatch({ type: "productName", payload: record.productName });
      dispatch({ type: "imgURL", payload: record.variantImgUrl });
      dispatch({ type: "brandName", payload: record.brandName });
      dispatch({ type: "variation", payload: record.variation });
      dispatch({ type: "active", payload: record.status === "Active" });
      dispatch({ type: "allocatedQty", payload: record.qtyAllocated ?? null });
      dispatch({ type: "availableQty", payload: record.qtyAvailable ?? null });
      dispatch({ type: "sokoPrice", payload: record.sokoPrice });
      dispatch({ type: "pamojaPrice", payload: record.pamojaPrice ?? null });
      dispatch({ type: "arrivesIn", payload: record.arrivesIn ?? null });
      dispatch({ type: "expiresIn", payload: record.expiresIn ?? null });
      dispatch({ type: "usersPerPamoja", payload: record.userPerPamoja ?? null });
      dispatch({ type: "maxQty", payload: record.maxQuantityPerUser ?? null });
      dispatch({ type: "showPamojaFeed", payload: record.showPamojaFeed ?? false });
      dispatch({ type: "activePamojas", payload: record.activePamojas ?? null });
      dispatch({ type: "initialUsersCount", payload: record.initialUsersCount });
      dispatch({ type: "showProductDescription", payload: record?.showProductDescription ?? false });
      dispatch({ type: "savePamojaStatus", payload: record.status === "Active" ? allPamojaStatus.active : record.status === "Draft" ? allPamojaStatus.draft : allPamojaStatus.inActive });
      superpamojaState.pamojaType === pamojaType.userPamoja ? dispatch({ type: "activePamojas", payload: record.activePamojas ?? null }) : <></>;
      superpamojaState.pamojaType === pamojaType.userPamoja ? dispatch({ type: "reward", payload: record.creatorRewards ?? null }) : <></>;
      superpamojaState.pamojaType === pamojaType.userPamoja ? dispatch({ type: "openDialog", payload: true }) : dispatch({ type: "openDialog", payload: false });
      superpamojaState.pamojaType === pamojaType.superMoja ? dispatch({ type: "isSuperpamojaEdit", payload: true }) : dispatch({ type: "isSuperpamojaEdit", payload: false });
      superpamojaState.pamojaType === pamojaType.superMoja ? dispatch({ type: "openSupermojaDialog", payload: true }) : dispatch({ type: "openSupermojaDialog", payload: false });
      superpamojaState.pamojaType === pamojaType.superMoja ? dispatch({ type: "pamojaConfigId", payload: record?.pamojaConfigId }) : dispatch({ type: "pamojaConfigId", payload: record?.pamojaConfigId });
      superpamojaState.pamojaType === pamojaType.superMoja ? dispatch({ type: "pamojaId", payload: record?.pamojaId }) : dispatch({ type: "pamojaId", payload: record?.pamojaConfigId });
      superpamojaState.pamojaType === pamojaType.superMoja ? dispatch({ type: "defaultQuantity", payload: record?.defaultQuantity }) : <></>;
    }
  };

  const handleSupermojaDialog = () => {
    dispatch({ type: "isSuperpamojaEdit", payload: false });
    dispatch({ type: "openSupermojaDialog", payload: true });
  };
  const csvReport = {
    data: exportTable,
    headers: headersExport,
    filename: `Products.csv`
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Pamoja </title>
      </Helmet>
      <PamojaFilter />
      <PamojaConfig />
      <SupermojaConfig />
      <SystemLoader />
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4}>
            <Typography variant="h4" sx={{ py: 2 }}>
              Pamoja
            </Typography>
          </Grid>
          <Grid item xs={8} style={headerStyle.searchContainer}>
            <TextField
              onChange={debounce((e) => {
                const searchValue = e.target.value;
                if (searchValue.length < 3) {
                  setSearchText(null);
                  updateTableData(pageSize, 0, pamojaStatus);
                } else {
                  setSearchText(searchValue);
                  updateTableData(pageSize, 0, pamojaStatus);
                }
              }, 300)}
              placeholder="Search Product"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                    <Search />
                  </InputAdornment>
                ),
                style: { ...headerStyle.searchTextField, marginRight: "1em" },
                inputProps: {
                  style: headerStyle.placeHolderText
                }
              }}
            />
            <Button
              style={buttonStyle.filterButton}
              onClick={() => {
                dispatch({ type: "pamojaFilter", payload: true });
              }}
              endIcon={<img alt="add" src="/assets/icons/button/filter.svg" style={{ fontSize: "22px" }} />}
            ></Button>
          </Grid>
        </Grid>
        <Divider />
        {/*Subheader Container */}
        <Grid sx={{ my: 2 }} maxWidth="xxl" spacing={2} gap={2} style={{ ...headerStyle.subHeaderContainer, justifyContent: "space-between" }}>
          <Grid item xs={6} style={buttonStyle.buttonContainer}>
            <Button
              onClick={() => {
                setSearchText(null);
                dispatch({ type: "pamojaType", payload: pamojaType.userPamoja });
                setTableArray([]);
                setPage(0);
              }}
              style={{ ...buttonStyle.simpleButton, backgroundColor: superpamojaState.pamojaType === pamojaType.userPamoja ? "#EF7B22" : null, color: superpamojaState.pamojaType === pamojaType.userPamoja ? "#FFF" : "#2C2C2C" }}
            >
              By User
            </Button>
            <Button
              onClick={() => {
                setSearchText(null);
                dispatch({ type: "pamojaType", payload: pamojaType.superMoja });
                setTableArray([]);
                setPage(0);
                dispatch({ type: "supermojaClear" });
              }}
              style={{ ...buttonStyle.simpleButton, backgroundColor: superpamojaState.pamojaType === pamojaType.superMoja ? "#EF7B22" : null, color: superpamojaState.pamojaType === pamojaType.superMoja ? "#FFF" : "#2C2C2C" }}
            >
              By Tushop
            </Button>
          </Grid>
          <Grid item xs={6} style={headerStyle.subHeaderContainer}>
            {superpamojaState.pamojaType === pamojaType.superMoja ? (
              <Grid item xs={4} style={{ marginRight: "1em" }}>
                <Button onClick={() => handleSupermojaDialog()} style={{ ...buttonStyle.simpleButton, backgroundColor: "#EF7B22", color: "#fff", paddingBottom: "1em", paddingTop: "1em" }} endIcon={<img alt="add" src="/assets/icons/button/add_icon.svg" />}>
                  Create Supermoja
                </Button>
              </Grid>
            ) : null}

            <Grid item xs={4} style={{ marginRight: "1em" }}>
              <FormControl style={{ width: "200px" }}>
                <InputLabel id="Status">Status</InputLabel>
                <Select
                  labelId="Status"
                  value={status}
                  name="status"
                  label="Status"
                  onChange={(e) => {
                    handleChangeStatus(e);
                  }}
                >
                  <MenuItem value={pamojaChangeStatus.all}>ALL</MenuItem>
                  <MenuItem value={pamojaChangeStatus.active}>Active</MenuItem>
                  <MenuItem value={pamojaChangeStatus.inActive}>Inactive</MenuItem>
                  <MenuItem value={pamojaChangeStatus.draft}>Draft</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: "200px" }}>
                <InputLabel id="Status">Export</InputLabel>
                <Select
                  labelId="Status"
                  value={exportType}
                  name="status"
                  label="Status"
                  onChange={(e) => {
                    fetchExportData(e);
                  }}
                >
                  <MenuItem value={pamojaCsvExports.ignore}>Export</MenuItem>
                  <MenuItem value={pamojaCsvExports.productSpecific}>Product Specific</MenuItem>
                  <MenuItem value={pamojaCsvExports.pamojaSpecific}>Pamoja Specific</MenuItem>
                  <MenuItem value={pamojaCsvExports.pamojaUserSpefic}>Pamoja Users Specific</MenuItem>
                  <MenuItem value={pamojaCsvExports.pamojaOrders}>Pamoja Orders</MenuItem>
                </Select>
              </FormControl>
              {exportt ? <CSVDownload {...csvReport} /> : <></>}
            </Grid>
          </Grid>
        </Grid>
        <Grid maxWidth="xxl">
          {tableArray && totalProductsCount === 0 && superpamojaState?.pamojaType === pamojaType.superMoja ? (
            <Grid style={tableStyle.supermojaIconContainer}>
              <Grid item>
                <img alt="persons" src="/assets/icons/persons_tripple.svg" />
              </Grid>
              <Grid item>
                <Typography style={{ ...fontStyle.normalText }}>Get Started</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ ...fontStyle.normalText, fontWeight: "400" }}>Use the button below to start a Supermoja</Typography>
              </Grid>
              <Grid item>
                <Button onClick={() => handleSupermojaDialog()} style={addProductButton} endIcon={<img alt="add" src="/assets/icons/button/add_icon.svg" />}>
                  Create Supermoja
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              <TableContainer>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                      <StyledTableCell2>{"___"}</StyledTableCell2>
                      <EnhancedTableHead headCells={pamojaHeadCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} />
                      <StyledTableCell2>{""}</StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <CircularProgress sx={{ color: colors.primary }} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      stableSort(tableArray, getComparator(order, orderBy)).map((record) => (
                        <TableRow key={superpamojaState.pamojaType === pamojaType.userPamoja ? record.productVariantId : record.pamojaConfigId}>
                          <TableCell> {!record.warning ? <></> : <img style={statusButtons} alt="add" src="/assets/icons/orange_warning.svg" />} </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.productName}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.brandName}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{record.variationName}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{superpamojaState.pamojaType === pamojaType.userPamoja ? record.sokoPrice : record.defaultQuantity === 0 ? record.sokoPrice : record.sokoPrice * record.defaultQuantity}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{checkNull(record.pamojaPrice)}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{checkNull(record.qtyAllocated)}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{checkNull(record.qtyAvailable)}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={pTable}>{checkNull(record.qtySold)}</p>
                          </TableCell>
                          {superpamojaState.pamojaType === pamojaType.userPamoja ? (
                            <TableCell align="left">
                              <p style={pTable}>{checkNull(record.activePamojas)}</p>
                            </TableCell>
                          ) : null}
                          <TableCell align="left">
                            <p style={pTable}>{record.status}</p>
                          </TableCell>
                          {superpamojaState.pamojaType === pamojaType.userPamoja ? (
                            <TableCell align="left">
                              <p style={pTable}>
                                {record.sokoPrice === null ? (
                                  <></>
                                ) : (
                                  <a href="#" onClick={() => handleOpenDetails(record)}>
                                    Modify
                                  </a>
                                )}
                              </p>
                            </TableCell>
                          ) : (
                            <TableCell size="small" padding="none" align="left">
                              <p style={pTable}>
                                {record.sokoPrice === null ? (
                                  <></>
                                ) : (
                                  <a href="#">
                                    <MoreVert color="#404040" fontSize="small" onClick={(e) => handleActionPopeverOpen(e, record)} />
                                  </a>
                                )}
                              </p>
                            </TableCell>
                          )}
                          <ActionPopever openActionPopover={openActionPopover} setOpenActionPopover={setOpenActionPopover} details={popoverRecordDeatils} handleOpenDetails={handleOpenDetails} />
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ height: "20px" }}>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25]}
                  component="div"
                  count={totalProductsCount}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(e, newVal) => {
                    updateTableData(pageSize, newVal, pamojaStatus);
                  }}
                  onRowsPerPageChange={(e) => {
                    updateTableData(e.target.value, page, pamojaStatus);
                  }}
                />
              </div>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
