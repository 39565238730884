import { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Switch, TextField, Typography } from "@mui/material";
// eslint-disable-next-line
import { addProductButton, addVariantButton, pamojaBox, pamojaDetailsHeading, pamojaDetailsHeading2, pmDetailsHeadingText } from "src/styles/styles";
import { alpha, styled } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { updatePamojaConfig } from "src/api/products";
import { GlobalContext } from "src/context/GlobalState";
import { allPamojaStatus, pamojaType } from "src/constants/constants";

const PamojaConfig = () => {
  const {
    dispatch,
    state: { pamojaState, superpamojaState, globalState }
  } = useContext(GlobalContext);
  const handleClose = () => {
    dispatch({ type: "clear" });
  };

  const OrangeSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: orange[600],
      "&:hover": {
        backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity)
      }
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: orange[600]
    }
  }));
  const handleDraftStatus = (active, draft) => (active && draft ? allPamojaStatus.draft : !active && draft ? allPamojaStatus.draft : active && !draft ? allPamojaStatus.active : allPamojaStatus.inActive);

  const handleConfirm = async (publishStatus, draftStatus) => {
    dispatch({ type: "savePamojaStatus", payload: publishStatus });
    if (pamojaState.pamojaPrice === null || pamojaState.pamojaPrice === 0) {
      alert("Please enter valid pamoja price");
    } else if (pamojaState.allocatedQty === null || pamojaState.allocatedQty === 0) {
      alert("Please enter valid allocated quantity");
    } else if (pamojaState.arrivesIn === null || pamojaState.arrivesIn === 0) {
      alert("Please enter valid days of arrival");
    } else if (pamojaState.expiresIn === null || pamojaState.expiresIn === 0) {
      alert("Please enter valid time of expiry");
    } else if (pamojaState.usersPerPamoja === null || pamojaState.usersPerPamoja === 0) {
      alert("Please enter valid users per pamoja");
    } else if (pamojaState.maxQty === null || pamojaState.maxQty === 0) {
      alert("Please enter valid maximum quantity per user");
    } else if (pamojaState.reward === null || pamojaState.reward === 0) {
      alert("Please enter valid creator rewards");
    } else {
      const body = {
        productVariantId: pamojaState.productVariantId,
        sokoPrice: pamojaState.sokoPrice,
        pamojaPrice: pamojaState.pamojaPrice,
        savings: pamojaState.sokoPrice - pamojaState.pamojaPrice,
        allocatedQty: pamojaState.allocatedQty,
        arrivesIn: pamojaState.arrivesIn,
        expiresIn: pamojaState.expiresIn,
        usersPerPamoja: pamojaState.usersPerPamoja,
        maxQtyPerUser: pamojaState.maxQty,
        rewards: pamojaState.reward,
        status: handleDraftStatus(publishStatus, draftStatus),
        showPamojaFeed: pamojaState?.showPamojaFeed ?? false,
        showProductDescription: pamojaState?.showProductDescription ?? false
      };
      const response = await updatePamojaConfig(body);

      if (response.httpStatusCode === 200) {
        alert("Pamoja configs updated successfully");
        dispatch({ type: "clear" });
        dispatch({ type: "reload", payload: !globalState.reload });
      } else {
        alert(response.message);
      }
    }
  };

  return (
    <Dialog open={pamojaState.openDialog} onClose={() => handleClose()} fullScreen style={{ width: "402px", height: "100%", position: "absolute", left: `${window.innerWidth - 410}px`, right: "0px" }}>
      {superpamojaState.pamojaType === pamojaType.userPamoja ? <DialogTitle> Manage Pamoja Configuration</DialogTitle> : null}
      <DialogContent>
        <div style={pamojaBox}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <img src={pamojaState.imgURL} style={{ width: "74px", height: "74px" }} alt="product" />
            </Grid>
            <Grid item xs={6}>
              <p style={pmDetailsHeadingText}>{pamojaState.productName}</p>
              {/* <br/> */}
              <p style={pamojaDetailsHeading}>{pamojaState.brandName}</p>
              <p style={pamojaDetailsHeading}>{pamojaState.variation}</p>
            </Grid>
            <Grid item xs={3}>
              <div style={{ padding: "18px" }}>
                <OrangeSwitch
                  checked={pamojaState.active}
                  onClick={(e) => {
                    dispatch({ type: "active", payload: e.target.checked });
                    dispatch({ type: "savePamojaStatus", payload: e.target.checked ? allPamojaStatus.active : allPamojaStatus.inActive });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={9}>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12} mb={0} pb={0}>
                <Typography style={pmDetailsHeadingText}> Show in Pamoja Feed</Typography>
              </Grid>
              <Grid item xs={12} mt={0} pt={0}>
                <Typography style={{ fontSize: "11.4px", color: "#8C8C8C" }}>Display product on the primary Pamoja feed</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "right" }}>
              <OrangeSwitch
                checked={pamojaState.showPamojaFeed}
                onClick={(e) => {
                  dispatch({ type: "showPamojaFeed", payload: e.target.checked });
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={9}>
            <Grid container spacing={1} direction="column">
              <Grid item xs={12} mb={0} pb={0}>
                <Typography style={pmDetailsHeadingText}> Show Product Description</Typography>
              </Grid>
              <Grid item xs={12} mt={0} pt={0}>
                <Typography style={{ fontSize: "11.4px", color: "#8C8C8C" }}>Show details about the product being offered</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <div style={{ textAlign: "right" }}>
              <OrangeSwitch
                checked={pamojaState?.showProductDescription ?? false}
                onClick={(e) => {
                  dispatch({ type: "showProductDescription", payload: e.target.checked });
                }}
              />
            </div>
          </Grid>
        </Grid>
        <p style={pmDetailsHeadingText}>Price Definition</p>
        <p style={pamojaDetailsHeading2}>Define your pamoja price for this SKU</p>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField aria-label="Soko Price" label="Soko Price" margin="normal" value={pamojaState.sokoPrice} variant="outlined" disabled />
          </Grid>
          <Grid item xs={4}>
            <TextField
              aria-label="Pamoja Price"
              onChange={(e) => {
                dispatch({ type: "pamojaPrice", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              label="Pamoja Price"
              margin="normal"
              value={pamojaState.pamojaPrice}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              aria-label="Savings"
              onChange={(e) => {
                dispatch({ type: "productDesc", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              label="Savings"
              margin="normal"
              value={pamojaState.sokoPrice - pamojaState.pamojaPrice}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <p style={pmDetailsHeadingText}>SKU settings</p>
        <p style={pamojaDetailsHeading2}>State the rules that govern the launch of each Pamoja</p>
        <Grid container spacing={1} sx={{ my: 2 }} direction="column">
          <Grid item xs={6}>
            {" "}
            <Typography style={pamojaDetailsHeading2}>No. of Pamojas</Typography>
          </Grid>
          <Grid item xs={6}>
            {" "}
            <Typography>{pamojaState.activePamojas}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField disabled aria-label="Available Qty" label="Available Qty" margin="normal" value={pamojaState.availableQty} variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              aria-label="Allocated Qty"
              onChange={(e) => {
                dispatch({ type: "allocatedQty", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              label="Allocated Qty"
              margin="normal"
              value={pamojaState.allocatedQty}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              aria-label="Arrives in"
              onChange={(e) => {
                dispatch({ type: "arrivesIn", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              label="Arrives in (days)"
              margin="normal"
              value={pamojaState.arrivesIn}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              aria-label="Expires in"
              onChange={(e) => {
                dispatch({ type: "expiresIn", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              label="Expires in (Hours)"
              margin="normal"
              value={pamojaState.expiresIn}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <p style={pmDetailsHeadingText}>User settings</p>
        <p style={pamojaDetailsHeading2}>Set the scope of users and their participation in Pamoja</p>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              aria-label="Users per Pamoja"
              onChange={(e) => {
                dispatch({ type: "usersPerPamoja", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              label="Users per Pamoja"
              margin="normal"
              value={pamojaState.usersPerPamoja}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              aria-label="Max qty per user"
              onChange={(e) => {
                dispatch({ type: "maxQty", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              label="Max qty per user"
              margin="normal"
              value={pamojaState.maxQty}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <p style={pmDetailsHeadingText}>Rewards</p>
        <p style={pamojaDetailsHeading2}>Setup rewards when a successful Pamoja is achieved</p>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              aria-label="Creator Rewards"
              label="Creator Rewards"
              onChange={(e) => {
                dispatch({ type: "reward", payload: e.target.value === "" ? null : parseInt(e.target.value) });
              }}
              margin="normal"
              value={pamojaState.reward}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={8}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <span style={{ flexGrow: 1 }} />
        <Button onClick={(e) => handleClose()} variant="outlined" style={addVariantButton}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          style={addVariantButton}
          onClick={() => {
            handleConfirm(allPamojaStatus.draft, true);
          }}
        >
          Save
        </Button>
        <Button
          style={addProductButton}
          onClick={() => {
            handleConfirm(pamojaState.savePamojaStatus === allPamojaStatus.active ? allPamojaStatus.active : allPamojaStatus.inActive, false);
          }}
        >
          Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PamojaConfig;
