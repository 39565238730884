import { Helmet } from "react-helmet-async";
import { useState, useEffect, useContext, useRef } from "react";
import { CSVDownload } from "react-csv";
import TableCell from "@mui/material/TableCell";
// @mui
import { Button, CircularProgress, Container, Divider, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
// components

import { addProductButton, addVariantButton, pTable, statusButtons, tableHeadText, warningButton } from "../../styles/styles";

// eslint-disable-next-line
import { EnhancedTableHead } from "src/utils/EnhanceTableHead";
import { StyledTableCell, StyledTableCell2 } from "../../styles/styles";
import { getComparator, stableSort } from "src/utils/sortTables";
import { fetchTableData } from "src/api/products";
import { productHeadCells } from "src/utils/product";
import { Search } from "@mui/icons-material";
import { GlobalContext } from "src/context/GlobalState";
import headerStyle from "src/styles/headerStyle";
// mock
// import PRODUCTS from '../_mock/products';
import { debounce } from "lodash";
import { PRODUCT_VIEW, colors, filterStatus } from "src/constants/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import buttonStyle from "src/styles/buttonStyle";
import { useQuery } from "src/utils/utils";

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const navigate = useNavigate();

  //Handle search persist
  const searchQuery = useQuery().get("search") || null;

  const [exportt, setExport] = useState(false);
  const [tableArray, setTableArray] = useState([]);
  const [exportTable, setExportTable] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("warning");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchQuery);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState(PRODUCT_VIEW.catalogue);
  const [status, setStatus] = useState(filterStatus.all);
  let prevSearchText = useRef("%%");

  const {
    dispatch,
    state: { productState, globalState }
  } = useContext(GlobalContext);

  useEffect(() => {
    if (searchText && searchText.trim().length >= 3 && searchText !== "null") {
      setSearchParams({ search: searchText });
    } else {
      setSearchText(null);
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        updateTableData(pageSize, page, searchText);
      } catch (error) {
        setLoading(false);
        alert(error.message);
      }
    };
    prevSearchText.current = searchText === null ? "%%" : searchText;
    if (!productState.addProduct && !productState.addVariant && searchText !== prevSearchText.current) {
      fetchData();
    }
    if (productState.productReload || searchText) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [searchText, globalState.reload, productState.productReload, currentView, status]);

  const updateTableData = async (pageSize, page, searchText) => {
    setLoading(true);
    let textToSearch = searchText && searchText.trim().length >= 3 ? searchText : null;
    let response = await fetchTableData({ pageSize, pageNumber: page, searchText: textToSearch, filter: { status: productState.filterStatuses } });

    if (response.httpStatusCode === 200) {
      const array = [];
      const { products, pageNumber, pageSize, totalProductsCount } = response.result.data;
      products.forEach((data) => {
        const { brand, categories, product, productVariantId, skuType, unit, variantType, status, vatId, warning } = data;
        const body = {
          productVariantId,
          categories,
          category: categories[0]?.categoryName ?? null,
          categoryId: categories[0]?.categoryId ?? null,
          productName: product.name,
          productDesc: product.description,
          additionalDetails: product.additionalDetails,
          productId: product.productId,
          brandName: brand.name,
          brandId: brand.brandId,
          brand,
          unit: unit.name,
          unitId: unit.unitId,
          variant: variantType.name,
          variantTypeId: variantType.variantTypeId,
          sku: skuType.name,
          skuID: skuType.skuTypeId,
          status,
          vatId,
          warning
        };

        array.push(body);
      });
      setTableArray(array);
      setPage(parseInt(pageNumber));
      setPageSize(parseInt(pageSize));
      setTotalProductsCount(totalProductsCount);

      setSearchParams({ ...searchParams, search: searchText });
      dispatch({ type: "productClear" });
      setLoading(false);
    } else {
      setLoading(false);
      alert(response?.message);
      setTableArray([]);
    }
  };

  const handleSearchChange = debounce((e) => {
    const searchValue = e.target.value;
    setCurrentView(PRODUCT_VIEW.catalogue);
    if (searchValue.length < 3) {
      setSearchText(searchValue);
    } else {
      setLoading(true);
      setSearchText(searchValue);
      setSearchParams({ search: searchValue });
      updateTableData(pageSize, page, searchValue);
    }
  }, 300);

  const fetchExportData = async (pageSize) => {
    setCurrentView(PRODUCT_VIEW.catalogue);
    let textToSearch = searchText && searchText.trim().length >= 3 ? searchText : null;
    const responseExport = await fetchTableData({ pageSize, pageNumber: page, searchText: textToSearch, filter: { status: productState.filterStatuses } });
    if (responseExport.httpStatusCode === 200) {
      const { products } = responseExport.result.data;
      const array2 = [];
      products.forEach((data) => {
        const { brand, categories, product, productVariantId, skuType, unit, variantType, status, vatId, warning } = data;
        const body = {
          productVariantId,
          categories,
          category: categories[0]?.categoryName ?? null,
          categoryId: categories[0]?.categoryId ?? null,
          productName: product.name,
          productDesc: product.description,
          additionalDetails: product.additionalDetails,
          productId: product.productId,
          brandName: brand.name,
          brandId: brand.brandId,
          brand,
          unit: unit.name,
          unitId: unit.unitId,
          variant: variantType.name,
          variantTypeId: variantType.variantTypeId,
          sku: skuType.name,
          skuID: skuType.skuTypeId,
          status,
          vatId,
          warning
        };

        array2.push(body);
      });

      setExportTable(array2);

      if (exportt) {
        setExport(false);

        setTimeout(() => {
          setExport(true);
        }, 500);
      } else {
        setExport(true);
      }
    } else {
      alert(responseExport.message);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const headers = [
    { label: "productVariantId", key: "productVariantId" },
    { label: "Category", key: "category" },
    { label: "categoryId", key: "categoryId" },
    { label: "productName", key: "productName" },
    { label: "productDesc", key: "productDesc" },
    { label: "productId", key: "productId" },
    { label: "brandName", key: "brandName" },
    { label: "brandId", key: "brandId" },
    { label: "unit", key: "unit" },
    { label: "unitId", key: "unitId" },
    { label: "variant", key: "variant" },
    { label: "variantTypeId", key: "variantTypeId" },
    { label: "sku", key: "sku" },
    { label: "skuID", key: "skuID" },
    { label: "status", key: "status" }
  ];

  const csvReport = {
    data: exportTable,
    headers,
    filename: `Products.csv`
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Products </title>
      </Helmet>
      {/* <AddProductDialog />
      <AddVariantDialog /> */}
      <Container maxWidth="xxl" sx={{ ml: 0, mt: 0, pb: 0 }}>
        {/* Header Container */}
        <Grid container spacing={1} sx={{ mt: -4 }} style={headerStyle.headerContainer}>
          <Grid item xs={4} sx={{ mb: 3.5 }}>
            <Typography variant="h4">Products </Typography>
          </Grid>
          <Grid item xs={8} style={headerStyle.searchContainer}>
            <TextField
              onChange={handleSearchChange}
              placeholder="Search Product"
              defaultValue={searchText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                    <Search />
                  </InputAdornment>
                ),
                style: headerStyle.searchTextField,
                inputProps: {
                  style: headerStyle.placeHolderText
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        {/*Subheader Container */}
        <Grid sx={{ my: 2 }} maxWidth="xxl" spacing={2} gap={2} style={{ ...headerStyle.subHeaderContainer, justifyContent: "space-between" }}>
          <Grid gap={1} item xs={6} style={buttonStyle.buttonContainer}>
            <Button onClick={(e) => navigate("/dashboard/", { replace: true })} style={{ ...buttonStyle.simpleButton, backgroundColor: currentView === PRODUCT_VIEW.catalogue ? "#EF7B22" : "#2C2C2C", color: currentView === PRODUCT_VIEW.catalogue ? "#FFF" : "##D9D9D9" }}>
              Catalogue
            </Button>
            <Button onClick={(e) => navigate("/dashboard/categories", { replace: true })} style={{ ...buttonStyle.simpleButton, backgroundColor: currentView === PRODUCT_VIEW.categories ? "#EF7B22" : null, color: currentView === PRODUCT_VIEW.categories ? "#FFF" : "#2C2C2C" }}>
              Categories
            </Button>
          </Grid>
          <Grid gap={1} style={{ ...headerStyle.subHeaderContainer, justifyContent: "space-between" }}>
            <Grid item xs={4} style={{ marginRight: "1em" }}>
              <FormControl style={{ width: "200px" }}>
                <InputLabel id="Status">Status</InputLabel>
                <Select
                  labelId="Status"
                  name="status"
                  value={status}
                  label="Status"
                  onChange={(e) => {
                    if (parseInt(e.target.value) === filterStatus.active) {
                      setStatus(filterStatus.active);
                      dispatch({ type: "filterStatuses", payload: [filterStatus.active] });
                    } else if (parseInt(e.target.value) === filterStatus.inActive) {
                      setStatus(filterStatus.inActive);
                      dispatch({ type: "filterStatuses", payload: [filterStatus.inActive] });
                    } else {
                      setStatus(filterStatus.all);
                      dispatch({ type: "filterStatuses", payload: [filterStatus.active, filterStatus.inActive] });
                    }
                  }}
                  sx={{ p: 0.7, "& .MuiOutlinedInput-input": { p: 0.7 } }}
                >
                  <MenuItem value={filterStatus.active}>Active</MenuItem>
                  <MenuItem value={filterStatus.inActive}>InActive</MenuItem>
                  <MenuItem value={filterStatus.all}>All</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={(e) => {
                  navigate(`/dashboard/products/details/add`);
                }}
                style={addProductButton}
                endIcon={<img alt="add" src="/assets/icons/button/add_icon.svg" />}
              >
                Add Product
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={(e) => {
                  navigate(`/dashboard/products/details/variant`);
                }}
                variant="outlined"
                style={addVariantButton}
                endIcon={<img alt="add" src="/assets/icons/button/add_icon_black.svg" />}
              >
                Add Variant
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button onClick={() => fetchExportData(totalProductsCount)} variant="outlined" style={addVariantButton} endIcon={<img alt="add" src="/assets/icons/button/arrow_down_black.svg" />}>
                Export
              </Button>
              {exportt ? <CSVDownload {...csvReport} /> : <></>}
            </Grid>
          </Grid>
        </Grid>

        {/*Table Container */}
        <Grid maxWidth="xxl" sx={{ mt: 2 }}>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow style={{ backgroundColor: "#FFD4B3" }}>
                  <EnhancedTableHead headCells={productHeadCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={tableArray.length} />
                  <StyledTableCell align="left">
                    <p style={tableHeadText}>Status</p>
                  </StyledTableCell>

                  <StyledTableCell2>{""}</StyledTableCell2>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress sx={{ color: colors.primary }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  stableSort(tableArray, getComparator(order, orderBy)).map((record) => (
                    <TableRow key={record.productVariantId}>
                      <TableCell> {record.warning ? <img style={warningButton} alt="add" src="/assets/icons/orange_warning.svg" /> : null} </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start"
                          }}
                        >
                          <p style={{ ...pTable, color: !record?.category ? "gray" : "#333" }}>{record?.category ?? "unassigned"}</p>
                          <span style={{ flexGrow: 0.5 }} />
                          {record.categories && record.categories.length > 1 ? (
                            <Tooltip title={<div>{record.categories.map((data, index) => (index === 0 ? null : <p>{data?.categoryName ?? null}</p>))}</div>}>
                              <div
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "8px",
                                  background: "#232F3E"
                                }}
                              >
                                <p
                                  style={{
                                    lineHeight: "12px",
                                    fontSize: "12px",
                                    padding: "1px 3px",
                                    color: "white",
                                    fontWeight: "700"
                                  }}
                                >
                                  +{record.categories.length - 1}
                                </p>
                              </div>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <p style={pTable}>{record.productName}</p>
                      </TableCell>
                      <TableCell align="left">
                        <p style={pTable}>{record.brandName}</p>
                      </TableCell>
                      <TableCell align="left">
                        <p style={pTable}>
                          {record.variant} - {record.unit}
                        </p>
                      </TableCell>
                      <TableCell align="left">
                        <p style={pTable}>{record.sku}</p>
                      </TableCell>
                      <TableCell align="left">{record.status === "Active" ? <img alt="add" style={statusButtons} src="/assets/icons/status/active-status.svg" /> : <img alt="add" style={statusButtons} src="/assets/icons/status/inactive-status.svg" />}</TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={(e) => {
                            navigate(`/dashboard/products/details/${record.productVariantId}${searchText ? `?search=${searchText}` : ""}`);
                          }}
                        >
                          <img alt="add" src="/assets/icons/button/edit.svg" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ height: "20px" }}>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={totalProductsCount}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={(e, newVal) => {
                updateTableData(pageSize, newVal, searchText);
              }}
              onRowsPerPageChange={(e) => {
                updateTableData(e.target.value, page, searchText);
              }}
            />
          </div>
        </Grid>
      </Container>
    </>
  );
}
