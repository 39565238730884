import { allPamojaStatus } from "src/constants/constants";

const getFromDate = () => {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  const startDate = date.getFullYear() + "-" + (date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " 00:00:00";

  return startDate;
};

const getDefaultEndDate = () => {
  const date = new Date();
  const today = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()) + ":00";

  return today;
};

export const pamojaInitialState = {
  openDialog: false,
  sokoPrice: 0,
  pamojaPrice: 0,
  allocatedQty: 0,
  availableQty: 0,
  arrivesIn: 0,
  expiresIn: 0,
  usersPerPamoja: 0,
  maxQty: 0,
  reward: 0,
  productVariantId: null,
  productName: null,
  imgURL: null,
  brandName: null,
  variation: null,
  active: false,
  pamojaFilter: false,
  pamojaStatuses: [0, 1, 2, 3],
  fromDate: getFromDate(),
  exportProgress: false,
  // fromDate: "2023-04-15T00:00:00",
  toDate: getDefaultEndDate(),
  showPamojaFeed: false,
  savePamojaStatus: allPamojaStatus.inActive,
  activePamojas: null,
  showProductDescription: false
};

export const pamojaReducer = (state, action) => {
  switch (action.type) {
    case "openDialog":
      return { ...state, openDialog: action.payload };
    case "pamojaFilter":
      return { ...state, pamojaFilter: action.payload };
    case "exportProgress":
      return { ...state, exportProgress: action.payload };
    case "pamojaStatuses":
      return { ...state, pamojaStatuses: action.payload };
    case "fromDate":
      return { ...state, fromDate: action.payload };
    case "toDate":
      return { ...state, toDate: action.payload };
    case "sokoPrice":
      return { ...state, sokoPrice: action.payload };
    case "pamojaPrice":
      return { ...state, pamojaPrice: action.payload };
    case "allocatedQty":
      return { ...state, allocatedQty: action.payload };
    case "availableQty":
      return { ...state, availableQty: action.payload };
    case "arrivesIn":
      return { ...state, arrivesIn: action.payload };
    case "expiresIn":
      return { ...state, expiresIn: action.payload };
    case "usersPerPamoja":
      return { ...state, usersPerPamoja: action.payload };
    case "maxQty":
      return { ...state, maxQty: action.payload };
    case "reward":
      return { ...state, reward: action.payload };
    case "productVariantId":
      return { ...state, productVariantId: action.payload };
    case "productName":
      return { ...state, productName: action.payload };
    case "imgURL":
      return { ...state, imgURL: action.payload };
    case "brandName":
      return { ...state, brandName: action.payload };
    case "variation":
      return { ...state, variation: action.payload };
    case "active":
      return { ...state, active: action.payload };
    case "showPamojaFeed":
      return { ...state, showPamojaFeed: action.payload };
    case "savePamojaStatus":
      return { ...state, savePamojaStatus: action.payload };
    case "activePamojas":
      return { ...state, activePamojas: action.payload };
    case "showProductDescription":
      return { ...state, showProductDescription: action.payload };
    case "clear":
      return {
        ...state,
        openDialog: false,
        sokoPrice: 0,
        pamojaPrice: 0,
        allocatedQty: 0,
        availableQty: 0,
        arrivesIn: 0,
        expiresIn: 0,
        usersPerPamoja: 0,
        maxQty: 0,
        reward: 0,
        productVariantId: null,
        productName: null,
        imgURL: null,
        brandName: null,
        variation: null,
        active: false,
        pamojaFilter: false,
        exportProgress: false,
        pamojaStatuses: [0, 1, 2, 3],
        fromDate: getFromDate(),
        toDate: getDefaultEndDate(),
        savePamojaStatus: allPamojaStatus.inActive,
        showProductDescription: false
      };
    default:
      return state;
  }
};
