import { colors } from "src/constants/constants"

const orderStyle ={
    orderContainer: {
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        padding: "1em 1em",
        backgroundColor: "#FFF"
    },
    title:{
        fontWeight: 700
    },
    subTitle: {
        fontSize: "10px",
        color: "#8C8C8C",
        lineHeight: "0px"
    },
    underlineText: {
        fontSize: "18px",
        fontWeight: "700px",
        color: "#59B7B2",
        lineHeight: "22px",
        textDecoration: "underline"
    },
    text:{
        fontSize: "14px",
    },
    subText:{
        fontSize: "12px",
    },
    buttonStatus:{
        padding: "3px 7px",
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: "500",
        margin: "auto",
        display: 'inline-block',
        borderRadius: "8px",
        textAlign: "center",
        float: "right"
    }
}

export default orderStyle