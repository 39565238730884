import React, { useContext } from "react";
import { Box, Popover, Typography } from "@mui/material";
import { allPamojaStatus } from "src/constants/constants";
import { createOrUpdateSupermojaConfig } from "src/api/products";
import { GlobalContext } from "src/context/GlobalState";

export default function ActionPopever({ openActionPopover, setOpenActionPopover, details, handleOpenDetails }) {
  const {
    dispatch,
    state: { globalState }
  } = useContext(GlobalContext);
  const notClickableStatus = ["Completed", "Expired"];

  const handleRemoveSuperMoja = async (details) => {
    try {
      const body = {
        pamojaConfigId: details.pamojaConfigId,
        productVariantId: details.productVariantId,
        sokoPrice: details.sokoPrice,
        pamojaPrice: details?.pamojaPrice ?? details.sokoPrice,
        savings: details?.pamojaPrice ? details.sokoPrice - details?.pamojaPrice : 0,
        allocatedQty: details.qtyAllocated,
        arrivesIn: details.arrivesIn,
        expiresIn: details.expiresIn,
        usersPerPamoja: details.userPerPamoja,
        maxQtyPerUser: details.maxQuantityPerUser,
        status: allPamojaStatus.inActive,
        showPamojaFeed: details.showPamojaFeed,
        showProductDescription: details.showProductDescription
      };

      const response = await createOrUpdateSupermojaConfig(body);
      if (response.httpStatusCode === 200) {
        alert("Your pamoja has been removed successfully");
        dispatch({ type: "supermojaClear" });
        dispatch({ type: "reload", payload: !globalState.reload });
      } else {
        alert(response?.message ?? "Unable to remove your pamoja");
      }
      setOpenActionPopover(null);
    } catch (error) {
      alert(error?.message);
    }
  };
  return (
    <>
      <Popover
        open={Boolean(openActionPopover)}
        anchorEl={openActionPopover}
        onClose={() => setOpenActionPopover(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 120,
            elevation: 0,
            boxShadow: "none",
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75
            }
          }
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            onClick={() => {
              if (notClickableStatus.includes(details.status)) {
                setOpenActionPopover(null);
              } else {
                setOpenActionPopover(null);
                handleOpenDetails(details);
              }
            }}
            sx={{ m: 1, cursor: "pointer" }}
          >
            {" "}
            Modify{" "}
          </Typography>
          <Typography onClick={() => handleRemoveSuperMoja(details)} sx={{ m: 1, color: "red", cursor: "pointer" }}>
            {" "}
            Remove{" "}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
