import React, { useContext, useState, useRef } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Grid, InputAdornment, Switch, Table, TableCell, TableRow, TextField, Typography, CircularProgress } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { GlobalContext } from "src/context/GlobalState";
import { Search } from "@mui/icons-material";
import headerStyle from "src/styles/headerStyle";
import pamojaStyles from "src/styles/pamojaStyle";
import { pamojaDetailsHeading, pamojaDetailsHeading2, pmDetailsHeadingText } from "src/styles/styles";
import buttonStyle from "src/styles/buttonStyle";
import { allPamojaStatus, pamojaType } from "src/constants/constants";
import { createOrUpdateSupermojaConfig, searchProductDetails } from "src/api/products";
import { debounce } from "lodash";
const SupermojaConfig = () => {
  const {
    dispatch,
    state: { superpamojaState, globalState }
  } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState(null);
  const [searchProductArray, setSearchProductArray] = useState([]);
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [totalProductSearchCount, setTotalProductSearchCount] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageSizeLimitScroll, setPageSizeLimitScroll] = useState(null);
  const [pageNumberSearch, setPageNumberSearch] = useState(0);
  const [refreshPageNumber, setRefreshPageNumber] = useState(null);

  const handleClose = () => {
    setSearchText(null);
    setSearchLoading(false);
    setSearchProductArray([]);
    setPageNumberSearch(0);
    dispatch({ type: "supermojaClear" });
  };

  const OrangeSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: orange[600],
      "&:hover": {
        backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity)
      }
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: orange[600]
    }
  }));

  //handle publish of supermoja
  const handleSupermojaSaveAndPublish = async (publishStatus) => {
    try {
      dispatch({ type: "savePamojaStatus", payload: publishStatus });
      if (superpamojaState.pamojaPrice === null || superpamojaState.pamojaPrice === 0) {
        alert("Please enter valid pamoja price");
      } else if (superpamojaState.allocatedQty === null || superpamojaState.allocatedQty === 0) {
        alert("Please enter valid allocated quantity");
      } else if (superpamojaState.arrivesIn === null || superpamojaState.arrivesIn === 0) {
        alert("Please enter valid days of arrival");
      } else if (superpamojaState.expiresIn === null || superpamojaState.expiresIn === 0) {
        alert("Please enter valid time of expiry");
      } else if (superpamojaState.usersPerPamoja === null || superpamojaState.usersPerPamoja === 0) {
        alert("Please enter valid users per pamoja");
      } else if (superpamojaState.maxQty === null || superpamojaState.maxQty === 0) {
        alert("Please enter valid maximum quantity per user");
      } else if (superpamojaState.productVariantId === null) {
        alert("Please select a product");
      } else {
        const body = {
          pamojaConfigId: superpamojaState.pamojaConfigId,
          productVariantId: superpamojaState.productVariantId,
          sokoPrice: superpamojaState.sokoPrice,
          pamojaPrice: superpamojaState.pamojaPrice,
          savings: superpamojaState?.defaultQuantity === 0 ? superpamojaState.sokoPrice - superpamojaState.pamojaPrice : superpamojaState.sokoPrice * superpamojaState?.defaultQuantity - superpamojaState.pamojaPrice,
          allocatedQty: superpamojaState.allocatedQty,
          arrivesIn: superpamojaState.arrivesIn,
          expiresIn: superpamojaState.expiresIn,
          usersPerPamoja: superpamojaState.usersPerPamoja,
          maxQtyPerUser: superpamojaState.maxQty,
          status: publishStatus,
          showPamojaFeed: superpamojaState.showPamojaFeed,
          initialUsersCount: superpamojaState?.initialUsersCount ?? 0,
          defaultQuantity: superpamojaState?.defaultQuantity ?? 0,
          showProductDescription: superpamojaState?.showProductDescription ?? false
        };

        dispatch({ type: "loading", payload: true });
        const response = await createOrUpdateSupermojaConfig(body);
        dispatch({ type: "loading", payload: false });
        if (response.httpStatusCode === 200) {
          alert("Your pamoja has been configured");
          dispatch({ type: "supermojaClear" });
          dispatch({ type: "reload", payload: !globalState.reload });
          setSearchProductArray([]);
        } else {
          alert(response?.message ?? "Unable to configure your pamoja");
        }
      }
    } catch (error) {
      alert(error?.message);
    }
  };

  //handle cancel of supermoja
  const handleSupermojaCancel = () => {
    try {
      setSearchText(null);
      setSearchLoading(false);
      setSearchProductArray([]);
      setPageNumberSearch(0);
      dispatch({ type: "initialUsersCount", payload: 0 });
      dispatch({ type: "openSupermojaDialog", payload: false });
    } catch (error) {
      alert(error?.message);
    }
  };

  const handleProductSearch = async (e, finalPageNumberValue, searchVal) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (refreshPageNumber) finalPageNumberValue = 0;
      if (pageSizeLimitScroll !== 0 && finalPageNumberValue > pageSizeLimitScroll) return;
      setProductSelected(false);
      const arr = [];
      const arr2 = [];
      setSearchLoading(true);
      const response = await searchProductDetails(pageSize, finalPageNumberValue, searchText ?? searchVal);
      if (response.httpStatusCode === 200) {
        const products = response.result?.data?.products || [];
        if (products.length === 0) {
          setNotFound(true);
          setSearchLoading(false);
          return;
        }

        products.forEach((res) => {
          let obj = {
            productVariantId: res?.productVariantId,
            brandName: res?.brand?.name,
            productName: res?.product?.name,
            unitName: res?.unit?.name,
            productImage: res?.productVariantImage?.[0].imageUrl,
            variation: res?.variantType?.name,
            sokoPrice: res?.productVariantPrice?.sokoPrice
          };
          arr.push(obj);
          if (finalPageNumberValue === 0) arr2.push(obj);
        });
        const filteredData = arr.filter((newProduct) => !searchProductArray.some((product) => product.productVariantId === newProduct.productVariantId));
        if (finalPageNumberValue === 0) {
          setSearchProductArray(arr2);
        } else {
          setSearchProductArray((prevProducts) => [...prevProducts, ...filteredData]);
        }
        setSearchLoading(false);
        setTotalProductSearchCount(response.result?.data?.totalProductsCount);
        setPageSizeLimitScroll(Math.ceil(response.result?.data?.totalProductsCount / pageSize));
        setShowProductDropdown(true);
      }
    } catch (error) {
      setSearchLoading(false);
      setProductSelected(false);
      setSearchText(null);
      alert(error?.message);
    }
  };

  const handleProductSelect = (e, product) => {
    try {
      setProductSelected(true);
      e.preventDefault();
      e.stopPropagation();
      if (product.productVariantId === superpamojaState.productVariantId) {
        setSearchText(null);
        setSearchProductArray([]);
        setShowProductDropdown(false);
        setPageNumberSearch(0);
        return;
      }

      dispatch({ type: "productVariantId", payload: product.productVariantId });
      dispatch({ type: "brandName", payload: product.brandName });
      dispatch({ type: "imgURL", payload: product.productImage });
      dispatch({ type: "productName", payload: product.productName });
      dispatch({ type: "variation", payload: product.variation });
      dispatch({ type: "sokoPrice", payload: product.sokoPrice });
      setSearchText(null);
      setSearchProductArray([]);
      setShowProductDropdown(false);
      setPageNumberSearch(0);

      setTimeout(() => {
        setProductSelected(false);
      }, 300);
    } catch (error) {
      setSearchText(null);
      setSearchProductArray([]);
      alert(error?.message);
    }
  };

  return (
    <Dialog
      open={superpamojaState.openSupermojaDialog}
      onClose={() => handleClose()}
      fullWidth
      sx={{
        position: "fixed",
        width: "462px",
        left: "auto",
        right: 0,
        top: "5%",
        overflowY: "auto",
        height: "100%",
        marginTop: "-40px"
      }}
    >
      <Grid spacing={1} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "1em", width: "100%" }}>
        <Grid item xs={9}>
          {superpamojaState.isSuperpamojaEdit && superpamojaState.pamojaType === pamojaType.superMoja ? <DialogTitle>Manage Supermoja </DialogTitle> : <DialogTitle>Create Supermoja </DialogTitle>}
        </Grid>
        <Grid item xs={3} sx={{ justifyContent: "flex-end" }}>
          <img onClick={() => handleClose()} alt="add" src="/assets/icons/cancel.svg" style={{ fontSize: "22px", cursor: "pointer" }} />
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container maxWidth="xxl">
          {/*Search product */}
          <Grid sx={{ mb: 2 }}>
            <TextField
              onChange={debounce((e) => {
                const searchValue = e.target.value;
                if (searchValue === "" || searchValue.length < 3) {
                  setSearchText(null);
                  setPageNumberSearch(0);
                  setRefreshPageNumber(0);
                  setPageSizeLimitScroll(0);
                  setSearchProductArray([]);
                } else {
                  setTotalProductSearchCount(0);
                  setSearchText(searchValue);
                  handleProductSearch(e, 0, searchValue);
                }
              }, 300)}
              onKeyDown={debounce((e) => {
                if (e.keyCode === 13 && searchText) {
                  setTotalProductSearchCount(0);
                  handleProductSearch(e, 0, searchText);
                }
              }, 300)}
              placeholder="Search Product"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                    <Search />
                  </InputAdornment>
                ),
                style: { ...headerStyle.searchTextField, width: "138%" },
                inputProps: {
                  style: headerStyle.placeHolderText
                }
              }}
            />
            {notFound && searchProductArray.length === 0 && totalProductSearchCount === 0 ? (
              <>
                <Table
                  size="small"
                  style={{
                    ...pamojaStyles.searchProductListing,
                    width: "88%",
                    borderRadius: "8px"
                  }}
                >
                  <TableRow style={{ cursor: "pointer" }}>
                    <TableCell style={pamojaStyles.cellBorder}>Product not found</TableCell>
                  </TableRow>
                </Table>
              </>
            ) : null}
            <div
              style={{
                ...pamojaStyles.searchProductListing,
                width: "88%",
                overflow: "auto",
                height: searchProductArray.length === 0 ? "auto" : totalProductSearchCount <= 5 ? "auto" : 170,
                borderRadius: "8px"
              }}
            >
              <Table size="small" style={{ width: "100%", marginTop: "2px", marginBottom: "2px" }}>
                {showProductDropdown && searchProductArray && searchProductArray.length > 0 && !productSelected ? (
                  searchProductArray.map((product, index) => (
                    <React.Fragment key={product.productVariantId}>
                      <TableRow style={{ cursor: "pointer" }} onClick={(e) => handleProductSelect(e, product)}>
                        <TableCell style={{ ...pamojaStyles.cellBorder, border: "1px solid #333" }}>
                          {product.productName} {product.variation} {product.brandName} - {product.unitName}
                        </TableCell>
                      </TableRow>
                      {index === searchProductArray.length - 1 && searchLoading ? (
                        <TableRow>
                          <TableCell style={{ ...pamojaStyles.cellBorder, display: "flex", justifyContent: "center", border: "1px solid #333", borderRadius: 0 }}>
                            <CircularProgress size={18} variant="indeterminate" sx={{ color: "#EF7B22", "& svg": { padding: "3px" } }} />
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  ))
                ) : searchLoading ? (
                  <TableRow>
                    <TableCell style={{ ...pamojaStyles.cellBorder, display: "flex", justifyContent: "center", border: "1px solid #333", borderRadius: 0 }}>
                      <CircularProgress size={18} variant="indeterminate" sx={{ color: "#EF7B22", "& svg": { padding: "3px" } }} />
                    </TableCell>
                  </TableRow>
                ) : null}
              </Table>
            </div>
            {showProductDropdown && totalProductSearchCount !== searchProductArray.length && searchProductArray.length !== 0 ? (
              <div
                style={{
                  ...pamojaStyles.searchProductListing,
                  width: "88%",
                  zIndex: 1,
                  marginTop: 170
                }}
              >
                <Button
                  disableElevation={true}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let pageNumberValue = pageNumberSearch === 0 ? 1 : pageNumberSearch + 1;
                    let finalPageNumberValue = pageNumberValue >= pageSizeLimitScroll ? pageSizeLimitScroll : pageNumberValue;
                    setPageNumberSearch(pageNumberValue >= pageSizeLimitScroll ? pageSizeLimitScroll : pageNumberValue);
                    handleProductSearch(e, finalPageNumberValue, searchText);
                  }}
                  sx={{
                    width: "100%",
                    my: 1,
                    backgroundColor: "#EF7B22",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#EF7B22",
                      boxShadow: "none"
                    }
                  }}
                  variant="contained"
                >
                  {" "}
                  {searchLoading ? <CircularProgress size={18} variant="indeterminate" sx={{ color: "#FFF", "& svg": { padding: "3px" } }} /> : "Load More"}
                </Button>
              </div>
            ) : null}
          </Grid>

          {/*Product info */}
          {superpamojaState?.productVariantId ? (
            <>
              <Grid style={pamojaStyles.pamojaContainer}>
                <Grid item xs={3} sx={{ mr: 2 }}>
                  <img src={superpamojaState.imgURL} style={{ width: "74px", height: "74px" }} alt="product" />
                </Grid>
                <Grid item xs={6}>
                  <p style={pmDetailsHeadingText}>{superpamojaState.productName}</p>
                  {/* <br/> */}
                  <p style={pamojaDetailsHeading}>{superpamojaState.brandName}</p>
                  <p style={pamojaDetailsHeading}>{superpamojaState.variation}</p>
                </Grid>
                <Grid item xs={3}>
                  <div style={{ padding: "18px" }}>
                    <OrangeSwitch
                      checked={superpamojaState.active}
                      onClick={(e) => {
                        dispatch({ type: "active", payload: e.target.checked });
                        dispatch({ type: "savePamojaStatus", payload: e.target.checked ? allPamojaStatus.active : allPamojaStatus.inActive });
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid style={pamojaStyles.emptySuperPamojaContainer}>
              <p>No Product Selected</p>
            </Grid>
          )}

          <Grid item xs={6} sx={{ my: 2 }}>
            <Grid item xs={12} mb={0} pb={0}>
              <Typography style={pmDetailsHeadingText}> Default Quantity</Typography>
            </Grid>
            <TextField
              aria-label="Default Quantity"
              onChange={(e) => {
                dispatch({ type: "defaultQuantity", payload: e.target.value === "" ? 0 : parseInt(e.target.value) });
              }}
              label="Default Quantity"
              margin="normal"
              value={superpamojaState?.defaultQuantity ?? 0}
              variant="outlined"
            />
          </Grid>
          {/*Price deefinition*/}
          <Grid container spacing={2} mt={1} px={1}>
            <Grid item xs={9}>
              <Grid container spacing={1} direction="column">
                <Grid item xs={12} mb={0} pb={0}>
                  <Typography style={pmDetailsHeadingText}> Show Product Description</Typography>
                </Grid>
                <Grid item xs={12} mt={0} pt={0}>
                  <Typography style={{ fontSize: "11.4px", color: "#8C8C8C" }}>Show details about the product being offered</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <div style={{ textAlign: "right" }}>
                <OrangeSwitch
                  checked={superpamojaState?.showProductDescription ?? false}
                  onClick={(e) => {
                    dispatch({ type: "showProductDescription", payload: e.target.checked });
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid style={{ ...pamojaStyles.pamojaContainer, flexDirection: "column", border: "none" }}>
            <p style={pmDetailsHeadingText}>Price Definition</p>
            <p style={pamojaDetailsHeading2}>Set yout Pamoja price for this product</p>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField aria-label="Soko Price" label="Soko Price" margin="normal" value={superpamojaState?.defaultQuantity === 0 ? superpamojaState.sokoPrice : superpamojaState.sokoPrice * superpamojaState?.defaultQuantity} variant="outlined" disabled />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  aria-label="Pamoja Price"
                  onChange={(e) => {
                    dispatch({ type: "pamojaPrice", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Pamoja Price"
                  margin="normal"
                  value={superpamojaState.pamojaPrice}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  aria-label="Savings"
                  onChange={(e) => {
                    dispatch({ type: "productDesc", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Savings"
                  margin="normal"
                  value={superpamojaState?.defaultQuantity === 0 ? superpamojaState.sokoPrice - superpamojaState.pamojaPrice : superpamojaState.sokoPrice * superpamojaState?.defaultQuantity - superpamojaState.pamojaPrice}
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>

          {/*Product and Delivery*/}
          <Grid style={{ ...pamojaStyles.pamojaContainer, flexDirection: "column", border: "none" }}>
            <p style={pmDetailsHeadingText}>Product & Delivery</p>
            <p style={pamojaDetailsHeading2}>State the rules that govern the launch of each Pamoja</p>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField disabled aria-label="Available Qty" label="Available Qty" margin="normal" value={superpamojaState.availableQty} variant="outlined" />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  aria-label="Allocated Qty"
                  onChange={(e) => {
                    dispatch({ type: "allocatedQty", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Allocated Qty"
                  margin="normal"
                  value={superpamojaState.allocatedQty}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  aria-label="Arrives in"
                  onChange={(e) => {
                    dispatch({ type: "arrivesIn", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Arrives in (days)"
                  margin="normal"
                  value={superpamojaState.arrivesIn}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  aria-label="Expires in"
                  onChange={(e) => {
                    dispatch({ type: "expiresIn", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Expires in (Hours)"
                  margin="normal"
                  value={superpamojaState.expiresIn}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          {/*User setting*/}
          <Grid style={{ ...pamojaStyles.pamojaContainer, flexDirection: "column", border: "none" }}>
            <p style={pmDetailsHeadingText}>User settings</p>
            <p style={pamojaDetailsHeading2}>Set the scope of users and their participation in Pamoja</p>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  aria-label="Users"
                  onChange={(e) => {
                    dispatch({ type: "usersPerPamoja", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Users"
                  margin="normal"
                  value={superpamojaState.usersPerPamoja}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  aria-label="Max Qty per user"
                  onChange={(e) => {
                    dispatch({ type: "maxQty", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Max Qty per user"
                  margin="normal"
                  value={superpamojaState.maxQty}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  aria-label="Intial User Count"
                  onChange={(e) => {
                    dispatch({ type: "initialUsersCount", payload: e.target.value === "" ? null : parseInt(e.target.value) });
                  }}
                  label="Intial User Count"
                  margin="normal"
                  value={superpamojaState?.initialUsersCount ?? 0}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ ...pamojaStyles.pamojaContainer, border: "none", gap: "8px", justifyContent: "flex-end" }}>
            <Button onClick={() => handleSupermojaCancel()} style={{ ...buttonStyle.simpleButton, backgroundColor: "#fff", color: "#232F3E", paddingBottom: "1em", paddingTop: "1em", border: "1px solid #232F3E" }}>
              Cancel
            </Button>
            <Button
              disabled={superpamojaState.isSuperpamojaEdit && superpamojaState.pamojaType === pamojaType.superMoja && superpamojaState.status !== allPamojaStatus.draft}
              onClick={() => handleSupermojaSaveAndPublish(allPamojaStatus.draft)}
              style={{ ...buttonStyle.simpleButton, backgroundColor: "#fff", color: superpamojaState.isSuperpamojaEdit && superpamojaState.pamojaType === pamojaType.superMoja && superpamojaState.status !== allPamojaStatus.draft ? "grey" : "#232F3E", paddingBottom: "1em", paddingTop: "1em", border: superpamojaState.isSuperpamojaEdit && superpamojaState.pamojaType === pamojaType.superMoja && superpamojaState.status !== allPamojaStatus.draft ? "1px solid #232F3E" : "1px solid #333" }}
            >
              Save
            </Button>
            <Button onClick={() => handleSupermojaSaveAndPublish(superpamojaState.savePamojaStatus === allPamojaStatus.active ? allPamojaStatus.active : allPamojaStatus.inActive)} style={{ ...buttonStyle.simpleButton, backgroundColor: "#EF7B22", color: "#fff", paddingBottom: "1em", paddingTop: "1em" }}>
              Publish
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SupermojaConfig;
