const buttonStyle ={
    filterButton:{
       borderRadius: "8px",
       backgroundColor: "#EF7B22",
       padding: "8px 5px",
       justifyContent: "center",
       alignItems: "center"
    },
    buttonContainer:{
        backgroundColor: "#F2F2F2",
        borderRadius: "8px",
        display: "flex",
        padding: "4px",
        justifyContent: "flex-end",
        alignItems: "center",
        alignSelf: "stretch"
        
    },
    simpleButton: {
        display: "flex",
        padding: "8px 16px",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "8px",
        
    }
   
}

export default buttonStyle