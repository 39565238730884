import { filterStatus } from "src/constants/constants";

export const productInitialState = {
  // Switches
  addProduct: false,
  addVariant: false,
  newAlternateCategory: false,
  newSKU: false,
  newVType: false,
  newUnit: false,
  newBrand: false,
  edit: false,

  //Category field upload
  newCategory: false,
  newCategoryImgUrl: null,
  newCategoryName: null,

  alternateCategory: null,
  newAlternateCategoryName: null,
  newAlternateCategoryImgUrl: null,

  category: null,
  tagBasedCategories: [],
  categories: null,

  productVariantId: null,

  allocatedQty: 0,
  availableQty: null,

  rules: {
    orderQty: null,
    dailyLimit: {
      maximumQty: null
    }
  },

  newBrandName: null,
  newBrandImgUrl: null,
  brand: {
    brandId: null,
    name: null,
    imageUrl: null,
    tagBasedCategory: []
  },

  // Dropdowns
  productsDropdown: [],
  categoryDropdown: [],
  skuTypeDropdown: [],
  variantTypeDropdown: [],
  unitDropdown: [],
  vatDropdown: [],
  brandDropdown: [],

  selectedUnit: null,
  selectedBrand: null,
  selectedVariant: null,

  // Product reload
  productReload: false,
  productSearch: null,

  filterStatuses: [filterStatus.inActive, filterStatus.active],
  productCarouselUpload: false,

  //Product form
  productRecord: {
    productVariantId: null,
    productId: null,
    productName: null,
    description: null,
    productImageUrls: [],
    skuTypeId: null,
    skuTypeName: null,
    brandId: null,
    brandName: null,
    variantId: null,
    variantName: null,
    unitId: null,
    unitName: null,
    isDefault: false,
    status: "InActive",
    vatId: null,
    rules: {
      orderQty: null,
      dailyLimit: {
        maximumQty: null
      }
    },
    allocatedQuantity: null,
    availableQuantity: null,
    additionalDetails: null,
    isJipange: false,
    isTopSelling: false,
    mainCategories: [],
    tagBasedCategory: []
  }
};

export const productReducer = (state, action) => {
  switch (action.type) {
    case "addProduct":
      return { ...state, addProduct: action.payload };
    case "productRecord":
      return { ...state, productRecord: action.payload };
    case "addVariant":
      return { ...state, addVariant: action.payload };
    case "newCategory":
      return { ...state, newCategory: action.payload };
    case "newCategoryName":
      return { ...state, newCategoryName: action.payload };
    case "newCategoryImgUrl":
      return { ...state, newCategoryImgUrl: action.payload };
    case "newAlternateCategory":
      return { ...state, newAlternateCategory: action.payload };
    case "newAlternateCategoryName":
      return { ...state, newAlternateCategoryName: action.payload };
    case "newAlternateCategoryImgUrl":
      return { ...state, newAlternateCategoryImgUrl: action.payload };
    case "newSKU":
      return { ...state, newSKU: action.payload };
    case "newSKUName":
      return { ...state, newSKUName: action.payload };
    case "newVType":
      return { ...state, newVType: action.payload };
    case "newVTypeName":
      return { ...state, newVTypeName: action.payload };
    case "newUnit":
      return { ...state, newUnit: action.payload };
    case "newUnitName":
      return { ...state, newUnitName: action.payload };
    case "newBrand":
      return { ...state, newBrand: action.payload };
    case "newBrandName":
      return { ...state, newBrandName: action.payload };
    case "newBrandImgUrl":
      return { ...state, newBrandImgUrl: action.payload };
    case "productsDropdown":
      return { ...state, productsDropdown: action.payload };
    case "categoryDropdown":
      return { ...state, categoryDropdown: action.payload };
    case "skuTypeDropdown":
      return { ...state, skuTypeDropdown: action.payload };
    case "variantTypeDropdown":
      return { ...state, variantTypeDropdown: action.payload };
    case "vatDropdown":
      return { ...state, vatDropdown: action.payload };
    case "unitDropdown":
      return { ...state, unitDropdown: action.payload };
    case "brandDropdown":
      return { ...state, brandDropdown: action.payload };
    case "edit":
      return { ...state, edit: action.payload };
    case "productReload":
      return { ...state, productReload: action.payload };
    case "productCarouselUpload":
      return { ...state, productCarouselUpload: action.payload };
    case "productSearch":
      return { ...state, productSearch: action.payload };
    case "filterStatuses":
      return { ...state, filterStatuses: action.payload };
    case "productClear":
      return {
        ...state,
        productRecord: {
          productVariantId: null,
          productId: null,
          productName: null,
          description: null,
          productImageUrls: [],
          skuTypeId: null,
          skuTypeName: null,
          brandId: null,
          brandName: null,
          variantId: null,
          variantName: null,
          unitId: null,
          unitName: null,
          isDefault: false,
          status: "InActive",
          vatId: null,
          rules: {
            orderQty: null,
            dailyLimit: {
              maximumQty: null
            }
          },
          allocatedQuantity: null,
          availableQuantity: null,
          additionalDetails: null,
          isJipange: false,
          isTopSelling: false,
          mainCategories: [],
          tagBasedCategory: []
        }
      };
    case "clear":
      return {
        ...state,
        addProduct: false,
        addVariant: false,
        newSKU: false,
        newVType: false,
        newUnit: false,
        newBrand: false,
        edit: false,
        productId: null,
        product: null,
        productDesc: null,
        details: null,
        category: null,
        tagBasedCategories: [],
        categories: [],
        newSKUName: null,
        SKU: null,
        productVariantId: null,
        newVTypeName: null,
        vType: null,
        vatType: null,
        newUnitName: null,
        unit: null,
        allocatedQty: 0,
        availableQty: null,
        rules: {
          orderQty: null,
          dailyLimit: {
            maximumQty: null
          }
        },
        newBrandName: null,
        newBrandImgUrl: null,
        brand: {
          brandId: null,
          name: null,
          imageUrl: null,
          tagBasedCategory: []
        },
        variantImgUrls: [],
        status: null,
        isJipange: false,
        productReload: false,
        productSearch: null,
        productCarouselUpload: false,
        productRecord: {
          productVariantId: null,
          productId: null,
          productName: null,
          description: null,
          productImageUrls: [],
          skuTypeId: null,
          skuTypeName: null,
          brandId: null,
          brandName: null,
          variantId: null,
          variantName: null,
          unitId: null,
          unitName: null,
          isDefault: false,
          status: "InActive",
          vatId: null,
          rules: {
            orderQty: null,
            dailyLimit: {
              maximumQty: null
            }
          },
          allocatedQuantity: null,
          availableQuantity: null,
          additionalDetails: null,
          isJipange: false,
          isTopSelling: false,
          filterStatuses: [filterStatus.inActive, filterStatus.active],
          mainCategories: [],
          tagBasedCategory: []
        }
      };
    default:
      return state;
  }
};
