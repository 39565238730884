export const brandInitialState = {
  // Switches
  addBrand: false,
  updateBrand: false,
  brandCarouselUpload: false,
  showBrandLabels: [0],

  // Fields
  selectedRecord: {
    brandId: null,
    name: null,
    imageUrl: null,
    isDefault: false,
    isArchived: false,
    thumbnailName: null,
    description: null,
    tagLine: null,
    coverPhoto: null,
    webCoverPhoto: null,
    brandPhotos: [],
    showBrand: 0,
    sortOrder: 1,
    tagBasedCategory: [],
    noOfItems: 0,
    numberOfCategories: 0,
    numberOfPhotos: 0
  }
};

export const brandReducer = (state, action) => {
  switch (action.type) {
    case "addBrand":
      return { ...state, addBrand: action.payload };
    case "updateBrand":
      return { ...state, updateBrand: action.payload };
    case "brandCarouselUpload":
      return { ...state, brandCarouselUpload: action.payload };
    case "selectedRecord":
      return { ...state, selectedRecord: action.payload };
    case "showBrandLabels":
      return { ...state, showBrandLabels: action.payload };
    case "clear":
      return {
        ...state,
        addBrand: false,
        updateBrand: false,
        showBrandLabels: [0],
        selectedRecord: {
          brandId: null,
          name: null,
          imageUrl: null,
          isDefault: false,
          isArchived: false,
          thumbnailName: null,
          description: null,
          tagLine: null,
          coverPhoto: null,
          webCoverPhoto: null,
          brandPhotos: [],
          showBrand: 0,
          sortOrder: 1,
          tagBasedCategory: [],
          noOfItems: 0,
          numberOfCategories: 0,
          numberOfPhotos: 0
        }
      };
    default:
      return state;
  }
};
