export const fetchJipangeConfigurations = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/orderService/admin/jipangeConfig/fetch`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const updateJipangeConfigurations = async (body) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/api/orderService/admin/jipangeConfig/save`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
