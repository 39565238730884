export const generateAdminCategoryShareLink = async (body) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BASE_URL}/api/productService/category/share`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
