export const globalInitialState = {
  email: null,
  password: null,
  loading: false,
  reload: false,
  navigateBack: false
};

export const globalReducer = (state, action) => {
  switch (action.type) {
    case "email":
      return { ...state, email: action.payload };
    case "password":
      return { ...state, password: action.payload };
    case "loading":
      return { ...state, loading: action.payload };
    case "navigateBack":
      return { ...state, navigateBack: action.payload };
    case "logout":
      localStorage.removeItem("token");
      return { ...state, email: null, password: null, loading: false };
    case "reload":
      return { ...state, reload: action.payload };
    default:
      return state;
  }
};
