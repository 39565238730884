import { Switch, alpha } from "@mui/material";
import { orange } from '@mui/material/colors';
import styled from '@emotion/styled';
import { CATEGORY_STATUS, SWITCH_TYPE } from "src/constants/constants";
import { createOrUpdateCategory, createOrUpdateSubCategory } from "src/api/products";
import { GlobalContext } from "src/context/GlobalState";
import { useContext } from "react";

export default function OrangeSwitch({checked, switchType,  bodyObj}) {

  const {dispatch,state:{ globalState}} = useContext(GlobalContext)

  const handleUpdate = async(e) =>{
    try{
      dispatch({type: "loading", payload: true})
      let {sortOrder, rules, subCategories, ...newBodyObj} = bodyObj
      newBodyObj.status = e.target.checked ? CATEGORY_STATUS.active : CATEGORY_STATUS.inActive
      if (switchType === SWITCH_TYPE.categorySwitch) delete newBodyObj.parentId
      let response
      if (switchType === SWITCH_TYPE.categorySwitch){
        response= await createOrUpdateCategory(newBodyObj)
      }else{
        response= await createOrUpdateSubCategory(newBodyObj)
      }
      dispatch({type: "loading", payload: false})
      if (response.httpStatusCode === 200) {
          alert(response?.message)
          dispatch({type: "categoryClear"})
          dispatch({type: "reload", payload: !globalState.reload})
      }else{
          alert(response?.message ?? 'Unable update category')
      }
      
    }catch(error){
      dispatch({type: "loading", payload: false})
      alert(error?.message)
    }
  }

    const OrangeSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: orange[600],
          '&:hover': {
            backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: orange[600],
        },
      }));
    
    return (
        <OrangeSwitch disabled={globalState.loading} checked={SWITCH_TYPE.categorySwitch ? checked : checked === CATEGORY_STATUS.active ? true : false} onClick={(e)=> handleUpdate(e) } />
    )
}