import { useContext, useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
// eslint-disable-next-line
import { GlobalContext } from "src/context/GlobalState";

const UnitField = ({ routePath }) => {
  const {
    dispatch,
    state: { productState }
  } = useContext(GlobalContext);
  const [selectedUnit, setSelectedUnit] = useState(productState.unitDropdown.find((unit) => unit.unitId === productState?.productRecord?.unitId) || null);
  useEffect(() => {
    setSelectedUnit(productState.unitDropdown.find((unit) => unit.unitId === productState?.productRecord?.unitId) || null);
  }, [productState]);

  const createNewBox = {
    boxSizing: "border-box",
    marginTop: "10px",
    width: "100%",
    background: "#F2F2F2",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "#BFBFBF",
    borderRadius: "8px 8px 8px 8px",
    padding: "1em",
    marginBottom: "1em"
  };

  const addVariantButton = {
    display: "flex",
    flexDirection: "row",
    color: "#404040",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
    height: "40px",
    borderRadius: "8px",
    borderColor: "black",
    flex: "none",
    order: "1",
    flexGrow: "0"
  };

  const handleChange = (e, val) => {
    if (val?.unitId === "POST") {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, unitId: null } });
      dispatch({ type: "newUnit", payload: true });
    } else {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, unitId: val?.unitId } });
    }
  };

  const handleClose = () => {
    dispatch({ type: "newUnit", payload: false });
    dispatch({ type: "newUnitName", payload: null });
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, unitId: null, unitName: null } });
  };

  return (
    <>
      {productState.newUnit ? (
        <div style={createNewBox}>
          <p>Create New Unit</p>
          <FormControl fullWidth>
            <TextField
              fullWidth
              label="Unit Name"
              variant="standard"
              margin="normal"
              onChange={(e) => {
                dispatch({ type: "productRecord", payload: { ...productState?.productRecord, unitName: e.target.value } });
              }}
              value={productState?.productRecord?.unitName ?? null}
            />
          </FormControl>
          <div
            style={{
              height: "42px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Button onClick={(e) => handleClose()} variant="outlined" style={addVariantButton}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Grid sx={{ mb: 2, border: "1px solid #D2D2D2", borderRadius: "8px", p: 1 }}>
          <FormControl fullWidth margin="dense">
            <Autocomplete
              disablePortal
              defaultValue={productState.unitDropdown.find((unit) => unit.unitId === productState?.productRecord?.unitId)}
              value={selectedUnit}
              disabled={productState?.productRecord?.skuTypeId === 1000}
              id="category-label"
              name="UnitType"
              options={productState.unitDropdown}
              getOptionLabel={(option) => option.label}
              onChange={(e, val) => handleChange(e, val)}
              sx={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} variant="standard" label={productState?.productRecord?.skuTypeId !== 1000 ? "Unit" : null} />}
            />
          </FormControl>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={routePath === "add" ? true : productState?.productRecord?.isDefault} onChange={() => dispatch({ type: "productRecord", payload: { ...productState?.productRecord, isDefault: routePath === "add" ? true : !productState?.productRecord?.isDefault } })} />} label="Set as default" />
          </FormGroup>
        </Grid>
      )}
    </>
  );
};

export default UnitField;
