import { useEffect } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import OrdersPage from "./pages/order/OrdersPage";
import LoginPage from "./pages/login/LoginPage";
import Page404 from "./pages/404/Page404";
import ProductsPage from "./pages/products/ProductsPage";
import CRMPage from "./pages/crm/CRMPage";
import { verifyTokenExpiry } from "./utils/verifyToken";
import PriceMatrixPage from "./pages/price_matrix/priceMatrix";
import PamojaPage from "./pages/pamoja/PamojaPage";
import BrandPage from "./pages/brand/BrandPage";
import JipangeConfigurationPage from "./pages/jipange/JipangeConfigurationPage";
import PromoPage from "./pages/promo/PromoPage";
import PromoDetails from "./pages/promo/PromoDetails";
import OrderDetails from "./pages/order/OrderDetails";
import CRMDetails from "./pages/crm/CRMDetails";
import ProductsDetails from "./pages/products/ProductDetails";
import PriceDetails from "./pages/price_matrix/priceDetails";
import BrandDetails from "./pages/brand/BrandDetails";
import CategoryPage from "./pages/category/CategoryPage";
import AuditPage from "./pages/audit/AuditPage";

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token === null || token === undefined) {
      navigate("/login", { replace: true });
    } else {
      verifyTokenExpiry(token, navigate);
    }
  }, [token, navigate]);
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: "products", element: <ProductsPage /> },
        { path: "products/details/:productVariantId", element: <ProductsDetails /> },
        { path: "categories", element: <CategoryPage /> },
        { path: "brands", element: <BrandPage /> },
        { path: "brands/details/:brandId", element: <BrandDetails /> },
        { path: "orders", element: <OrdersPage /> },
        { path: "orders/details/:orderId", element: <OrderDetails /> },
        { path: "crm", element: <CRMPage /> },
        { path: "crm/details/:customerId", element: <CRMDetails /> },
        { path: "priceMatrix", element: <PriceMatrixPage /> },
        { path: "priceMatrix/details/:productVariantId", element: <PriceDetails /> },
        { path: "promos", element: <PromoPage /> },
        { path: "promos/details/:promoId", element: <PromoDetails /> }
      ]
    },
    {
      path: "/dashboard/okoa",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: "pamoja", element: <PamojaPage /> },
        { path: "jipange", element: <JipangeConfigurationPage /> }
      ]
    },
    {
      path: "/dashboard/audit",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/settings" />, index: true },
        { path: "logs", element: <AuditPage /> }
      ]
    },
    {
      path: "login",
      element: <LoginPage />
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />
    }
  ]);

  return routes;
}
