import PropTypes from "prop-types";
import TableSortLabel from "@mui/material/TableSortLabel";

import { StyledTableCell, tableHeadText } from "../styles/styles";

export function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    console.log("prop", property);
    onRequestSort(event, property);
  };

  return (
    <>
      {headCells.map((headCell) => (
        <StyledTableCell key={headCell.id} align="left" padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
          <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
            <p style={tableHeadText}>{headCell.label}</p>
          </TableSortLabel>
        </StyledTableCell>
      ))}
      {/* <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell2>{""}</StyledTableCell2> */}
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired
};
