import { urlType } from "src/constants/constants";
import { brandImageContainer } from "src/styles/styles";
import { extractYoutubeVideoId, isValidYoutubeLink, urlIsVideoOrImage } from "src/utils/utils";

export const RenderMedia = ({ url, type }) => {
  return (
    <>
      {urlIsVideoOrImage(url) === urlType.image ? (
        <img src={url} style={{ width: "100%", height: "100%" }} alt="Product" />
      ) : isValidYoutubeLink(url) ? (
        <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${extractYoutubeVideoId(url)}?rel=0&modestbranding=1&controls=1&showinfo=0&disablekb=1`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="YouTube Video"></iframe>
      ) : urlIsVideoOrImage(url) === urlType.video ? (
        <video src={url} controls style={{ width: "100%", height: "100%" }} />
      ) : (
        <img src={`/assets/${type === "brand" ? "NoImage.png" : "add_image.svg"}`} style={type === "brand" ? brandImageContainer : { width: "100%", height: "100%", padding: "1em 1em" }} alt="Product" />
      )}
    </>
  );
};
