import { colors } from "src/constants/constants"

const promoStyle ={
    promoSummaryContainer:{
        width:'80%',
        height: '406px' , 
        backgroundColor:'#F2F2F2', 
        margin:"2em auto",
        borderRadius: '8px',
        padding : '24px',
        gap: '16px'
    },
    promoSummarySubContainer: {
        marginBottom : '1em',
    },
   promoTitle:{
        fontSize: '14px',
        fontFamily :'Lato',
        color: colors.neutral
   },
   promoSubTitle:{
        fontSize: '16px',
        fontWeight : 700,
        fontFamily :'Lato',
        color: colors.secondaryNeutral
   },
   promoSubTitle2:{
    fontSize: '14px',
    fontFamily :'Lato',
    color: colors.secondaryNeutral
}
}

export default promoStyle