import { CATEGORY_STATUS } from "src/constants/constants";

export const categoryInitialState = {
  showCategoryDialog: false,
  draggingItem: null,
  categoryRecord: {
    categoryId: null,
    name: null,
    imageUrl: null,
    status: CATEGORY_STATUS.inActive,
    parentId: null
  },
  categoryType: null,
  selectedParentRecord: {
    name: null,
    imageUrl: null
  }
};

export const categoryReducer = (state, action) => {
  switch (action.type) {
    case "showCategoryDialog":
      return { ...state, showCategoryDialog: action.payload };
    case "categoryRecord":
      return { ...state, categoryRecord: action.payload };
    case "categoryType":
      return { ...state, categoryType: action.payload };
    case "selectedParentRecord":
      return { ...state, selectedParentRecord: action.payload };
    case "categoryClear":
      return {
        ...state,
        showCategoryDialog: false,
        draggingItem: null,
        categoryRecord: {
          categoryId: null,
          name: null,
          imageUrl: null,
          status: CATEGORY_STATUS.inActive,
          parentId: null
        },
        categoryType: null,
        selectedParentRecord: {
          name: null,
          imageUrl: null
        }
      };
    default:
      return state;
  }
};
