import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 75,
        height: 40,
        mb:1,
        // display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img src='https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/LogoWhite.jpeg?alt=media&token=96aa50a0-174b-41da-824c-d10a7e7cd81a' alt='Tushop' style={{ width: "100%" }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
