const fontStyle ={
    normalText:{
        color: "#404040",
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight:  "700",
        lineHeight: "normal"
    },
   
}

export default fontStyle