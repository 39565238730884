import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Box, Drawer } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import { navConfig, chanelAdmnistrationNav, configAdmnistrationNav } from "./config";

// ----------------------------------------------------------------------

const NAV_WIDTH = 224;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const admin = {
    width: "100%",
    height: "12px",
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#404040",
    flex: "none",
    order: "0",
    flexGrow: "0"
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" }
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 4,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          padding: "16px",
          width: "224px",
          height: "156px",
          background: "#FFFFFF",
          flex: "none",
          order: "0",
          flexGrow: "0"
        }}
      >
        <Logo />
      </Box>

      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px 16px",
          gap: "8px",
          width: "224px",
          height: "44px",
          background: "#FFFFFF",
          borderTop: "1px solid #D9D9D9",
          flex: "none",
          order: "0",
          flexGrow: "0"
        }}
      >
        <p style={admin}>ADMINISTRATOR</p>
      </Box>
      <NavSection data={navConfig} />
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px 16px",
          gap: "8px",
          width: "224px",
          height: "44px",
          background: "#FFFFFF",
          borderTop: "1px solid #D9D9D9",
          flex: "none",
          order: "0",
          flexGrow: "0"
        }}
      >
        <p style={admin}>CHANNEL ADMINISTRATION</p>
      </Box>
      <NavSection data={chanelAdmnistrationNav} />
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          backgroundColor: "pink",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px 16px",
          gap: "8px",
          width: "224px",
          height: "44px",
          background: "#FFFFFF",
          borderTop: "1px solid #D9D9D9",
          flex: "none",
          order: "0",
          flexGrow: "0"
        }}
      >
        <p style={admin}>SETTINGS</p>
      </Box>
      <NavSection data={configAdmnistrationNav} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH }
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed"
            }
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
