import { useCallback, useContext, useEffect, useState } from "react";
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// eslint-disable-next-line

import { addProductButton, addVariantButton, orderDetailsText } from "src/styles/styles";
import { searchUserData } from "src/api/orders";
import { GlobalContext } from "src/context/GlobalState";

//MUI - date component
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { dateToUTC } from "src/utils/formatTime";
import { orderingPlatform } from "src/constants/constants";

const OrderFilter = () => {
  const {
    dispatch,
    state: { orderState }
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const payStatuses = [
    { label: "SUCCESS", value: 1 },
    { label: "FAILURE", value: 2 },
    { label: "CANCELLED", value: 3 },
    { label: "PENDING", value: 4 }
  ];

  const orderStatuses = [
    { label: "ORDER PENDING", value: 1 },
    { label: "ORDER PLACED", value: 2 },
    { label: "CANCELLED", value: 3 },
    { label: "IN TRANSIT", value: 4 },
    { label: "READY FOR PICKUP", value: 5 },
    { label: "DELIVERED", value: 6 }
  ];

  const deliveryMethods = [
    { label: "PICKUP FROM CL", value: 1 },
    { label: "DOOR STEP DELIVERY", value: 2 },
    { label: "DIGITAL DELIVERY", value: 3 }
  ];

  const purchaseTypes = [
    { label: "Soko", value: 1 },
    { label: "Pamoja", value: 3 },
    { label: "Jipange", value: 4 },
    { label: "Supermoja", value: 5 }
  ];

  const platform = [
    { label: "App", value: orderingPlatform.app },
    { label: "Web", value: orderingPlatform.web }
  ];
  const handleClose = () => dispatch({ type: "orderFilter", payload: false });

  const handleClearFilter = () => {
    setOptions([]);
    setValue("");
    setInputValue("");
    setState([
      {
        startDate: null,
        endDate: null,
        key: "selection"
      }
    ]);
    dispatch({ type: "clear" });
  };

  const handleInputChange = async (val) => {
    setInputValue(val);
    if (val.length === 0) {
      setOptions([]);
      dispatch({ type: "customerId", payload: [] });
      dispatch({ type: "leaderId", payload: [] });
    } else if (val.length < 3 && val.length > 0) {
      setOptions([]);
    } else if (val.length >= 3) {
      const arr = [];
      const response = await searchUserData(val);

      if (response.httpStatusCode === 200) {
        response.result.data.forEach((data) => {
          if (data.isLeader) {
            arr.push({ label: `Leader: ${data.firstName} ${data.lastName}`, id: data.userId, type: "CL" });
          } else {
            arr.push({ label: `Customer: ${data.firstName} ${data.lastName}`, id: data.userId, type: "CS" });
          }
        });

        setOptions(arr);
      } else {
        alert(response.message);
      }
    }
  };

  const handleSelect = (data) => {
    setValue(data);
    if (data.type === "CS") {
      orderState.customerId.push(data.id);
      dispatch({ type: "leaderId", payload: [] });
    } else {
      orderState.leaderId.push(data.id);
      dispatch({ type: "customerId", payload: [] });
    }
  };

  const handleChangeDateRange = (range) => {
    setState([range]);
    // 2023-02-10 00:00:00
    const date = new Date(range.startDate);
    const date2 = new Date(range.endDate);

    console.log("getMonth()", date.getMonth());
    const startDate = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + date.getDate().toString().padStart(2, "0") + "T00:00:00";

    const endDate = date2.getFullYear() + "-" + (date2.getMonth() + 1 > 9 ? date2.getMonth() + 1 : "0" + (date2.getMonth() + 1)) + "-" + date2.getDate().toString().padStart(2, "0") + "T23:59:59";

    dispatch({ type: "fromDate", payload: startDate });
    dispatch({ type: "toDate", payload: endDate });
    dispatch({ type: "fromDateLocal", payload: startDate });
    dispatch({ type: "toDateLocal", payload: endDate });
  };

  const handleChangePaymentStatus = (e) => {
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      orderState.paymentStatuses.push(val);
    } else {
      const index = orderState.paymentStatuses.indexOf(val);
      orderState.paymentStatuses.splice(index, 1);
    }
    dispatch({ type: "orderFilter", payload: true });
  };

  const handleChangeOrderStatus = (e) => {
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      orderState.orderStatuses.push(val);
    } else {
      const index = orderState.orderStatuses.indexOf(val);
      orderState.orderStatuses.splice(index, 1);
    }
    dispatch({ type: "orderFilter", payload: true });
  };

  const handleChangeDeliveryMethods = (e) => {
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      orderState.deliveryMethods.push(val);
    } else {
      const index = orderState.deliveryMethods.indexOf(val);
      orderState.deliveryMethods.splice(index, 1);
    }
    dispatch({ type: "orderFilter", payload: true });
  };

  const handleChangePurchaseTypes = (e) => {
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      orderState.purchaseTypes.push(val);
    } else {
      const index = orderState.purchaseTypes.indexOf(val);
      orderState.purchaseTypes.splice(index, 1);
    }
    dispatch({ type: "orderFilter", payload: true });
  };

  const handleChangeOrderingPlatform = (e) => {
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      orderState.platform.push(val);
    } else {
      const index = orderState.platform.indexOf(val);
      orderState.platform.splice(index, 1);
    }
    dispatch({ type: "orderFilter", payload: true });
  };

  useEffect(() => {
    // console.log('Rec42', {local: orderState.fromDateLocal, dbDate :orderState.fromDate})
  }, [orderState.fromDateLocal, orderState.toDateLocal]);

  //Handle order date filter from
  const handleFromDateFilterChange = useCallback((date) => {
    if (date) {
      const filteredDate = date.format("YYYY-MM-DDTHH:mm");
      dispatch({ type: "fromDate", payload: filteredDate });
      dispatch({ type: "fromDateLocal", payload: filteredDate });
    }
  }, []);

  //Handle order date filter to
  const handleToDateFilterChange = useCallback((date) => {
    if (date) {
      const filteredDate = date.format("YYYY-MM-DDTHH:mm");
      dispatch({ type: "toDate", payload: filteredDate });
      dispatch({ type: "toDateLocal", payload: filteredDate });
    }
  }, []);

  return (
    <Dialog open={orderState.orderFilter} onClose={() => handleClose()} maxWidth="md" fullWidth>
      <DialogContent>
        <Grid container spacing={2} gap={2}>
          <Grid item xs={4}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                handleSelect(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                handleInputChange(newInputValue);
              }}
              options={options}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Search by customer/cl name" />}
            />
            <br />
            <Grid>
              <p style={orderDetailsText}>
                <b>Date Filter</b>
              </p>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker inputFormat="DD/MM/YYYY HH:mm" format="DD/MM/YYYY HH:mm" label="From Date" variant="inline" ampm={false} timeSteps={{ hours: 1, minutes: 1 }} onChange={handleFromDateFilterChange} renderInput={(params) => <TextField {...params} value={dayjs(orderState.fromDateLocal).isValid() ? dayjs(orderState.fromDateLocal).format("DD/MM/YYYY HH:mm") : ""} />} />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker inputFormat="DD/MM/YYYY HH:mm" format="DD/MM/YYYY HH:mm" label="To Date" variant="inline" ampm={false} timeSteps={{ hours: 1, minutes: 1 }} onChange={handleToDateFilterChange} renderInput={(params) => <TextField {...params} value={dayjs(orderState.toDateLocal).isValid() ? dayjs(orderState.toDateLocal).format("DD/MM/YYYY HH:mm") : ""} />} />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={6} sx={{ flex: "row" }}>
            <Grid item xs={6}>
              <p style={orderDetailsText}>
                <b>Payment Status</b>
              </p>
              <FormGroup>
                {payStatuses.map((data) => (
                  <FormControlLabel sx={{ height: "25px" }} control={<Checkbox onChange={(e) => handleChangePaymentStatus(e)} value={data.value} size="small" checked={orderState.paymentStatuses.indexOf(data.value) === -1 ? false : true} />} label={data.label} />
                ))}
              </FormGroup>
              <p style={orderDetailsText}>
                <b>Order Status</b>
              </p>
              <FormGroup>
                {orderStatuses.map((data) => (
                  <FormControlLabel sx={{ height: "30px" }} control={<Checkbox onChange={(e) => handleChangeOrderStatus(e)} value={data.value} size="small" checked={orderState.orderStatuses.indexOf(data.value) === -1 ? false : true} />} label={data.label} />
                ))}
              </FormGroup>

              <p style={orderDetailsText}>
                <b>Delivery Method</b>
              </p>
              <FormGroup>
                {deliveryMethods.map((data) => (
                  <FormControlLabel sx={{ height: "30px" }} control={<Checkbox onChange={(e) => handleChangeDeliveryMethods(e)} value={data.value} size="small" checked={orderState.deliveryMethods.indexOf(data.value) === -1 ? false : true} />} label={data.label} />
                ))}
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <p style={orderDetailsText}>
                <b>Purchase Type</b>
              </p>
              <FormGroup>
                {purchaseTypes.map((data) => (
                  <FormControlLabel sx={{ height: "30px" }} control={<Checkbox onChange={(e) => handleChangePurchaseTypes(e)} value={data.value} size="small" checked={orderState.purchaseTypes.indexOf(data.value) === -1 ? false : true} />} label={data.label} />
                ))}
              </FormGroup>

              <p style={orderDetailsText}>
                <b>Ordering platform</b>
              </p>
              <FormGroup>
                {platform.map((data) => (
                  <FormControlLabel sx={{ height: "30px" }} control={<Checkbox onChange={(e) => handleChangeOrderingPlatform(e)} value={data.value} size="small" checked={orderState.platform.indexOf(data.value) === -1 ? false : true} />} label={data.label} />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => handleClearFilter()} variant="outlined" style={addVariantButton}>
          Clear filters
        </Button>

        <Button
          style={addProductButton}
          onClick={() => {
            handleClose();
          }}
        >

          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default OrderFilter;
