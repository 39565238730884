import { Button, CircularProgress, Grid, InputAdornment, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Search } from "@mui/icons-material";
import headerStyle from "src/styles/headerStyle";
import promoStyle from "src/styles/promoStyle";
import pamojaStyles from "src/styles/pamojaStyle";
import { searchProductDetails } from "src/api/products";
import { GlobalContext } from "src/context/GlobalState";

export default function PromoProduct() {
  const [searchText, setSearchText] = useState(null);
  const [searchProductArray, setSearchProductArray] = useState([]);
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageSizeLimitScroll, setPageSizeLimitScroll] = useState(null);
  const [pageNumberSearch, setPageNumberSearch] = useState(0);
  const [refreshPageNumber, setRefreshPageNumber] = useState(null);
  const [totalProductSearchCount, setTotalProductSearchCount] = useState(0);

  const {
    dispatch,
    state: { promoState }
  } = useContext(GlobalContext);

  //Handle product search
  const handleProductSearch = async (e, finalPageNumberValue, searchVal) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (refreshPageNumber) finalPageNumberValue = 0;
      if (pageSizeLimitScroll !== 0 && finalPageNumberValue > pageSizeLimitScroll) return;
      setProductSelected(false);
      const arr = [];
      const arr2 = [];
      setSearchLoading(true);
      const response = await searchProductDetails(pageSize, finalPageNumberValue, searchText ?? searchVal);
      if (response.httpStatusCode === 200) {
        const products = response.result?.data?.products || [];
        if (products.length === 0) {
          setNotFound(true);
          setSearchLoading(false);
          return;
        }
        products.forEach((res) => {
          let obj = {
            productVariantId: res?.productVariantId,
            brandName: res?.brand?.name,
            productName: res?.product?.name,
            unitName: res?.unit?.name,
            productImage: res?.productVariantImage?.[0].imageUrl,
            variation: res?.variantType?.name,
            sokoPrice: res?.productVariantPrice?.sokoPrice
          };
          arr.push(obj);
          if (finalPageNumberValue === 0) arr2.push(obj);
        });
        const filteredData = arr.filter((newProduct) => !searchProductArray.some((product) => product.productVariantId === newProduct.productVariantId));
        if (finalPageNumberValue === 0) {
          setSearchProductArray(arr2);
        } else {
          setSearchProductArray((prevProducts) => [...prevProducts, ...filteredData]);
        }
        setSearchLoading(false);
        setTotalProductSearchCount(response.result?.data?.totalProductsCount);
        setPageSizeLimitScroll(Math.ceil(response.result?.data?.totalProductsCount / pageSize));
        setShowProductDropdown(true);
      }
    } catch (error) {
      setSearchLoading(false);
      setProductSelected(false);
      setSearchText(null);
      alert(error?.message);
    }
  };

  //Hanlde product container close
  const handleClose = () => {
    try {
      dispatch({ type: "promoProductClear" });
      dispatch({ type: "promoRecord", payload: { ...promoState.promoRecord, productVariantId: null } });
      setSearchText(null);
      setSearchLoading(false);
      setSearchProductArray([]);
      setPageNumberSearch(0);
    } catch (error) {
      alert(error?.message);
    }
  };

  const handleProductSelect = (e, product) => {
    try {
      setProductSelected(true);
      e.preventDefault();
      e.stopPropagation();
      if (product.productVariantId === promoState.promoProduct.productVariantId) {
        setSearchText(null);
        setSearchProductArray([]);
        setShowProductDropdown(false);
        setPageNumberSearch(0);
        return;
      }
      dispatch({ type: "promoProduct", payload: { ...promoState.promoProduct, brandName: product.brandName, productVariantId: product.productVariantId, imgUrl: product.productImage, variation: product.variation, sokoPrice: product.sokoPrice, productName: product.productName } });
      dispatch({ type: "promoRecord", payload: { ...promoState.promoRecord, productVariantId: product.productVariantId } });

      setSearchText(null);
      setSearchProductArray([]);
      setShowProductDropdown(false);
      setPageNumberSearch(0);

      setTimeout(() => {
        setProductSelected(false);
      }, 300);
    } catch (error) {
      setSearchText(null);
      setSearchProductArray([]);
      alert(error?.message);
    }
  };

  return (
    <>
      <Grid sx={{ width: "100%" }}>
        {/* Search container */}
        <Grid>
          <Typography sx={{ my: 1 }} style={{ fontSize: "16px", fontWeight: "600" }}>
            Select Product
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            onChange={debounce((e) => {
              const searchValue = e.target.value;
              if (searchValue === "" || searchValue.length < 3) {
                setSearchText(null);
                setPageNumberSearch(0);
                setRefreshPageNumber(0);
                setPageSizeLimitScroll(0);
                setSearchProductArray([]);
                setShowProductDropdown(false);
              } else {
                setTotalProductSearchCount(0);
                setSearchText(searchValue);
                handleProductSearch(e, 0, searchValue);
              }
            }, 300)}
            onKeyDown={debounce((e) => {
              if (e.keyCode === 13 && searchText) {
                setTotalProductSearchCount(0);
                handleProductSearch(e, 0, searchText);
              }
            }, 300)}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                  <Search />
                </InputAdornment>
              ),
              style: { ...headerStyle.searchTextField, width: "100%" },
              inputProps: {
                style: headerStyle.placeHolderText
              }
            }}
          />

          {/* When no product is found */}
          {notFound && searchProductArray.length === 0 && totalProductSearchCount === 0 ? (
            <Grid sx={{ width: "100%", position: "relative" }} style={{ display: "flex" }}>
              <Table
                size="small"
                style={{
                  ...pamojaStyles.searchProductListing,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  borderRadius: "12px",
                  width: "100%",
                  tableLayout: "fixed"
                }}
              >
                <TableRow style={{ cursor: "pointer" }}>
                  <TableCell style={pamojaStyles.cellBorder}>Product not found</TableCell>
                </TableRow>
              </Table>
            </Grid>
          ) : null}

          {/* loading start */}
          <Grid sx={{ width: "100%", position: "relative", overflow: "auto", height: searchProductArray.length === 0 ? "auto" : totalProductSearchCount <= 5 ? "auto" : 170 }} style={{ display: "flex" }}>
            <Table
              size="small"
              style={{
                position: "relative",
                top: 0,
                left: 0,
                zIndex: 1,
                borderRadius: "8px",
                width: "100%",
                tableLayout: "fixed"
              }}
            >
              {showProductDropdown && searchProductArray && searchProductArray.length > 0 && !productSelected ? (
                searchProductArray.map((product, index) => (
                  <React.Fragment key={product.productVariantId}>
                    <TableRow style={{ cursor: "pointer" }} onClick={(e) => handleProductSelect(e, product)}>
                      <TableCell style={{ ...pamojaStyles.cellBorder, border: "1px solid #333" }}>
                        {product.productName} {product.variation} {product.brandName} - {product.unitName}
                      </TableCell>
                    </TableRow>
                    {index === searchProductArray.length - 1 && searchLoading ? (
                      <TableRow>
                        <TableCell style={{ ...pamojaStyles.cellBorder, display: "flex", justifyContent: "center", border: "1px solid #333", borderRadius: 0 }}>
                          <CircularProgress size={18} variant="indeterminate" sx={{ color: "#EF7B22", "& svg": { padding: "3px" } }} />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                ))
              ) : searchLoading ? (
                <TableRow>
                  <TableCell style={{ ...pamojaStyles.cellBorder, display: "flex", justifyContent: "center", border: "1px solid #333", borderRadius: 0 }}>
                    <CircularProgress size={18} variant="indeterminate" sx={{ color: "#EF7B22", "& svg": { padding: "3px" } }} />
                  </TableCell>
                </TableRow>
              ) : null}
            </Table>
          </Grid>

          {/* Show dropdown items */}
          {showProductDropdown && totalProductSearchCount !== searchProductArray.length && searchProductArray.length !== 0 ? (
            <Grid sx={{ width: "100%", position: "relative", overflow: "auto" }} style={{ display: "flex" }}>
              <Button
                disableElevation={true}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  let pageNumberValue = pageNumberSearch === 0 ? 1 : pageNumberSearch + 1;
                  let finalPageNumberValue = pageNumberValue >= pageSizeLimitScroll ? pageSizeLimitScroll : pageNumberValue;
                  setPageNumberSearch(pageNumberValue >= pageSizeLimitScroll ? pageSizeLimitScroll : pageNumberValue);
                  handleProductSearch(e, finalPageNumberValue, searchText);
                }}
                sx={{
                  width: "100%",
                  my: 1,
                  backgroundColor: "#EF7B22",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#EF7B22",
                    boxShadow: "none"
                  }
                }}
                variant="contained"
              >
                {" "}
                {searchLoading ? <CircularProgress size={18} variant="indeterminate" sx={{ color: "#FFF", "& svg": { padding: "3px" } }} /> : "Load More"}
              </Button>
            </Grid>
          ) : null}
        </Grid>

        {/* Product view container */}
        {promoState.promoRecord.productVariantId ? (
          <Grid sx={{ display: "flex", justifyContent: "space-between", my: 1, alignItems: "center", p: "8px", width: "100%", border: "1px solid #D9D9D9" }}>
            <Grid item xs={5} sx={{ display: "flex", width: "100%" }}>
              <Grid>
                <img width="100px" height="100px" src={promoState?.promoProduct?.imgUrl ?? null} alt="product_image" style={{ borderRadius: "8px" }} />
              </Grid>
              <Grid style={{ alignItems: "center", marginLeft: "1em" }}>
                <Typography>{`${promoState?.promoProduct?.brandName ?? ""} ${promoState?.promoProduct?.productName ?? ""}`}</Typography>
                <Typography style={promoStyle.promoTitle}>{promoState?.promoProduct?.variation ?? null}</Typography>
                <Typography>Ksh {promoState?.promoProduct?.sokoPrice ?? null}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img onClick={() => handleClose()} alt="add" src="/assets/icons/cancel.svg" style={{ fontSize: "22px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
