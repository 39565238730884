export const fetchPromoData = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/promoCodes/fetch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchPromoDataByPromoCodeId = async (promoCodeId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/promoCode/${promoCodeId}/fetchDetails`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchPromoConfigDetails = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/promoCodeConfig/dropDowns/fetch`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const createOrUpdatePromo = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/promoCode/createOrUpdate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const deletePromoCode = async (promoCodeId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/promoCode/${promoCodeId}/delete`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchPromoDataExport = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/promoCodes/export`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
