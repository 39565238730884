import { useContext } from "react";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line
import { actions2, addProductButton, brandImageContainer, imageContainer } from "src/styles/styles";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import FirebaseApp from "src/firebase/firebase";
import { useDropzone } from "react-dropzone";
import { GlobalContext } from "src/context/GlobalState";

const BrandImageUpload = () => {
  const storage = getStorage(FirebaseApp);
  const {
    dispatch,
    state: { brandState }
  } = useContext(GlobalContext);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data) => {
        handleUploadImage(data);
      });
    }
  });

  const handleUploadImage = (image) => {
    const defaultFileSize = process.env.REACT_APP_FILESIZE_LIMIT;
    if (image?.size > defaultFileSize) {
      alert("Please upload product image less than 150kb?");
    } else {
      const Reference = ref(storage, `SKU/Brands/BrandImages/${Date.now()}-${image.name}`);
      uploadBytes(Reference, image).then((snapshot) => {
        console.log(snapshot);
        getDownloadURL(Reference).then((url) => {
          dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, imageUrl: url } });
        });
      });
    }
  };

  return (
    <div
      {...getRootProps()}
      style={{
        // padding: "20px",
        boxSizing: "border-box",
        marginTop: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        height: "450px",
        background: "#232F3E",
        borderWidth: "1px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#BFBFBF",
        borderRadius: "8px 8px 8px 8px"
      }}
    >
      <input {...getInputProps()} />

      <img src={brandState.selectedRecord.imageUrl ?? "/assets/NoImage.png"} style={{ ...brandImageContainer, height: "400px" }} alt="Product" />

      <p style={{ color: "white", textAlign: "center", marginTop: "1em", fontWeight: "700" }}>Change</p>
    </div>
  );
};

export default BrandImageUpload;
