import { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
// eslint-disable-next-line
import { addProductButton, addVariantButton } from "src/styles/styles";
import { GlobalContext } from "src/context/GlobalState";

const NewPMFields = () => {
  const {
    dispatch,
    state: { pmState }
  } = useContext(GlobalContext);

  const handleConfirm = () => {
    const marketPrices = pmState.priceMatrixRecord.marketPrices[pmState.marketPriceName];
    const existingFieldIndex = marketPrices.findIndex((price) => price.name === pmState.newFieldName);

    if (existingFieldIndex !== -1) {
      // Update existing field
      marketPrices[existingFieldIndex] = {
        ...marketPrices[existingFieldIndex],
        price: parseInt(pmState.newFieldValue)
      };
    } else {
      console.log("Olaa");
      marketPrices.push({
        marketPriceId: "null",
        name: pmState.newFieldName,
        marketPriceType: {
          marketPriceTypeId: pmState.marketPriceTypeId,
          name: pmState.marketPriceName
        },
        price: parseInt(pmState.newFieldValue),
        productVariantId: pmState.productVariantId
      });
      pmState.newFields.push({
        marketPriceId: null,
        marketPrice: parseInt(pmState.newFieldValue),
        marketPriceTypeId: pmState.marketPriceTypeId,
        marketPriceName: pmState.newFieldName
      });
    }

    handleClose();
  };

  const handleClose = () => {
    dispatch({ type: "marketPriceTypeId", payload: null });
    dispatch({ type: "marketPriceName", payload: null });
    dispatch({ type: "newFieldName", payload: null });
    dispatch({ type: "newFieldValue", payload: null });
    dispatch({ type: "addNewField", payload: false });
    // dispatch({ type: "pmDetails", payload: true })
  };

  return (
    <Dialog open={pmState.addNewField} onClose={handleClose}>
      <DialogContent>
        <p>Add new {pmState.marketPriceName} field</p>
        <TextField
          onChange={(e) => {
            dispatch({ type: "newFieldName", payload: e.target.value });
          }}
          fullWidth
          label="New field title"
          variant="outlined"
          value={pmState.newFieldName}
          margin="normal"
        />
        <TextField
          onChange={(e) => {
            dispatch({ type: "newFieldValue", payload: e.target.value });
          }}
          fullWidth
          label="Price"
          variant="outlined"
          value={pmState.newFieldValue}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <span style={{ flexGrow: 1 }} />
        <Button onClick={(e) => handleClose()} variant="outlined" style={addVariantButton}>
          Cancel
        </Button>
        <Button
          style={addProductButton}
          onClick={() => {
            handleConfirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewPMFields;
