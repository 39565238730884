import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { addProductButton } from "src/styles/styles";

export default function ShareDialog({ shareLink, openDialog, setShowShareDialog, setCopied, shareType }) {
  return (
    <Dialog maxWidth="md" fullWidth open={openDialog ?? false} onClose={() => setShowShareDialog(false)}>
      <Grid sx={{ backgroundColor: "#F2F2F2", display: "flex", justifyContent: "space-between", alignItems: "center", pr: 2 }}>
        <DialogTitle>{`Share ${shareType ?? "Brand"} Attribution Link`}</DialogTitle>
        <Close onClick={() => setShowShareDialog(false)} />
      </Grid>
      <DialogContent>
        <Grid container>
          <Grid item xs={10} alignItems="center" justifyContent="center">
            <TextField sx={{ marginTop: 0 }} fullWidth label="Share Link" aria-label="Brand" margin="normal" variant="standard" disabled value={shareLink ?? ""} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid>
            <CopyToClipboard
              text={shareLink ?? ""}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 5000);
              }}
            >
              <Button variant="outlined" style={addProductButton} onClick={() => setShowShareDialog(false)}>
                Copy Link
              </Button>
            </CopyToClipboard>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
