import { Button, CircularProgress, Grid, InputAdornment, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Search } from "@mui/icons-material";
import headerStyle from "src/styles/headerStyle";
import promoStyle from "src/styles/promoStyle";
import pamojaStyles from "src/styles/pamojaStyle";
import { GlobalContext } from "src/context/GlobalState";
import { searchBrandDetails } from "src/api/products";

export default function PromoBrand() {
  const [searchText, setSearchText] = useState(null);
  const [searchBrandArray, setSearchBrandArray] = useState([]);
  const [showBrandDropdown, setShowBrandDropdown] = useState(false);
  const [brandSelected, setBrandSelected] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageSizeLimitScroll, setPageSizeLimitScroll] = useState(null);
  const [pageNumberSearch, setPageNumberSearch] = useState(0);
  const [refreshPageNumber, setRefreshPageNumber] = useState(null);
  const [totalBrandSearchCount, setTotalBrandSearchCount] = useState(0);

  const {
    dispatch,
    state: { promoState }
  } = useContext(GlobalContext);

  //const handle brand search
  const handleBrandSearch = async (e, finalPageNumberValue, searchVal) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (refreshPageNumber) finalPageNumberValue = 0;
      if (pageSizeLimitScroll !== 0 && finalPageNumberValue > pageSizeLimitScroll) return;
      setBrandSelected(false);
      const arr = [];
      const arr2 = [];
      setSearchLoading(true);
      const response = await searchBrandDetails(pageSize, finalPageNumberValue, searchText ?? searchVal);
      if (response.httpStatusCode === 200) {
        const brands = response.result?.data?.brands || [];
        if (brands.length === 0) {
          setNotFound(true);
          setSearchLoading(false);
          return;
        }
        brands.forEach((res) => {
          let obj = {
            brandId: res?.brandId,
            brandName: res?.name,
            imgUrl: res?.imageUrl
          };
          arr.push(obj);
          if (finalPageNumberValue === 0) arr2.push(obj);
        });
        const filteredData = arr.filter((newBrand) => !searchBrandArray.some((brand) => brand.brandId === newBrand.brandId));
        if (finalPageNumberValue === 0) {
          setSearchBrandArray(arr2);
        } else {
          setSearchBrandArray((prevBrands) => [...prevBrands, ...filteredData]);
        }
        setSearchLoading(false);
        setTotalBrandSearchCount(response.result?.data?.totalCount);
        setPageSizeLimitScroll(Math.ceil(response.result?.data?.totalCount / pageSize));
        setShowBrandDropdown(true);
      }
    } catch (error) {
      setSearchLoading(false);
      setBrandSelected(false);
      setSearchText(null);
      alert(error?.message);
    }
  };

  //Hanlde brand container close
  const handleClose = () => {
    try {
      dispatch({ type: "promoBrandClear" });
      dispatch({ type: "promoRecord", payload: { ...promoState.promoRecord, brandId: null } });
      setSearchText(null);
      setSearchLoading(false);
      setSearchBrandArray([]);
      setPageNumberSearch(0);
    } catch (error) {
      alert(error?.message);
    }
  };

  const handleBrandSelect = (e, brand) => {
    try {
      setBrandSelected(true);
      e.preventDefault();
      e.stopPropagation();
      if (brand.brandId === promoState.brandProduct.brandId) {
        setSearchText(null);
        setSearchBrandArray([]);
        setShowBrandDropdown(false);
        setPageNumberSearch(0);
        return;
      }
      dispatch({ type: "brandProduct", payload: { ...promoState.brandProduct, brandName: brand.brandName, brandId: brand.brandId, imgUrl: brand.imgUrl } });
      dispatch({ type: "promoRecord", payload: { ...promoState.promoRecord, brandId: brand.brandId, productVariantId: null } });

      setSearchText(null);
      setSearchBrandArray([]);
      setShowBrandDropdown(false);
      setPageNumberSearch(0);

      setTimeout(() => {
        setBrandSelected(false);
      }, 300);
    } catch (error) {
      setSearchText(null);
      setSearchBrandArray([]);
      alert(error?.message);
    }
  };

  return (
    <>
      <Grid sx={{ width: "100%" }}>
        <Grid>
          <Typography sx={{ my: 1 }} style={{ fontSize: "16px", fontWeight: "600" }}>
            Select Brand
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            onChange={debounce((e) => {
              const searchValue = e.target.value;
              if (searchValue === "" || searchValue.length < 3) {
                setSearchText(null);
                setPageNumberSearch(0);
                setRefreshPageNumber(0);
                setPageSizeLimitScroll(0);
                setSearchBrandArray([]);
                setShowBrandDropdown(false);
              } else {
                setTotalBrandSearchCount(0);
                setSearchText(searchValue);
                handleBrandSearch(e, 0, searchValue);
              }
            }, 300)}
            onKeyDown={debounce((e) => {
              if (e.keyCode === 13 && searchText) {
                setTotalBrandSearchCount(0);
                handleBrandSearch(e, 0, searchText);
              }
            }, 300)}
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: "#D9D9D9" }}>
                  <Search />
                </InputAdornment>
              ),
              style: { ...headerStyle.searchTextField, width: "100%" },
              inputProps: {
                style: headerStyle.placeHolderText
              }
            }}
          />

          {/* When no brand is found */}
          {notFound && searchBrandArray.length === 0 && totalBrandSearchCount === 0 ? (
            <Grid sx={{ width: "100%", position: "relative" }} style={{ display: "flex" }}>
              <Table
                size="small"
                style={{
                  ...pamojaStyles.searchProductListing,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  borderRadius: "12px",
                  width: "100%",
                  tableLayout: "fixed"
                }}
              >
                <TableRow style={{ cursor: "pointer" }}>
                  <TableCell style={pamojaStyles.cellBorder}>Brand not found</TableCell>
                </TableRow>
              </Table>
            </Grid>
          ) : null}

          {/* loading start */}
          <Grid sx={{ width: "100%", position: "relative", overflow: "auto", height: searchBrandArray.length === 0 ? "auto" : totalBrandSearchCount <= 5 ? "auto" : 170 }} style={{ display: "flex" }}>
            <Table
              size="small"
              style={{
                position: "relative",
                top: 0,
                left: 0,
                zIndex: 1,
                borderRadius: "8px",
                width: "100%",
                tableLayout: "fixed"
              }}
            >
              {showBrandDropdown && searchBrandArray && searchBrandArray.length > 0 && !brandSelected ? (
                searchBrandArray.map((brand, index) => (
                  <React.Fragment key={brand.brandId}>
                    <TableRow style={{ cursor: "pointer" }} onClick={(e) => handleBrandSelect(e, brand)}>
                      <TableCell style={{ ...pamojaStyles.cellBorder, border: "1px solid #333" }}>{brand.brandName}</TableCell>
                    </TableRow>
                    {index === searchBrandArray.length - 1 && searchLoading ? (
                      <TableRow>
                        <TableCell style={{ ...pamojaStyles.cellBorder, display: "flex", justifyContent: "center", border: "1px solid #333", borderRadius: 0 }}>
                          <CircularProgress size={18} variant="indeterminate" sx={{ color: "#EF7B22", "& svg": { padding: "3px" } }} />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                ))
              ) : searchLoading ? (
                <TableRow>
                  <TableCell style={{ ...pamojaStyles.cellBorder, display: "flex", justifyContent: "center", border: "1px solid #333", borderRadius: 0 }}>
                    <CircularProgress size={18} variant="indeterminate" sx={{ color: "#EF7B22", "& svg": { padding: "3px" } }} />
                  </TableCell>
                </TableRow>
              ) : null}
            </Table>
          </Grid>

          {/* Show dropdown items */}
          {showBrandDropdown && totalBrandSearchCount !== searchBrandArray.length && searchBrandArray.length !== 0 ? (
            <Grid sx={{ width: "100%", position: "relative", overflow: "auto" }} style={{ display: "flex" }}>
              <Button
                disableElevation={true}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  let pageNumberValue = pageNumberSearch === 0 ? 1 : pageNumberSearch + 1;
                  let finalPageNumberValue = pageNumberValue >= pageSizeLimitScroll ? pageSizeLimitScroll : pageNumberValue;
                  setPageNumberSearch(pageNumberValue >= pageSizeLimitScroll ? pageSizeLimitScroll : pageNumberValue);
                  handleBrandSearch(e, finalPageNumberValue, searchText);
                }}
                sx={{
                  width: "100%",
                  my: 1,
                  backgroundColor: "#EF7B22",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#EF7B22",
                    boxShadow: "none"
                  }
                }}
                variant="contained"
              >
                {" "}
                {searchLoading ? <CircularProgress size={18} variant="indeterminate" sx={{ color: "#FFF", "& svg": { padding: "3px" } }} /> : "Load More"}
              </Button>
            </Grid>
          ) : null}
        </Grid>

        {/* Product view container */}
        {promoState.promoRecord.brandId ? (
          <Grid sx={{ display: "flex", justifyContent: "space-between", my: 1, alignItems: "center", p: "8px", width: "100%", borderRadius: "8px", border: "1px solid #D9D9D9" }}>
            <Grid item xs={5} sx={{ display: "flex", width: "100%", placeItems: "center", alignItems: "center" }}>
              <Grid>
                <img width="35px" height="35px" src={promoState?.brandProduct?.imgUrl ?? null} alt="brand_image" style={{ borderRadius: "8px" }} />
              </Grid>
              <Grid style={{ marginLeft: "1em" }}>
                <Typography style={{ alignItems: "center", placeItems: "center", justifyContent: "center" }}>{promoState?.brandProduct?.brandName ?? ""}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img onClick={() => handleClose()} alt="add" src="/assets/icons/cancel.svg" style={{ fontSize: "22px", cursor: "pointer" }} />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
