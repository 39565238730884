const tableStyle ={
    headerContainer:{
        
    },
    searchContainer:{
        display: "flex", 
        justifyContent: "flex-end",
        marginBottom: "1em"
    },
    placeHolderText:{
        fontSize: "14px",
        lineHeight: "20px",
    },
    searchTextField:{
        paddingTop: 0,
        width: '400px',
        paddingBottom: 0,
        borderRadius: "8px",
    },
    subHeaderContainer:{
        display: "flex",
        justifyContent: "flex-end",
        
    },
    supermojaEmptyTableContainer:{
        backgroundColor: "green",
        display: "flex",
        height:"auto",
        padding: "8px 60px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flexShrink: 0,
    },
    supermojaIconContainer:{
        display:"flex",
        backgroundColor: "#F2F2F2",
        borderRadius: "50%",
        width: "400px",
        height: "400px",
        flexDirection: "column",
        margin: "8em auto 8em",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
        flexShrink: 0
    },
    cellStyle:{
        padding: '2px 8px',
    }
    
}

export default tableStyle