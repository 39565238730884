const headerStyle ={
    headerContainer:{
        
    },
    searchContainer:{
        display: "flex", 
        justifyContent: "flex-end",
        marginBottom: "1em"
    },
    placeHolderText:{
        fontSize: "14px",
        lineHeight: "20px",
    },
    searchTextField:{
        paddingTop: 0,
        width: '400px',
        paddingBottom: 0,
        borderRadius: "8px",
    },
    subHeaderContainer:{
        display: "flex",
        justifyContent: "flex-end",
        
    },
    subHeaderArrowContainer:{
        justifyContent:"flex-start",
        paddingTop: "1em",
        paddingBottom: "1em",
        display: "flex",
        
    }
}

export default headerStyle