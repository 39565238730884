import { useContext, useState } from "react";
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// eslint-disable-next-line
import { addProductButton, addVariantButton, orderDetailsText } from "src/styles/styles";

import { dateToUTC } from "src/utils/formatTime";
import { GlobalContext } from "src/context/GlobalState";

const PamojaFilter = () => {
  const {
    dispatch,
    state: { pamojaState }
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const pamojaStatuses = [
    { label: "INACTIVE", value: 0 },
    { label: "ACTIVE", value: 1 },
    { label: "IN PROGRESS", value: 2 },
    { label: "SUCCESS", value: 3 }
  ];

  const handleClose = () => {
    dispatch({ type: "pamojaFilter", payload: false });
  };

  const handleClearFilter = () => {
    setOptions([]);
    setValue("");
    setInputValue("");
    setState([
      {
        startDate: null,
        endDate: null,
        key: "selection"
      }
    ]);
    dispatch({ type: "clear" });
  };

  const handleChangeDateRange = (range) => {
    setState([range]);

    // 2023-02-10 00:00:00
    const date = new Date(range.startDate);
    const date2 = new Date(range.endDate);

    const startDate = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0") + "T00:00:00";

    const endDate = date2.getFullYear() + "-" + (date2.getMonth() + 1).toString().padStart(2, "0") + "-" + date2.getDate().toString().padStart(2, "0") + "T23:59:59";

    dispatch({ type: "fromDate", payload: dateToUTC(startDate) });
    dispatch({ type: "toDate", payload: dateToUTC(endDate) });
  };

  const handleChangeOrderStatus = (e) => {
    const val = parseInt(e.target.value);
    if (e.target.checked) {
      pamojaState.pamojaStatuses.push(val);
    } else {
      const index = pamojaState.pamojaStatuses.indexOf(val);

      pamojaState.pamojaStatuses.splice(index, 1);
    }

    console.log(pamojaState.pamojaStatuses);

    dispatch({ type: "pamojaFilter", payload: true });
  };

  console.log("HELLO", value, inputValue);
  return (
    <Dialog open={pamojaState.pamojaFilter} onClose={() => handleClose()}>
      <DialogTitle>
        <p>Filter for export</p>
      </DialogTitle>
      <DialogContent>
        <DateRange
          editableDateInputs={true}
          onChange={(item) => {
            handleChangeDateRange(item.selection);
          }}
          moveRangeOnFirstSelection={false}
          ranges={state}
        />
        <br />
        <p style={orderDetailsText}>
          <b>Pamoja Status</b>
        </p>
        <FormGroup>
          {pamojaStatuses.map((data) => (
            <FormControlLabel sx={{ height: "30px" }} control={<Checkbox onChange={(e) => handleChangeOrderStatus(e)} value={data.value} size="small" checked={pamojaState.pamojaStatuses.indexOf(data.value) === -1 ? false : true} />} label={data.label} />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => handleClearFilter()} variant="outlined" style={addVariantButton}>
          Clear filters
        </Button>

        <Button
          style={addProductButton}
          onClick={() => {
            handleClose();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PamojaFilter;
