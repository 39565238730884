export const fetchUserData = async (searchText, pageSize, page) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/userService/admin/users/fetch?${new URLSearchParams({
        searchText,
        pageNumber: page,
        pageSize
      })}`,
      {
        method: "GET",
        headers: {
          authorization: token
        }
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchUserDetails = async (userId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/userService/admin/user/${userId}/details/fetch`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const updateUserToCL = async (body) => {
  const token = localStorage.getItem("token");

  const url = `${process.env.REACT_APP_BASE_URL}/api/userService/user/role/update`;

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
