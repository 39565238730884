import { useContext, useState } from "react";
// eslint-disable-next-line
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import FirebaseApp from "src/firebase/firebase";
import { useDropzone } from "react-dropzone";
import { GlobalContext } from "src/context/GlobalState";
import { urlIsVideoOrImageUpload } from "src/utils/utils";
import { urlType } from "src/constants/constants";
import { RenderMedia } from "../media/RenderMedia";

const GalleryUpload = (props) => {
  const [uploading, setUploading] = useState(false);

  const storage = getStorage(FirebaseApp);
  const {
    dispatch,
    state: { brandState }
  } = useContext(GlobalContext);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data) => {
        handleUploadGalleryImage(data);
      });
    }
  });

  const handleUploadGalleryImage = (media, index) => {
    try {
      setUploading(true);
      if (!urlIsVideoOrImageUpload(media)) {
        setUploading(false);
        alert("Please upload correct file format");
        return;
      }
      const defaultFileSize = urlIsVideoOrImageUpload(media) === urlType.image ? process.env.REACT_APP_FILESIZE_LIMIT : process.env.REACT_APP_FILESIZE_VIDEO_LIMIT;
      if (media?.size > defaultFileSize) {
        setUploading(false);
        alert(urlIsVideoOrImageUpload(media) === urlType.image ? "Please upload product image less than 150kb?" : "Please upload product video less than 5Mb?");
      } else {
        const Reference = ref(storage, `SKU/Brands/${urlIsVideoOrImageUpload(media) === urlType.image ? "GalleryPhotos" : "GalleryVideos"}/${Date.now()}-${media.name}`);
        uploadBytes(Reference, media).then((snapshot) => {
          getDownloadURL(Reference).then((url) => {
            const updatedBrandPhotos = [...brandState.selectedRecord.brandPhotos];
            updatedBrandPhotos[props.index] = url;
            dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, brandPhotos: updatedBrandPhotos } });
          });
        });
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);
      alert(error?.message ?? "An error occured during file upload");
    }
  };

  const handleDeleteClick = (e) => {
    let updatedBrandPhotos = [...brandState.selectedRecord.brandPhotos];
    updatedBrandPhotos.splice(props.index, 1);
    dispatch({ type: "selectedRecord", payload: { ...brandState.selectedRecord, brandPhotos: updatedBrandPhotos } });
  };
  return (
    <div
      {...getRootProps()}
      style={{
        // padding: "20px",
        boxSizing: "border-box",
        marginTop: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "95%",
        height: "270px",
        background: "#232F3E",
        position: "relative",
        borderWidth: "1px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#BFBFBF",
        borderRadius: "8px 8px 8px 8px"
      }}
    >
      <input {...getInputProps()} />
      <RenderMedia url={brandState.selectedRecord.brandPhotos[props.index] === undefined ? null : brandState.selectedRecord.brandPhotos[props.index]} uploading={uploading} type="brand" />
      <p style={{ color: "white", textAlign: "center", marginTop: "auto", fontWeight: "700", backgroundColor: "#232f3e" }}>Change</p>
      {brandState.selectedRecord.brandPhotos[props.index] === undefined ? (
        <></>
      ) : (
        <img
          src="/assets/icons/button/delete.svg"
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            cursor: "pointer"
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick();
          }}
        />
      )}
    </div>
  );
};

export default GalleryUpload;
