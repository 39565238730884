import { filterStatus } from "src/constants/constants";

export const pmIntitialState = {
  pmDetails: false,
  addNewField: false,
  productId: null,
  productVariantId: null,
  matrixPrice: null,
  discount: null,
  overRidePrice: null,
  pmData: {},
  newFields: [],
  newFieldName: null,
  newFieldValue: null,
  marketPriceTypeId: null,
  marketPriceName: null,
  filterStatuses: [filterStatus.inActive, filterStatus.active],

  priceMatrixRecord: {
    //Form submissions
    productId: null,
    productVariantId: null,
    marketPrices: {
      WholeSale: [],
      Dukas: [],
      "Bargain Markets": [],
      SuperMarkets: [],
      Competition: [],
      "UserFeedback Prices": []
    },
    discount: null,
    overRidePrice: null,
    priceMatrixId: null,

    percentage: 0,
    imageUrl: "",
    productName: "",
    variantName: "",
    categoryName: "",
    unitName: "",
    skuTypeName: "",
    brandName: "",
    price: "",
    status: "InActive",
    createdAt: new Date()
  }
};

export const pmReducer = (state, action) => {
  switch (action.type) {
    case "pmDetails":
      return { ...state, pmDetails: action.payload };
    case "addNewField":
      return { ...state, addNewField: action.payload };
    case "newFieldName":
      return { ...state, newFieldName: action.payload };
    case "newFieldValue":
      return { ...state, newFieldValue: action.payload };
    case "marketPriceTypeId":
      return { ...state, marketPriceTypeId: action.payload };
    case "marketPriceName":
      return { ...state, marketPriceName: action.payload };
    case "productId":
      return { ...state, productId: action.payload };
    case "productVariantId":
      return { ...state, productVariantId: action.payload };
    case "matrixPrice":
      return { ...state, matrixPrice: action.payload };
    case "discount":
      return { ...state, discount: action.payload };
    case "overRidePrice":
      return { ...state, overRidePrice: action.payload };
    case "pmData":
      return { ...state, pmData: action.payload };
    case "priceMatrixRecord":
      return { ...state, priceMatrixRecord: action.payload };
    case "newFields":
      return { ...state, pmData: action.payload };
    case "filterStatuses":
      return { ...state, filterStatuses: action.payload };
    case "clear":
      return {
        ...state,
        pmDetails: false,
        addNewField: false,
        productId: null,
        productVariantId: null,
        matrixPrice: null,
        discount: null,
        overRidePrice: null,
        pmData: {},
        newFields: [],
        newFieldName: null,
        newFieldValue: null,
        marketPriceTypeId: null,
        marketPriceName: null,
        filterStatuses: [filterStatus.inActive, filterStatus.active],
        priceMatrixRecord: {
          //Form submissions
          productId: null,
          productVariantId: null,
          marketPrices: {
            WholeSale: [],
            Dukas: [],
            "Bargain Markets": [],
            SuperMarkets: [],
            Competition: [],
            "UserFeedback Prices": []
          },
          discount: null,
          overRidePrice: null,
          priceMatrixId: null,

          percentage: 0,
          imageUrl: "",
          productName: "",
          variantName: "",
          categoryName: "",
          unitName: "",
          skuTypeName: "",
          brandName: "",
          price: "",
          status: "InActive",
          createdAt: new Date()
        }
      };
    default:
      return state;
  }
};
