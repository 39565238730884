import { PROMO_CHANNEL, PROMO_OFFER_TYPE, PROMO_STATUS, PURCHASE_TYPES, VALIDITY_TYPE, VISIBILITY_TYPE } from "src/constants/constants";

export const promoInitialState = {
  promoDetails: false,
  promoData: {},

  addPromoCode: false,
  updatePromoCode: false,
  addPoint: false,

  promoConfig: {},
  promoReload: false,

  validFromDisplay: null,
  validToDisplay: null,

  validFromUTC: null,
  validToUTC: null,

  promoProduct: {
    productVariantId: null,
    imgUrl: null,
    productName: null,
    variation: null,
    sokoPrice: null
  },
  brandProduct: {
    brandId: null,
    brandName: null,
    imgUrl: null
  },

  //Fields
  promoRecord: {
    promoCodeId: null,
    name: null,
    promoCode: null,
    description: null,
    validFrom: null,
    validTo: null,
    promoChannel: PROMO_CHANNEL.sokoCart.id,
    minOrderValue: null,
    maxOrderValue: null,
    offerType: PROMO_OFFER_TYPE.discount.id,
    promoInfo: {
      discount: "Discount is offered on the selected Promo Channel if the user reaches the defined minimum order value",
      freeProduct: "Product is offered for free on the selected Promo Channel if the user reaches the defined minimum order value",
      freeDelivery: "Delivery fee is waved off the selected Promo Channel if the user reaches the defined minimum order value"
    },
    visibilityType: VISIBILITY_TYPE.external.id,
    discount: null,
    productVariantId: null,
    brandId: null,
    quantity: null,
    purchaseTypeId: PURCHASE_TYPES.tushop,
    validityType: VALIDITY_TYPE.timePeriod.id,
    redemptionLimit: null,
    termAndConditions: null,
    status: PROMO_STATUS.active
  }
};

export const promoReducer = (state, action) => {
  switch (action.type) {
    case "promoDetails":
      return { ...state, promoDetails: action.payload };
    case "addPromoCode":
      return { ...state, addPromoCode: action.payload };
    case "updatePromoCode":
      return { ...state, updatePromoCode: action.payload };
    case "promoData":
      return { ...state, promoData: action.payload };
    case "promoRecord":
      return { ...state, promoRecord: action.payload };
    case "promoConfig":
      return { ...state, promoConfig: action.payload };
    case "addPoint":
      return { ...state, addPoint: action.payload };
    case "validFromDisplay":
      return { ...state, validFromDisplay: action.payload };
    case "validToDisplay":
      return { ...state, validToDisplay: action.payload };
    case "validFromUTC":
      return { ...state, validFromUTC: action.payload };
    case "validToUTC":
      return { ...state, validToUTC: action.payload };
    case "promoReload":
      return { ...state, promoReload: action.payload };
    case "promoProduct":
      return { ...state, promoProduct: action.payload };
    case "brandProduct":
      return { ...state, brandProduct: action.payload };
    case "promoProductClear":
      return { ...state, promoProduct: { brandName: null, productVariantId: null, imgUrl: null, productName: null, variation: null, sokoPrice: null } };
    case "promoBrandClear":
      return { ...state, brandProduct: { brandId: null, brandName: null, imgUrl: null } };
    case "promoClear":
      return {
        ...state,
        promoDetails: false,
        promoData: {},
        validFromDisplay: null,
        validFromUTC: null,
        validToUTC: null,
        promoRecord: {
          promoCodeId: null,
          name: null,
          promoCode: null,
          description: null,
          validFrom: null,
          validTo: null,
          validFromDisplay: null,
          validToDisplay: null,
          promoChannel: PROMO_CHANNEL.sokoCart.id,
          minOrderValue: null,
          maxOrderValue: null,
          offerType: PROMO_OFFER_TYPE.discount.id,
          promoInfo: {
            discount: "Discount is offered on the selected Promo Channel if the user reaches the defined minimum order value",
            freeProduct: "Product is offered for free on the selected Promo Channel if the user reaches the defined minimum order value",
            freeDelivery: "Delivery fee is waved off the selected Promo Channel if the user reaches the defined minimum order value"
          },
          visibilityType: VISIBILITY_TYPE.external.id,
          discount: null,
          productVariantId: null,
          brandId: null,
          quantity: null,
          purchaseTypeId: PURCHASE_TYPES.tushop,
          validityType: VALIDITY_TYPE.timePeriod.id,
          termAndConditions: null,
          status: PROMO_STATUS.active
        },
        promoProduct: {
          productVariantId: null,
          variantName: null,
          price: null
        },
        brandProduct: {
          brandId: null,
          brandName: null,
          imgUrl: null
        },
        addPromoCode: false,
        updatePromoCode: false,
        addPoint: false
      };
    default:
      return state;
  }
};
