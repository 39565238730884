//jipange csv
export const jipangeCSV = {
  headers: [
    { label: "Jipange Id", key: "Jipange Id" },
    { label: "Scheduled Delivery Date", key: "Scheduled Delivery Date" },
    { label: "Product Description", key: "Product Description" },
    { label: "Jipange Price", key: "Jipange Price" },
    { label: "Jipange Quantity", key: "Jipange Quantity" },
    { label: "Jipange Total", key: "Jipange Total" },
    { label: "Jipange Started On", key: "Jipange Started On" },
    { label: "Jipange Next Scheduled On", key: "Jipange Next Scheduled On" },
    { label: "Jipange Type", key: "Jipange Type" },
    { label: "Current Jipange Status", key: "Current Jipange Status" },
    { label: "Customer Id", key: "Customer Id" },
    { label: "Customer Phone Number", key: "Customer Phone Number" },
    { label: "Customer Wallet Balance", key: "Customer Wallet Balance" },
    { label: "Customer", key: "Customer" },
    { label: "Delivery Address", key: "Delivery Address" },
    { label: "Delivery Instruction", key: "Delivery Instruction" },
    { label: "Alternative PhoneNumber", key: "Alternative PhoneNumber" }
  ]
};

//order csv
export const orderCSV = {
  headers: [
    { label: "Order Number", key: "Order Number" },
    { label: "Order Date", key: "Order Date" },
    { label: "Community Leader", key: "Community Leader" },
    { label: "CL ID", key: "CL ID" },
    { label: "CL Phone Number", key: "CL Phone Number" },
    { label: "Customer", key: "Customer" },
    { label: "Customer ID", key: "Customer ID" },
    { label: "Customer Phone Number", key: "Customer Phone Number" },
    { label: "Alternate Phone Number", key: "Alternate Phone Number" },
    { label: "Product Description", key: "Product Description" },
    { label: "Total Quantity", key: "Total Quantity" },
    { label: "Unit Price", key: "Unit Price" },
    { label: "Total Price", key: "Total Price" },
    { label: "Status", key: "Status" },
    { label: "Delivery Address", key: "Delivery Address" },
    { label: "Delivery Longitude", key: "Delivery Longitude" },
    { label: "Delivery Latitude", key: "Delivery Latitude" },
    { label: "Delivery Mode", key: "Delivery Mode" },
    { label: "PurchaseType", key: "PurchaseType" },
    { label: "Depot", key: "Depot" },
    { label: "Shipper", key: "Shipper" },
    { label: "Payment Status", key: "Payment Status" },
    { label: "Delivery Date", key: "Delivery Date" },
    { label: "Delivered Date", key: "DeliveryDate" },
    { label: "Category", key: "Category" },
    { label: "Parent Category", key: "Parent Category" },
    { label: "OrderID", key: "OrderID" },
    { label: "Delivery Instruction", key: "Delivery Instruction" },
    { label: "Pamoja Completed On", key: "Pamoja Completed On" },
    { label: "Delivered On", key: "Delivered On" },
    { label: "Logistics Delivery Date", key: "Logistics Delivery Date" },
    { label: "Soko Price", key: "Soko Price" },
    { label: "Jipange Price", key: "Jipange Price" },
    { label: "Pamoja Price", key: "Pamoja Price" },
    { label: "Supermoja Price", key: "Supermoja Price" },
    { label: "New/Return Customer", key: "New/Return Customer" },
    { label: "Promo Code", key: "Promo Code" },
    { label: "Promo Savings", key: "Promo Savings" },
    { label: "Ordered Using", key: "Ordered Using" },
    { label: "Link generated from", key: "Link generated from" },
    { label: "Ordering Platform", key: "Ordering Platform" }
  ]
};

//product specific csv
export const productSpecificCSV = {
  headers: [
    { label: "productVariantId", key: "productVariantId" },
    { label: "productName", key: "productName" },
    { label: "brandName", key: "brandName" },
    { label: "unit", key: "unit" },
    { label: "sokoPrice", key: "sokoPrice" },
    { label: "pamojaPrice", key: "pamojaPrice" },
    { label: "qtyAllocated", key: "qtyAllocated" },
    // { label: 'qtySold', key: 'qtySold' },
    { label: "arrivesIn", key: "arrivesIn" },
    { label: "creatorRewards", key: "creatorRewards" },
    { label: "userPerPamoja", key: "userPerPamoja" },
    // { label: 'variantImgUrl', key: 'variantImgUrl' },
    { label: "status", key: "status" }
  ]
};

//pamoja specific csv
export const pamojaSpecificCSV = {
  headers: [
    { label: "Pamoja ID", key: "pamojaId" },
    { label: "Pamoja Config ID", key: "pamojaConfigId" },
    { label: "Expires In (Hours)", key: "expiresIn" },
    { label: "Arrives In (Days)", key: "arrivesIn" },
    { label: "User Per Pamoja", key: "userPerPamoja" },
    { label: "Total Qty Allocated", key: "totalQuantityAllocated" },
    { label: "Pamoja Launched By", key: "pamojaLaunchedBy" },
    { label: "Launcher UserID", key: "launchedByUserId" },
    { label: "Launcher Name", key: "launchedUser" },
    { label: "Launcher Phone Number", key: "launchedByPhoneNumber" },
    { label: "Creator Rewards", key: "creatorRewards" },
    { label: "Product Variant ID", key: "productVariantId" },
    { label: "ProductcName", key: "productName" },
    { label: "Unit", key: "unit" },
    { label: "Brand", key: "brand" },
    { label: "Variant Type", key: "variantType" },
    { label: "Pamoja Price", key: "pamojaPrice" },
    { label: "Soko Price", key: "sokoPrice" },
    { label: "No. Of Users", key: "numberOfUsers" },
    { label: "Status", key: "status" },
    { label: "Pamoja Completed At", key: "maturedDate" }
  ]
};

//pamoja user specific csv
export const pamojaUserSpecificCSV = {
  headers: [
    { label: "Pamoja User ID", key: "pamojaUserId" },
    { label: "Pamoja ID", key: "pamojaId" },
    { label: "Pamoja Config ID", key: "pamojaConfigId" },
    { label: "ProductcName", key: "productName" },
    { label: "Joiner Name", key: "joinerName" },
    { label: "Joiner Phone No.", key: "joinerPhoneNumber" },
    { label: "Date Joined", key: "createdAt" },
    { label: "Date Signed Up", key: "joinerSignUpDate" },
    { label: "Quantity Bought", key: "quantity" },
    { label: "Pamoja Price", key: "pamojaPrice" },
    { label: "Total Price", key: "total" },
    { label: "Status", key: "status" },
    { label: "Launcher Name", key: "launchedUser" },
    { label: "Launcher Phone No.", key: "launchedByPhoneNumber" },
    { label: "Pamoja Completed On", key: "maturedDate" },
    { label: "Delivered On", key: "deliveryDate" }
  ]
};

//pamoja orders
export const pamojaOrdersCSV = {
  headers: [
    { label: "Pamoja Id", key: "Pamoja Id" },
    { label: "Inviter", key: "Inviter" },
    { label: "Inviter Phone Number", key: "Inviter Phone Number" },
    { label: "Pamoja Launch Date", key: "Pamoja Launch Date" },
    { label: "Invitee", key: "Invitee" },
    { label: "Invitee Phone Number", key: "Invitee Phone Number" },
    { label: "Invitee Signed-up Date", key: "Invitee Signed-up Date" },
    { label: "Pamoja Joined Date", key: "Pamoja Joined Date" },
    { label: "Pamoja Product", key: "Pamoja Product" },
    { label: "Tushop Price", key: "Tushop Price" },
    { label: "Pamoja Price", key: "Pamoja Price" },
    { label: "Creator Reward", key: "Creator Reward" },
    { label: "Revenue", key: "Revenue" },
    { label: "Order Quantity", key: "Order Quantity" },
    { label: "Pamoja Status", key: "Pamoja Status" },
    { label: "Invitees Leader Id", key: "Invitees Leader Id" },
    { label: "Invitees Leader", key: "Invitees Leader" },
    { label: "Invitees Leader Phone Number", key: "Invitees Leader Phone Number" },
    { label: "New/Return Customer", key: "New/Return Customer" }
  ]
};

export const categoriesCSV = {
  headers: [
    { label: "Category Id", key: "Category Id" },
    { label: "Category Name", key: "Category Name" },
    { label: "Category Status", key: "Category Status" },
    { label: "Sub-category Name", key: "Sub-category Name" },
    { label: "Sub-category Status", key: "Sub-category Status" }
  ]
};
