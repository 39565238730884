import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { login } from "src/api/auth";
import { GlobalContext } from "src/context/GlobalState";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const {
    dispatch,
    state: { globalState }
  } = useContext(GlobalContext);

  const handleClick = async () => {
    try {
      const body = {
        emailId: globalState.email,
        password: globalState.password
      };

      const data = await login(body);

      if (data.httpStatusCode === 200) {
        const token = data.result.data.accessToken;

        dispatch({ type: "userData", payload: data.result.data });
        localStorage.setItem("user", JSON.stringify(data.result.data));
        localStorage.setItem("token", token);

        navigate("/dashboard/products", { replace: true });
      } else {
        alert(data.message);
      }
    } catch (error) {}
    // navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <TextField
        name="email"
        fullWidth
        label="Email address"
        value={globalState.email}
        onChange={(e) => {
          dispatch({ type: "email", payload: e.target.value });
        }}
      />

      <TextField
        name="password"
        fullWidth
        label="Password"
        value={globalState.password}
        onChange={(e) => {
          dispatch({ type: "password", payload: e.target.value });
        }}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
        {/* <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton style={{ backgroundColor: "#FE7C19" }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Sign In
      </LoadingButton>
    </>
  );
}
