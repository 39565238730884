import { allPamojaStatus, pamojaType } from "src/constants/constants";

export const superPamojaInitialState = {
  isSuperpamojaEdit: false,
  openSupermojaDialog: false,
  sokoPrice: 30,
  pamojaPrice: 0,
  allocatedQty: 0,
  availableQty: 0,
  productName: null,
  brandName: null,
  variation: null,
  active: false,
  arrivesIn: 0,
  expiresIn: 0,
  usersPerPamoja: 0,
  productVariantId: null,
  maxQtyPerUser: 0,
  imgURL: null,
  pamojaConfigId: null,
  pamojaId: null,
  pamojaType: pamojaType.userPamoja,
  showPamojaFeed: false,
  savePamojaStatus: allPamojaStatus.inActive,
  searchPage: 0,
  initialUsersCount: 0,
  showProductDescription: false,
  defaultQuantity: 0
};

export const superpamojaReducer = (state, action) => {
  switch (action.type) {
    case "openSupermojaDialog":
      return { ...state, openSupermojaDialog: action.payload };
    case "sokoPrice":
      return { ...state, sokoPrice: action.payload };
    case "pamojaPrice":
      return { ...state, pamojaPrice: action.payload };
    case "productName":
      return { ...state, productName: action.payload };
    case "brandName":
      return { ...state, brandName: action.payload };
    case "variation":
      return { ...state, variation: action.payload };
    case "active":
      return { ...state, active: action.payload };
    case "allocatedQty":
      return { ...state, allocatedQty: action.payload };
    case "availableQty":
      return { ...state, availableQty: action.payload };
    case "arrivesIn":
      return { ...state, arrivesIn: action.payload };
    case "expiresIn":
      return { ...state, expiresIn: action.payload };
    case "usersPerPamoja":
      return { ...state, usersPerPamoja: action.payload };
    case "productVariantId":
      return { ...state, productVariantId: action.payload };
    case "pamojaId":
      return { ...state, pamojaId: action.payload };
    case "maxQty":
      return { ...state, maxQty: action.payload };
    case "imgURL":
      return { ...state, imgURL: action.payload };
    case "isSuperpamojaEdit":
      return { ...state, isSuperpamojaEdit: action.payload };
    case "pamojaType":
      return { ...state, pamojaType: action.payload };
    case "pamojaConfigId":
      return { ...state, pamojaConfigId: action.payload };
    case "showPamojaFeed":
      return { ...state, showPamojaFeed: action.payload };
    case "savePamojaStatus":
      return { ...state, savePamojaStatus: action.payload };
    case "searchPage":
      return { ...state, searchPage: action.payload };
    case "initialUsersCount":
      return { ...state, initialUsersCount: action.payload };
    case "defaultQuantity":
      return { ...state, defaultQuantity: action.payload };
    case "showProductDescription":
      return { ...state, showProductDescription: action.payload };
    case "supermojaClear":
      return {
        ...state,
        isSuperpamojaEdit: false,
        openSupermojaDialog: false,
        sokoPrice: 0,
        pamojaPrice: 0,
        allocatedQty: 0,
        availableQty: 0,
        productName: null,
        brandName: null,
        variation: null,
        active: false,
        arrivesIn: 0,
        expiresIn: 0,
        usersPerPamoja: 0,
        productVariantId: null,
        pamojaConfigId: null,
        maxQty: 0,
        imgURL: null,
        showPamojaFeed: false,
        savePamojaStatus: allPamojaStatus.inActive,
        searchPage: 0,
        initialUsersCount: 0,
        defaultQuantity: 0,
        showProductDescription: false
      };
    default:
      return state;
  }
};
