export const fetchTableData = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/products/fetch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchPamojaTableData = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/pamoja/fetch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchPamojaTableDataByTushop = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/supermoja/fetch`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchDropdownData = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/dropDowns/fetch`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchPMDetails = async (productVariantId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/productService/admin/product/priceMatrix/fetch?${new URLSearchParams({
        productVariantId
      })}`,
      {
        method: "GET",
        headers: {
          authorization: token
        }
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export const createProductRecord = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/product/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const createVariantRecord = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/productVariant/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const updatePMRecord = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/productPriceMatrix/createOrUpdate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const updatePamojaConfig = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/pamojaConfig/enable`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const updateProductRecord = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/product/update`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchBrandTableData = async (pageSize, page, searchText) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/productService/admin/brands/fetch?${new URLSearchParams({
        pageSize,
        pageNumber: page,
        searchText
      })}`,
      {
        method: "GET",
        headers: {
          authorization: token
        }
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchBrandsById = async (brandId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/brand/${brandId}/fetchDetails`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const createOrUpdateBrand = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/brand/createOrUpdate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//Create or update supermoja config
export const createOrUpdateSupermojaConfig = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/supermojaConfig/enable`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//TODO: Implement search in manage supermoja
export const searchProductDetails = async (pageSize, page, searchText) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/productService/admin/productVariants/fetch?${new URLSearchParams({
        searchText,
        pageSize,
        pageNumber: page
      })}`,
      {
        method: "GET",
        headers: {
          authorization: token
        }
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export const fetchProductVariantInfo = async (productVariantId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/productVariant/${productVariantId}/admin/details/fetch`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//search for brands promo
export const searchBrandDetails = async (pageSize, page, searchText) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/productService/admin/brands/search?${new URLSearchParams({
        searchText,
        pageSize,
        pageNumber: page
      })}`,
      {
        method: "GET",
        headers: {
          authorization: token
        }
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

//fetch category listing
export const fetchCategoryList = async (searchValue) => {
  const token = localStorage.getItem("token");
  try {
    let searchUrl = searchValue ? `${process.env.REACT_APP_BASE_URL}/api/productService/category/admin/fetch?searchText=${searchValue}` : `${process.env.REACT_APP_BASE_URL}/api/productService/category/admin/fetch`;
    const response = await fetch(`${searchUrl}`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//Create or update category
export const createOrUpdateCategory = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/category/createOrUpdate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//Create or update subcategory
export const createOrUpdateSubCategory = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/subCategory/createOrUpdate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//Delete category
export const deleteCategoryRecord = async (categoryId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/category/delete/${categoryId}`, {
      method: "DELETE",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//Category reordering
export const reorderCategoryListing = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/category/reorder`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//Category export
export const fetchCategoryExportData = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/category/export`, {
      method: "GET",
      headers: {
        authorization: token
      }
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

//Admin brand share
export const generateAdminBrand = async (body) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/productService/admin/brand/share`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
