import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, alpha, Box, Switch, Autocomplete, Divider, LinearProgress } from "@mui/material";
// eslint-disable-next-line

import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { addProductButton, addVariantButton, imageContainer, pVarText } from "src/styles/styles";

// eslint-disable-next-line
import SKUField from "src/components/fields/skuField";
import ProductVariantInput from "src/components/fields/productVariantInput/ProductVariantInput";
import FirebaseApp from "src/firebase/firebase";
import { getStorage } from "firebase/storage";
import Loader from "src/components/dialogs/Loader";
import EmptyProductImagesUpload from "src/components/uploads/emptyProductImagesUpload";
import CarrouselProductUpload from "src/components/uploads/carrouselProductUpload";
import styled from "@emotion/styled";
import { orange } from "@mui/material/colors";
import { GlobalContext } from "src/context/GlobalState";
import { createProductRecord, createVariantRecord, fetchCategoryList, fetchDropdownData, fetchProductVariantInfo, updateProductRecord } from "src/api/products";
import { productCreateOrUpdateValidator } from "src/validators/product.validation";
import headerStyle from "src/styles/headerStyle";
import { extractYoutubeVideoId, isValidYoutubeLink, urlIsVideoOrImage } from "src/utils/utils";
import { urlType } from "src/constants/constants";
import buttonStyle from "src/styles/buttonStyle";

// ----------------------------------------------------------------------

export default function ProductsDetails() {
  const galleryInputArray = [];
  const storage = getStorage(FirebaseApp);
  const {
    dispatch,
    state: { productState, globalState }
  } = useContext(GlobalContext);
  let { productVariantId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("search") || null;

  const [youtubeLink, setYoutubeLink] = useState(null);
  useEffect(() => {
    fetchCategoriesDropDown()
      .then(() => fetchDropDowns())
      .then(() => {
        if (/^\d+$/.test(productVariantId)) {
          fetchProductVariantDetails();
        } else {
          dispatch({ type: "loading", payload: false });
        }
      })
      .catch((err) => alert(err?.message));
  }, []);

  useEffect(() => {
    if (location?.state?.brandId && location?.state?.brandName) {
      const updatedBrandsDropDown = [...productState.brandDropdown, { brandId: location?.state?.brandId, label: location?.state?.brandName }];
      dispatch({ type: "brandDropdown", payload: updatedBrandsDropDown });
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, brandId: location?.state?.brandId, brandName: location?.state?.brandName } });
    }
  }, [location.state]);

  const fetchProductVariantDetails = async () => {
    try {
      const response = await fetchProductVariantInfo(productVariantId);
      if (response.httpStatusCode === 200) {
        const {
          data: { productId, product, productVariantId, skuType, isJipange, brand, variantType, unit, status, productVariantVat, rules, productVariantPrice, categories, productVariantImage, isTopSelling, isDefault }
        } = response?.result;
        dispatch({
          type: "productRecord",
          payload: {
            ...productState?.productRecord,
            productVariantId,
            productId,
            productName: product?.name ?? null,
            description: product?.description ?? null,
            additionalDetails: product?.additionalDetails ?? null,
            isJipange,
            skuTypeId: skuType?.skuTypeId ?? null,
            skuTypeName: skuType?.skuTypeName ?? null,
            brandId: brand?.brandId ?? null,
            brandName: brand?.name ?? null,
            status,
            productImageUrls: productVariantImage.map((img) => {
              return { url: img?.imageUrl ?? null, isPrimary: img?.isPrimary ?? false };
            }),
            variantId: variantType?.variantTypeId ?? null,
            variantName: variantType?.name ?? null,
            rules,
            unitId: unit?.unitId ?? null,
            unitName: unit?.name ?? null,
            vatId: productVariantVat?.vatId,
            isDefault,
            isTopSelling: isTopSelling ?? false,
            allocatedQuantity: productVariantPrice?.allocatedQuantity,
            availableQuantity: productVariantPrice?.currentQuantity,
            mainCategories: categories?.filter((x) => x !== null) ?? []
          }
        });
        dispatch({ type: "loading", payload: false });
      } else {
        dispatch({ type: "loading", payload: false });
        alert(response?.message);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const fetchCategoriesDropDown = async () => {
    try {
      dispatch({ type: "loading", payload: true });
      let response = await fetchCategoryList();
      if (response.httpStatusCode === 200) {
        const processedCategories = response?.result?.data.flatMap((category) => {
          if (category.subCategories.length === 0) {
            return {
              categoryId: category.categoryId,
              categoryName: category.name,
              categoryImageUrl: category.imageUrl,
              group: null
            };
          } else {
            return category.subCategories.map((subCategory) => ({
              categoryId: subCategory.categoryId,
              categoryName: subCategory.name,
              categoryImageUrl: subCategory.imageUrl,
              group: category.name
            }));
          }
        });

        dispatch({ type: "categoryDropdown", payload: processedCategories });
      }
    } catch (error) {
      alert(error?.message);
      dispatch({ type: "loading", payload: false });
    }
  };

  const fetchDropDowns = async () => {
    try {
      const response = await fetchDropdownData();
      if (response.httpStatusCode === 200) {
        const { variantTypes, units, brands, skuTypes, products, vats } = response.result.data;
        if (Array.isArray(products)) products.unshift({ productId: "POST", name: "Create New Product" });
        dispatch({
          type: "productsDropdown",
          payload: products.map((product) => {
            return { label: product.name, productId: product.productId };
          })
        });
        if (Array.isArray(variantTypes)) variantTypes.unshift({ variantTypeId: "POST", name: "Create New Variant Type" });
        dispatch({
          type: "variantTypeDropdown",
          payload: variantTypes.map((variant) => {
            return { label: variant.name, vType: variant.variantTypeId };
          })
        });
        dispatch({ type: "skuTypeDropdown", payload: skuTypes });
        if (Array.isArray(units)) units.unshift({ unitId: "POST", name: "Create New Unit Type" });
        dispatch({
          type: "unitDropdown",
          payload: units.map((unit) => {
            return { label: unit.name, unitId: unit.unitId };
          })
        });
        if (Array.isArray(brands)) brands.unshift({ brandId: "POST", name: "Create New Brand" });
        dispatch({
          type: "brandDropdown",
          payload: brands.map((brand) => {
            return { label: brand.name, brandId: brand.brandId };
          })
        });
        dispatch({ type: "vatDropdown", payload: vats });
      } else {
        alert(response?.message);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const { productsDropdown } = productState;

  const handleClose = () => {
    dispatch({ type: "clear" });
    dispatch({ type: "loading", payload: false });
    navigate(`/dashboard/products${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
  };

  const handleChangeJipangeStatus = (e) => dispatch({ type: "productRecord", payload: { ...productState?.productRecord, isJipange: e.target.checked } });
  const handleChangeTopSellingStatus = (e) => dispatch({ type: "productRecord", payload: { ...productState?.productRecord, isTopSelling: e.target.checked } });
  const handleChangeActivity = (e) => dispatch({ type: "productRecord", payload: { ...productState?.productRecord, status: e.target.value } });

  const OrangeSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: orange[600],
      fontSize: 24,
      "&:hover": {
        backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity)
      }
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: orange[600]
    }
  }));

  const handleSubmit = async () => {
    try {
      dispatch({ type: "loading", payload: true });

      let validateResp;
      if (productVariantId === "add") {
        validateResp = productCreateOrUpdateValidator({ productObj: productState.productRecord, createProductRecord: true, createProductVariantRecord: false, updateProductRecord: false });
      } else if (productVariantId === "variant") {
        validateResp = productCreateOrUpdateValidator({ productObj: productState.productRecord, createProductRecord: false, createProductVariantRecord: true, updateProductRecord: false });
      } else if (/^\d+$/.test(productVariantId)) {
        validateResp = productCreateOrUpdateValidator({ productObj: productState.productRecord, createProductRecord: false, createProductVariantRecord: false, updateProductRecord: true });
      }
      if (validateResp?.error) {
        dispatch({ type: "loading", payload: false });
        alert(validateResp?.error ?? "An error occured");
        return;
      }
      let response;
      if (productVariantId === "add") {
        response = await createProductRecord(validateResp);
      } else if (productVariantId === "variant") {
        response = await createVariantRecord(validateResp);
      } else if (/^\d+$/.test(productVariantId)) {
        response = await updateProductRecord(validateResp);
      }
      dispatch({ type: "loading", payload: false });
      if (response.httpStatusCode === 200) {
        alert(response.message);
        navigate(`/dashboard/products${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
        dispatch({ type: "clear" });
      } else {
        alert(response.message);
      }
    } catch (error) {
      dispatch({ type: "loading", payload: false });
      alert(error?.message);
    }
  };

  const handleChange = (e, val) => {
    if (val?.productId === "POST") {
      dispatch({ type: "addVariant", payload: false });
      dispatch({ type: "addProduct", payload: true });
    } else {
      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, productId: val?.productId } });
    }
  };

  const productImageUrls = productState.productRecord?.productImageUrls ?? [];

  // Loop through the productImageUrls
  for (let i = 0; i < productImageUrls.length && i < 9; i++) {
    galleryInputArray.push(
      <Grid xs={2.4} key={i}>
        <CarrouselProductUpload index={i} />
      </Grid>
    );
  }

  // Check if there is room for a "+" icon (adding a new image)
  if (productImageUrls.length < 9) {
    galleryInputArray.push(
      <Grid xs={2.4} key="add-new">
        <CarrouselProductUpload index={productImageUrls.length} isAddNewIcon={true} />
      </Grid>
    );
  }

  const primaryImageUrl = productState.productRecord?.productImageUrls?.find((x) => x.isPrimary);
  const fallbackImage = "/assets/NoImage.png";
  const imageUrl = primaryImageUrl?.url || primaryImageUrl?.imageUrl || fallbackImage;

  //Handle upload of youtube link
  const uploadYoutubeLinkCarouselPhotos = (link) => {
    if (!link) {
      alert("Please enter a valid youtube url !");
      return;
    }
    if (!isValidYoutubeLink(link)) {
      alert("Please enter a valid youtube url !");
      return;
    }
    let updatedImageUrls = [...(productState.productRecord.productImageUrls || [])];
    updatedImageUrls.push({ url: youtubeLink, isPrimary: false });
    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, productImageUrls: updatedImageUrls } });
    setYoutubeLink(null);
    alert("Upload successful");
    return;
  };

  return (
    <>
      <Loader />
      <Grid container spacing={1} sx={{ mt: 0, mx: 2 }} style={headerStyle.headerContainer}>
        <Grid item xs={4}>
          <Typography variant="h4">Products</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <Button
            sx={{ mr: 1 }}
            onClick={() => {
              dispatch({ type: "clear" });
              navigate(`/dashboard/products${searchQuery ? `?search=${searchQuery}` : ""}`, { replace: true });
            }}
          >
            <img alt="add" src="/assets/icons/button/back.svg" />
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={4}>
          <div
            style={{
              // padding: "20px",
              boxSizing: "border-box",
              marginTop: "10px",
              marginLeft: "auto",
              marginRight: "auto",
              width: "80%",
              height: "30%",
              borderWidth: "1px 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#BFBFBF",
              borderRadius: "8px 8px 8px 8px"
            }}
          >
            {urlIsVideoOrImage(imageUrl) === urlType.image ? (
              <img src={imageUrl} style={imageContainer} alt="Product" />
            ) : urlIsVideoOrImage(imageUrl) === urlType.video ? (
              <video controls src={imageUrl} style={imageContainer} alt="Product" />
            ) : isValidYoutubeLink(imageUrl) ? (
              <iframe width="100%" height="100%" style={imageContainer} src={`https://www.youtube.com/embed/${extractYoutubeVideoId(imageUrl)}?rel=0&modestbranding=1&controls=1&showinfo=0&disablekb=1`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="YouTube Video"></iframe>
            ) : null}
          </div>
          <div
            style={{
              boxSizing: "borderBox",
              marginTop: "2em",
              marginLeft: "auto",
              marginRight: "auto",
              width: "80%"
            }}
          >
            <Grid>
              <Grid container sx={{ justifyContent: "space-between", my: 2 }}>
                <Grid item xs={7}>
                  <Typography fontSize={18}>Top Selling</Typography>
                </Grid>
                <Grid item xs={4}>
                  <OrangeSwitch
                    name="isTopSelling"
                    checked={productState.productRecord?.isTopSelling ?? false}
                    onClick={(e) => {
                      handleChangeTopSellingStatus(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: "space-between", my: 2 }}>
                <Grid item xs={7}>
                  <Typography fontSize={18} variant="h5" color="#3B4758">
                    Available for Jipange
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <OrangeSwitch
                    name="isJipange"
                    checked={productState.productRecord?.isJipange ?? false}
                    onClick={(e) => {
                      handleChangeJipangeStatus(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: 20 }}>
                    Status
                  </FormLabel>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" onChange={(e) => handleChangeActivity(e)} value={productState.productRecord?.status} style={{ display: "grid" }}>
                    <FormControlLabel value={"Active"} control={<Radio />} label="Active" />
                    <FormControlLabel value={"InActive"} control={<Radio />} label="Inactive" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={8}>
          {productState?.productRecord?.productImageUrls?.length < 9 ? (
            <Grid
              my={2}
              gap={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <TextField onChange={(e) => setYoutubeLink(e.target.value ? e.target.value.trim() : e.target.value)} fullWidth label="Upload link from youtube" variant="outlined" value={youtubeLink ?? ""} InputLabelProps={{ shrink: true }} sx={{ margin: 0, height: "56px" }} />
              <Button
                sx={{
                  ...buttonStyle.simpleButton,
                  backgroundColor: "#EF7B22",
                  color: "#FFF",
                  padding: "8px 16px",
                  height: "56px",
                  display: "flex",
                  alignItems: "center"
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#EF7B22";
                  e.currentTarget.style.color = "#FFF";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "#EF7B22";
                  e.currentTarget.style.color = "#FFF";
                }}
                onClick={() => uploadYoutubeLinkCarouselPhotos(youtubeLink)}
              >
                Upload
              </Button>
            </Grid>
          ) : null}
          {productState.productCarouselUpload && <LinearProgress color="primary" />}
          <div
            style={{
              padding: "20px",
              boxSizing: "border-box",
              background: "#F2F2F2",
              borderWidth: "1px 1px 1px 1px",
              marginTop: "10px",
              height: "30%",
              borderStyle: "solid",
              borderColor: "#BFBFBF",
              borderRadius: "8px 8px 8px 8px",
              textAlign: "center"
            }}
          >
            {productState.productRecord?.productImageUrls?.length === 0 ? (
              <EmptyProductImagesUpload />
            ) : (
              <>
                <h2>Product variant Carrousel Images</h2>
                <Grid container spacing={2}>
                  {galleryInputArray}
                </Grid>
              </>
            )}
          </div>
          {productVariantId === "add" || /^\d+$/.test(productVariantId) ? (
            <>
              <h2>{productVariantId === "add" ? "Create new" : "Edit"} product</h2>
              <FormControl fullWidth>
                <TextField
                  onChange={(e) => {
                    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, productName: e.target.value } });
                  }}
                  fullWidth
                  label="Product Name"
                  variant="standard"
                  value={productState.productRecord?.productName}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  aria-label="Description"
                  name="productDescription1"
                  id="description"
                  style={{ width: "100%" }}
                  variant="standard"
                  multiline
                  rows={4}
                  onChange={(e) => {
                    dispatch({ type: "productRecord", payload: { ...productState?.productRecord, description: e.target.value } });
                  }}
                  label="Description"
                  margin="normal"
                  value={productState?.productRecord.description}
                  InputLabelProps={{ shrink: true }}
                />

                {productState.productRecord?.skuTypeId === 1000 ? (
                  <TextField
                    aria-label="Description"
                    name="productDescription1"
                    id="description"
                    variant="standard"
                    style={{ width: "100%" }}
                    multiline
                    rows={4}
                    onChange={(e) => {
                      dispatch({ type: "productRecord", payload: { ...productState?.productRecord, additionalDetails: e.target.value } });
                    }}
                    label="Additional Details"
                    margin="normal"
                    value={productState.productRecord?.additionalDetails}
                    InputLabelProps={{ shrink: true }}
                  />
                ) : (
                  <></>
                )}
                <SKUField />
              </FormControl>
            </>
          ) : (
            <>
              <h2>Create new variant</h2>
              <FormControl fullWidth margin="dense">
                <Autocomplete disablePortal id="category-label" name="product" options={productsDropdown} getOptionLabel={(option) => option.label} onChange={(e, val) => handleChange(e, val)} sx={{ width: "100%" }} renderInput={(params) => <TextField {...params} label="Product" variant="standard" />} />
              </FormControl>
            </>
          )}

          <p style={{ ...pVarText, fontWeight: "800" }}>Product Variant</p>
          <ProductVariantInput routePath={productVariantId} />
          <Box sx={{ display: "flex", my: 2, float: "right" }}>
            <Button sx={{ mr: 2 }} onClick={(e) => handleClose()} variant="outlined" style={addVariantButton}>
              Cancel
            </Button>
            <Button
              style={addProductButton}
              onClick={() => {
                handleSubmit();
              }}
            >
              Confirm
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
