import { useContext, useState } from "react";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// eslint-disable-next-line

import { addProductButton, addVariantButton } from "src/styles/styles";
import { searchUserData } from "src/api/orders";
import { GlobalContext } from "src/context/GlobalState";

const JipangeOrderFilter = ({ handleJipangeExport }) => {
  const {
    dispatch,
    state: { orderState }
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const handleClose = () => {
    dispatch({ type: "jipangeFilter", payload: false });
  };

  const handleClearFilter = () => {
    setOptions([]);
    setValue("");
    setInputValue("");
    setState([
      {
        startDate: null,
        endDate: null,
        key: "selection"
      }
    ]);
    dispatch({ type: "clear" });
  };

  const handleInputChange = async (val) => {
    setInputValue(val);
    if (val.length === 0) {
      setOptions([]);
      dispatch({ type: "jipangeCustomerIds", payload: [] });
    } else if (val.length < 3 && val.length > 0) {
      setOptions([]);
    } else if (val.length === 3) {
      const arr = [];
      const response = await searchUserData(val);
      if (response.httpStatusCode === 200) {
        response.result.data.forEach((data) => {
          arr.push({ label: `Customer: ${data.firstName} ${data.lastName}`, id: data.userId, type: "CS" });
        });
        setOptions(arr);
      } else {
        alert(response.message);
      }
    }
  };

  const handleSelect = (data) => {
    setValue(data);
    if (data.type === "CS") {
      orderState.jipangeCustomerIds.push(data.id);
    }
  };

  const handleChangeDateRange = (range) => {
    setState([range]);
    // 2023-02-10 00:00:00
    const date = new Date(range.startDate);
    const date2 = new Date(range.endDate);

    const startDate = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + date.getDate().toString().padStart(2, "0") + "T00:00:00";

    const endDate = date2.getFullYear() + "-" + (date2.getMonth() + 1 > 9 ? date2.getMonth() + 1 : "0" + (date2.getMonth() + 1)) + "-" + date2.getDate().toString().padStart(2, "0") + "T23:59:59";

    dispatch({ type: "fromDate", payload: startDate });
    dispatch({ type: "toDate", payload: endDate });
    dispatch({ type: "fromDateLocal", payload: startDate });
    dispatch({ type: "toDateLocal", payload: endDate });
  };

  return (
    <Dialog open={orderState.jipangeFilter} onClose={() => handleClose()}>
      <DialogTitle sx={{ mx: 2 }}>FILTER JIPANGE ORDERS</DialogTitle>
      <DialogContent style={{ width: "420px" }}>
        <Grid container justifyContent="center" spacing={7}>
          <Grid item xs={11}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                handleSelect(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                handleInputChange(newInputValue);
              }}
              options={options}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Search by customer/cl name" />}
            />
            <br />
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                handleChangeDateRange(item.selection);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => handleClearFilter()} variant="outlined" style={addVariantButton}>
          Clear filters
        </Button>
        <Button
          style={addProductButton}
          onClick={() => {
            console.log("You clicked me");
            handleJipangeExport();
          }}
        >
          {orderState.jipangeDownloadLoading ? "Please wait ..." : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JipangeOrderFilter;
