export const login = async (body) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/userService/admin/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    return response.json();
  } catch (error) {
    return error;
  }
};
