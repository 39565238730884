import { createProductRecord, createVariantRecord, updateProductRecord } from "src/api/products";

export const productHeadCells = [
  {
    id: "warning",
    numeric: false,
    disablePadding: true,
    label: ""
  },
  {
    id: "category",
    numeric: false,
    disablePadding: true,
    label: "Category"
  },
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Product"
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: false,
    label: "Brand"
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    label: "Variation"
  },
  {
    id: "sku",
    numeric: false,
    disablePadding: false,
    label: "SKU"
  }
];

export const categoryHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Category"
  }
];

export const pmHeadCells = [
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category"
  },
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Product"
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: false,
    label: "Brand"
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    label: "Variation"
  },
  {
    id: "sku",
    numeric: false,
    disablePadding: false,
    label: "SKU"
  }
];

export const handleSubmitProductRecord = async (product, productDesc, newCategory, newCategoryName, newCategoryImgUrl, category, rules, newSKU, newSKUName, SKU, newVType, newVTypeName, vType, newUnit, newUnitName, unit, newBrand, newBrandName, newBrandImgUrl, brand, variantImgUrls, status, vatId, allocatedQty, details, categories, isJipange) => {
  const primaryImage = variantImgUrls.find(({ isPrimary }) => isPrimary === true);
  if (!product || product.trim() === "") {
    return "Please enter Product name";
  } else if (!productDesc || productDesc.trim() === "") {
    return "Please enter Product description";
  } else if (categories.mainCategories.length === 0) {
    return "Please select category";
  } else if (!SKU) {
    return "Please select SKU type";
  } else if (!vType && !newVType) {
    return "Please select variant type";
  } else if (!unit && !newUnit) {
    return "Please select unit";
  } else if (!brand.brandId && !brand.brandName) {
    return "Please select brand";
  } else if (!vatId) {
    return "Please select VAT Type";
  } else if (variantImgUrls.length === 0) {
    return "Please upload product image";
  } else if (!primaryImage) {
    return "Please select primary Image";
  } else if (!status) {
    return "Please select product status";
  } else if (parseInt(SKU) === parseInt(process.env.REACT_APP_DP_SKU_TYPE) && details === null) {
    return "Please add additional details for service";
  } else {
    const body = {
      productName: product.trim(),
      allocatedQuantity: allocatedQty ?? 0,
      productImageUrls: variantImgUrls,
      skuTypeId: SKU,
      skuTypeName: newSKUName,
      mainCategories: categories.mainCategories,
      alternateCategories: categories.alternateCategories,
      brandId: brand.brandId,
      brandName: brand.brandName,
      variantId: vType,
      variantName: vType ? null : newVTypeName,
      description: productDesc,
      unitId: unit,
      unitName: newUnitName,
      rules,
      status,
      vatId,
      additionalDetails: details,
      isJipange: isJipange ?? false
    };

    const response = await createProductRecord(body);
    return response;
  }
};

export const handleSubmitVariantRecord = async (productId, newVType, newVTypeName, vType, newUnit, newUnitName, unit, rules, newBrand, newBrandName, newBrandImgUrl, brand, variantImgUrls, status, vatId, allocatedQty, categories, isJipange) => {
  const primaryImage = variantImgUrls.find(({ isPrimary }) => isPrimary === true);
  if (productId === null) {
    return "Please select Product";
  } else if (vType === null && !newVType) {
    return "Please select variant type";
  } else if (unit === null && !newUnit) {
    return "Please select unit";
  } else if (brand.brandId === null && !brand.brandName) {
    return "Please select brand";
  } else if (vatId === null) {
    return "Please select VAT Type";
  } else if (variantImgUrls.length === 0) {
    return "Please upload product image";
  } else if (primaryImage === undefined) {
    return "Please select primary Image";
  } else if (status === null) {
    return "Please select product status";
  } else {
    const body = {
      productId,
      productVariantImageUrls: variantImgUrls,
      allocatedQuantity: allocatedQty ?? 0,
      alternateCategories: categories.alternateCategories,
      brandId: brand.brandId,
      brandName: brand?.brandName ?? null,
      variantId: vType,
      variantName: vType ? null : newVTypeName,
      unitId: unit,
      unitName: newUnitName,
      rules,
      status,
      vatId,
      isJipange: isJipange ?? false
    };

    const response = await createVariantRecord(body);

    return response;
  }
};

export const handleUpdateProductRecord = async (productId, productVariantId, product, productDesc, newCategory, newCategoryName, newCategoryImgUrl, category, rules, newSKU, newSKUName, SKU, newVType, newVTypeName, vType, newUnit, newUnitName, unit, newBrand, newBrandName, newBrandImgUrl, brand, variantImgUrls, status, vatId, allocatedQty, details, categories, isJipange) => {
  const primaryImage = variantImgUrls.find(({ isPrimary }) => isPrimary === true);

  if (!product || product.trim() === "") {
    return "Please enter Product name";
  } else if (!productDesc || productDesc.trim() === "") {
    return "Please enter Product description";
  } else if (categories.mainCategories.length === 0) {
    return "Please select category";
  } else if (!SKU) {
    return "Please select SKU type";
  } else if (!vType && !newVType) {
    return "Please select variant type";
  } else if (!unit && !newUnit) {
    return "Please select unit";
  } else if (brand.brandId === null || !brand.name) {
    return "Please select brand";
  } else if (!brand.brandId && !newBrandName) {
    return "Please select brand";
  } else if (!vatId) {
    return "Please select VAT Type";
  } else if (variantImgUrls.length === 0) {
    return "Please upload product image";
  } else if (!primaryImage) {
    return "Please select primary Image";
  } else if (parseInt(SKU) === parseInt(process.env.REACT_APP_DP_SKU_TYPE) && details === null) {
    return "Please add additional details for service";
  } else {
    const body = {
      productId,
      productVariantId,
      productName: product.trim(),
      allocatedQuantity: allocatedQty ?? 0,
      productImageUrls: variantImgUrls,
      skuTypeId: SKU,
      skuTypeName: newSKUName,
      mainCategories: categories.mainCategories,
      alternateCategories: categories.alternateCategories,
      brandId: brand.brandId,
      brandName: brand?.name ?? newBrandName,
      variantId: vType,
      variantName: newVTypeName,
      description: productDesc,
      unitId: unit,
      unitName: newUnitName,
      rules,
      status,
      vatId,
      additionalDetails: details,
      isJipange: isJipange ?? false
    };

    console.log(body);
    const response = await updateProductRecord(body);

    return response;
  }
};
