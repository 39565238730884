import jwtDecode from "jwt-decode";

export const verifyTokenExpiry = (token, navigate) => {
  if (!token || token === "") {
    navigate("/login", { replace: true });
    return;
  }
  const jwtTokenDecoded = jwtDecode(token);
  // console.log('expiry-->', jwtTokenDecoded.exp, 'iat-->', Date.now())
  const expirationTime = jwtTokenDecoded.exp * 1000 - 60000;
  if (Date.now() >= expirationTime || token === null || token === undefined) {
    localStorage.removeItem("token");
    navigate("/login", { replace: true });
  }
};
