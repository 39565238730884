export const crmInitialState = {
  crmDetails: false,
  crmData: {
    userId: null,
    userFirstName: null,
    userlastName: null,
    userRegisteredNumber: null,
    address: [],
    clFirstName: null,
    clLastName: null,
    signUpDate: null,
    lastLoginDate: null,
    role: null,
    walletBalance: 0,
    payouts: 0,
    topUps: 0,
    bonus: 0,
    transactions: [],
    nextJipangeAmount: 0,
    nextJipangeDate: 0,
    orderAmount: 0
  },
  displayAddress: false
};

export const crmReducer = (state, action) => {
  switch (action.type) {
    case "crmDetails":
      return { ...state, crmDetails: action.payload };
    case "crmData":
      return { ...state, crmData: action.payload };
    case "displayAddress":
      return { ...state, displayAddress: action.payload };
    case "clear":
      return {
        ...state,
        crmDetails: false,
        crmData: {
          userId: null,
          userFirstName: null,
          userlastName: null,
          userRegisteredNumber: null,
          address: [],
          clFirstName: null,
          clLastName: null,
          signUpDate: null,
          lastLoginDate: null,
          role: null,
          walletBalance: 0,
          payouts: 0,
          topUps: 0,
          bonus: 0,
          transactions: [],
          nextJipangeAmount: 0,
          nextJipangeDate: 0,
          orderAmount: 0
        },
        displayAddress: false
      };
    default:
      return state;
  }
};
