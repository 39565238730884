import { useContext } from "react";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import FirebaseApp from "src/firebase/firebase";
import { useDropzone } from "react-dropzone";
import { GlobalContext } from "src/context/GlobalState";
import { LinearProgress } from "@mui/material";

const EmptyGalleryUploads = () => {
  const storage = getStorage(FirebaseApp);
  const {
    dispatch,
    state: { brandState }
  } = useContext(GlobalContext);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((data) => {
        handleUploadMultipleGalleryImage(data);
      });
    }
  });

  const handleUploadMultipleGalleryImage = async (image) => {
    try {
      dispatch({ type: "brandCarouselUpload", payload: true });
      const defaultFileSize = process.env.REACT_APP_FILESIZE_LIMIT;
      if (image?.size > defaultFileSize) {
        alert("Please upload product image less than 150kb?");
      } else {
        const Reference = ref(storage, `SKU/Brands/GalleryPhotos/${Date.now()}-${image.name}`);
        await uploadBytes(Reference, image);
        const url = await getDownloadURL(Reference);
        brandState.selectedRecord.brandPhotos.push(url);
        dispatch({ type: "updateBrand", payload: brandState.updateBrand });
        dispatch({ type: "addBrand", payload: brandState.addBrand });
      }
    } catch (error) {
      dispatch({ type: "brandCarouselUpload", payload: false });
      alert(error?.message ?? "An error occured during file upload");
    } finally {
      dispatch({ type: "brandCarouselUpload", payload: false });
    }
  };

  return (
    <>
      {brandState.brandCarouselUpload && <LinearProgress color="primary" sx={{ px: 0, m: 0 }} />}
      <div style={{}} {...getRootProps()}>
        <input {...getInputProps()} />
        <img src={"/assets/upload.png"} style={{ marginLeft: "auto", marginRight: "auto", marginTop: "80px", height: "180px" }} alt="Product" />
        <p>
          <b>Drag and Drop files here</b>
        </p>
        <p>click here or drop to add images</p>
      </div>
    </>
  );
};

export default EmptyGalleryUploads;
