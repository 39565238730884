const pamojaStyles = {
    emptySuperPamojaContainer:{
        display: "flex",
        height: "90px",
        marginTop: "1em",
        width: "100%",
        padding: "8px 16px",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        borderRadius: "8px",
        border: "1px solid #D9D9D9",
        backgroundColor: "#F2F2F2",
        color: "#BFBFBF"
    },
    pamojaContainer:{
        display: "flex",
        marginTop: "1em",
        width: "100%",
        border: "1px solid #D9D9D9",
        padding: "4px 0px 4px 8px",
        borderRadius: "8px",
    },
    searchProductListing:{
       position:"absolute",
       zIndex: 1,
       marginRight: "2em",
       backgroundColor: "rgba(255, 255, 255, 1.0)"
    },
    cellBorder: {
        border: "1px solid #D9D9D9",
        textAlign: "left",
    }

}

export default pamojaStyles