import { parse, subHours, set, format, addHours } from "date-fns";

export function fDate(datee) {
  const date = new Date(datee);
  date.getTimezoneOffset();
  const formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " T " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes());

  return datee === null || datee === "NIL" ? "-" : formattedDate;
}

export function dateToUTC(datee) {
  const date = new Date(datee);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  const formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()) + ":" + (date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds());

  console.log(datee);
  return datee === null || datee === "NIL" ? "-" : formattedDate;
}

export function getFromDate() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const startDate = date.getFullYear() + "-" + (date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " 00:00:00";
  return startDate;
}

export function getDefaultEndDate() {
  const date = new Date();
  const today = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + " " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()) + ":00";

  return today;
}

export function convertStartDateToUTC(date) {
  const parsedDate = parse(date, "yyyy-MM-dd 'T' HH:mm", new Date());
  const newDate = subHours(parsedDate, 3);
  const finalDate = set(newDate, { hours: 0, minutes: 0, seconds: 0 });
  const formattedDate = format(finalDate, "yyyy-MM-dd 'T' HH:mm");
  return formattedDate;
}

export function convertEndDateToUTC(date) {
  const parsedDate = parse(date, "yyyy-MM-dd 'T' HH:mm", new Date());
  const newDate = subHours(parsedDate, 3);
  const finalDate = set(newDate, { hours: 21, minutes: 59, seconds: 59 });
  const formattedDate = format(finalDate, "yyyy-MM-dd 'T' HH:mm");
  return formattedDate;
}

export function getDefaultDates() {
  const date = new Date();
  const date2 = new Date();
  const startDate = date.getFullYear() + "-" + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + date.getDate().toString().padStart(2, "0") + "T00:00:00";
  const endDate = date2.getFullYear() + "-" + (date2.getMonth() + 1 > 9 ? date2.getMonth() + 1 : "0" + (date2.getMonth() + 1)) + "-" + date2.getDate().toString().padStart(2, "0") + "T23:59:59";

  return { startDate, endDate };
}
