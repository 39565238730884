// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBAE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBAE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBAE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBAE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBAE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBAE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBAE_MEASUREMENT_ID,
};

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);


// const analytics = getAnalytics(FirebaseApp);

export default FirebaseApp